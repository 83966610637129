import React from 'react';
import { Checkbox, Button, Modal } from 'semantic-ui-react';

import { PAGES_MAP, THF_SERVER_URL } from '../util';
import LoadingProgressShown from '../Components/LoadingProgressShown';

const USER_KEYS = [// order dictates appearance
  'firstName', 'lastName', 'last_sign_in', 'email',
  'valid_subscription', 'is_trial_user', 'trial_ends_at',
  'signup_partner_id', 'referred_by', 'signupCode',
  'channel_partner', 'channel_partner_referral_code',
  'visual_mode', 'favorites', 'preferredViews',
  'okta_uid', 'admin'
];

function EditPagesModal({pages, updatePages}) {
  const [open, setOpen] = React.useState(false)
  const [newPages, setNewPages] = React.useState({});

  React.useEffect( () => {
    let temp = {};
    Object.keys(PAGES_MAP).forEach(page => temp[page] = false);
    pages.forEach(page => temp[page] = true);
    setNewPages(temp);
  }, [pages]);

  return (<Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Edit</Button>}
      style={{display: 'flex !important'}}
    >
      <Modal.Header>Select Pages</Modal.Header>
      <Modal.Content>
        <Modal.Description>
        <ul>
          {Object.keys(newPages).map(pageKey =>{
            if (!PAGES_MAP[pageKey]) console.error("Admin Page, no PAGE_MAP key for:", {pageKey});
            return <li key={pageKey}>
              <Checkbox label={PAGES_MAP[pageKey].name} toggle onChange={(e, data) => {
                  let temp = {...newPages};
                  temp[pageKey] = data.checked;
                  setNewPages(temp);
                }}
                checked={newPages[pageKey]}
              />
            </li>;
          })}
        </ul>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nevermind
        </Button>
        <Button icon='checkmark' content="Save" labelPosition='right'
          positive
          onClick={() => {
            updatePages(newPages);
            setOpen(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

const UsersTable = ({accessToken, updateUserPermissions, displayTitle="Users"}) => {
  const [loading, setLoading] = React.useState(true);
  const [displayError, setDisplayError] = React.useState(null);
  
  const [users, setUsers] = React.useState([]);
  const [loadingUser, setLoadingUser] = React.useState(null);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    if (accessToken) {
      fetch(THF_SERVER_URL+"users?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => {
        res.json().then( (users) => {
          setUsers(users);
          setLoading(false);
        });
      }).catch( (error) => {
        if (error.name === 'AbortError') return;
        console.error("Error: (UsersTable) fetching 'users'", {error});
        setDisplayError("Unable to communicate with server. Please try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [accessToken]);

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' /> }
  </div>);

  if (users.length === 0) return (<div>
    <h3>{displayTitle}</h3>
    <p>No Users to display</p>
  </div>);

  const columnKeys = USER_KEYS;

  return (<div>
    <h3>{displayTitle}</h3>
    <div className="full-cells">
      <table className="admin-usertable">
        <thead>
          <tr>{columnKeys.map( (k) => <th key={k}>{k}</th>)}</tr>
        </thead>

        <tbody>
          {users.map( (u, uIndex) => {
            if (loadingUser === u.id) return (<tr key={u.id}><td>Updating...<LoadingProgressShown type='spinner' /></td></tr>);

            return (<tr key={u.id}>
              {columnKeys.map( (k) => {
                if (typeof u[k] === 'boolean') {
                  return <td key={k}>
                    <Checkbox label="" toggle onChange={(e, data) => {
                        if (!loadingUser) {
                          setLoadingUser(u.id);
                          updateUserPermissions(k, data.checked, u.okta_uid, (newPerms) => {
                            let temp = [...users];
                            temp[uIndex] = newPerms;
                            setUsers(temp);
                            setLoadingUser(null);
                          });
                        };
                      }}
                      checked={u[k]}
                    />
                  </td>;
                } else if (Array.isArray(u[k])) {
                  return (<td key={k}>
                    {k === "pages" ? (
                      <EditPagesModal pages={u[k]} updatePages={(p) => {
                        if (!loadingUser) {
                          setLoadingUser(u.id);
                          const newPages = Object.keys(p).filter(k => p[k]);
                          updateUserPermissions(k, newPages, u.okta_uid, (newPerms) => {
                            let temp = [...users];
                            temp[uIndex] = newPerms;
                            setUsers(temp);
                            setLoadingUser(null);
                          });
                        };
                      }}/>
                    ) : (<React.Fragment>
                      {u[k].join(", ")}
                    </React.Fragment>)}
                  </td>);
                } else if (typeof u[k] === "object") {
                  return (<td key={k}>
                    {u[k] === null ? <p>null</p> : Object.keys(u[k]).join(", ")}
                  </td>);
                };
                if (k === 'last_sign_in') {
                  return (<td key={k}>{new Date(u[k]*1000).toLocaleString()}</td>);
                };
                return (<td key={k}>{u[k]}</td>);
              })}
            </tr>);
          })}
        </tbody>
      </table>
    </div>
  </div>);
};
export default UsersTable;
