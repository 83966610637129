import React from 'react';
import UsersTable from '../Components/UsersTable';
import BugReport from '../Components/BugReport';
import { THF_SERVER_URL } from '../util';

const ChannelPartnerPanel = ({permissions, accessToken, updateUserPermissions}) => {
  const [users, setUsers] = React.useState([]);
  const [referralCode, setReferralCode] = React.useState("");

  React.useEffect( () => {
    if (permissions.channel_partner_referral_code) {
      setReferralCode(permissions.channel_partner_referral_code);
    }
  }, [permissions.channel_partner_referral_code]);

  React.useEffect( () => {
    if (accessToken) {
      fetch(THF_SERVER_URL+"referred_users?access_token="+accessToken)
      .then( (res) => {
        return res.json();
      }).then((j) => {
        setUsers(j);
      }).catch( (error) => {
      }).finally( () => {
      });
    };
  }, [accessToken]);

  if (!(permissions.admin || permissions.channel_partner)) {
    return null;
  }

  return (<div className="pageContent">
    <p>Welcome to the Partner Panel</p>
    <p>Sales manager: Robert Douglas</p>
    <BugReport />
    <div>
      <label>Referral Code:</label>
      <input type="text" value={referralCode} onChange={(e) => {
        setReferralCode(e.target.value);
      }}/>
      <button onClick={() => updateUserPermissions('channel_partner_referral_code', referralCode)}>save</button>
      <p>Referral link: <a href={"https://thf.ai/r/"+permissions.channel_partner_referral_code}>https://thf.ai/r/{permissions.channel_partner_referral_code}</a></p>
    </div>
    <UsersTable users={users} title="Your Customers"/>
  </div>);
};

export default ChannelPartnerPanel;

