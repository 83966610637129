import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import config from '../config';

let registrationNotClicked = true;

const Login = ({register, code, registrationCallback}) => {
  const { authService } = useOktaAuth();
  if (!register) {
    registrationNotClicked = false;
  }

  React.useEffect(() => {
    const { issuer, clientId, redirectUri, scopes } = config.oidc;
    const widget = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo: 'images/svg/thf-text-black.jpg',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in to THF Portal',
        },
      },
      features: {
        registration: true,                           // Enable self-service registration flow
        rememberMe: true,                             // Setting to false will remove the checkbox to save username
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        issuer,
        scopes,
      },
      registration: {
        preSubmit: (postData, onSuccess) => {
          if (code) postData.signupCode = code;
          //console.log("Pre-Login", {postData});
          onSuccess(postData);
        },
        postSubmit: (response, onSuccess) => {
          if (registrationCallback) registrationCallback(response);
          //console.log("Post-Login", {response});
          onSuccess(response);
        }
      }
    });

    widget.on('afterRender', function (data) {
      // This is the code that makes it look as if the Okta Sign-In Widget has been loaded to the "Registration" view
      const view = data.controller;
      if(view == 'primary-auth') {
        if(registrationNotClicked) {
          registrationNotClicked = false;
          document.getElementsByClassName("registration-link")[0].click();
        }
      }
    });

    widget.renderEl(
      { el: '#sign-in-widget' },
      ({ tokens }) => {
        //console.log("Login Okta", {tokens});
        if (tokens) {
          // Add tokens to storage
          const tokenManager = authService.getTokenManager();
          tokenManager.add('idToken', tokens.idToken);
          tokenManager.add('accessToken', tokens.accessToken);
        }
        if (!registrationCallback) {
          // Return to the original URL (if auth was initiated from a secure route), falls back to the origin
          const fromUri = authService.getFromUri();
          window.location.assign(fromUri);
        }
      },
      (err) => {
        throw err;
      },
    );
  }, [authService]);

  return (
    <div>
      <div id="sign-in-widget" />
    </div>
  );
};
export default Login;
