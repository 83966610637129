import React from 'react';
import { THF_SERVER_URL } from '../util';

const prettyJSON = (obj) => {
  return <ul style={{textAlign: 'left'}}>
    {Object.keys(obj).map(key => {
      if (typeof obj[key] === 'object' ) {
        return <li key={key}>{key}:{prettyJSON(obj[key])}</li>;
      } else {
        return <li key={key}>{key}: {obj[key]}</li>;
      };
    })}
  </ul>;
};

const Metrics = ({accessToken}) => {
  const [loading, setLoading] = React.useState(true);
  const [metrics, setMetrics] = React.useState(null);

  React.useEffect( () => {
    if (accessToken) {
      setLoading(true);
      fetch(
        THF_SERVER_URL+"metrics?access_token="+accessToken,
      ).then( (res) => res.json().then( (details) => {
        setMetrics(details);
        setLoading(false);
      })).finally( () => {
        setLoading(false);
      });
    };
  }, [accessToken]);

  if (loading || metrics === null) return <p>Loading server metrics...</p>;

  return (<div>
    <h3>Server Metrics</h3>
    <div style={{marginInline:'auto',width:'440px',overflowY:'auto',maxHeight:'600px'}}>
      {prettyJSON(metrics)}
    </div>
  </div>);
};
export default Metrics;
