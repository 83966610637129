import React from 'react';

import config from '../config';
import { THF_SERVER_URL } from '../util';
import { downloadHistoricalVol, downloadHistoricalHurst, 
  downloadHistoricalOptionPricings, downloadHistoricalCalibratedForecasts 
} from '../csvDownload';
import useManagedSymbols from '../Hooks/ManagedSymbols';

import LoadingProgressShown from '../Components/LoadingProgressShown';
import SquareCardManager from '../Components/SquareCardManager';
import { ListSearch, ListGrouped } from '../Components/ListTypes';

import '../css/dataMarketPlace.css';

const DataMarketplacePage = ({userInfo, accessToken, permissions}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);
  const [downloading, setDownloading] = React.useState(false);

  const [shopMarket, setShopMarket] = React.useState(config.dataMarketplace[0]);
  const [shoppingList, setShoppingList] = React.useState([]);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [squareCustomer, setSquareCustomer] = React.useState(null);
  const [newPurchaseResult, setNewPurchaseResult] = React.useState(null);
  const [purchases, setPurchases] = React.useState(null);

  const [dataMarketPlace, setDataMarketPlace] = React.useState(config.dataMarketplace);
  function changeMarketType(offering) {
    setShoppingList([]);
    setShopMarket(offering);
  };

  const { managedSymbols } = useManagedSymbols({accessToken, setLoading, setDisplayError}, true);
  
  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken && userInfo) {
      fetch(THF_SERVER_URL+"past_purchases/"+userInfo.email+"?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => res.json().then( (list) => {
        setPurchases(list);
        setLoading(false);
      })).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (DataMarketPlacePage)", error);
        setDisplayError("Unable to communicate with server. Please try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [userInfo, accessToken, newPurchaseResult]);

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  function squareCardSelected(customer, card) {
    console.log("Square customer, card selected for use:", {customer, card});
    setSquareCustomer(customer);
    setSelectedCard(card);
  };

  function buyData(customerId, sourceId, email, amount, purchase_details, cb) {
    const body = JSON.stringify({
      locationId: config.payments.locationId,
      customerId,
      sourceId,
      email,
      amount,
      purchase_details
    });
    fetch(THF_SERVER_URL+'buy_data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }).then( (res) => {
      if (res.ok) {
        //res.json().then();
        cb();
      } else {
        throw new Error("Unable to complete purchase.");
      };
    }).catch( (error) => {
      if (error.name === 'AbortError') return;
      //console.error("Error: (DataMarketPlacePage)", error);
      setDisplayError("Unable to complete purchase. Please try again later.");
    }).finally( () => {
    });
  };

  function download(offering_id, symbols, cb) {
    console.log('starting download for', offering_id, symbols)
    if (offering_id === 'historical-vol') {
      downloadHistoricalVol(accessToken, offering_id, symbols, cb, () => setDisplayError());
    } else if (offering_id === 'historical-hurst') {
      downloadHistoricalHurst(accessToken, offering_id, symbols, cb, () => setDisplayError());
    } else if (offering_id === 'historical-option-pricings') {
      downloadHistoricalOptionPricings(accessToken, symbols, cb, () => setDisplayError());
    } else if (offering_id === 'historical-calibration-forecasts') {
      downloadHistoricalCalibratedForecasts(accessToken, offering_id, symbols, cb, () => setDisplayError());
    };
  };

  function addToShoppingListItem(symbolKey) {
    var symArr = [];
    const currentlyInList = shoppingList.find(s => s.key === symbolKey);
    if (currentlyInList) return;
    if (purchases) {
      const relevantPurchases = purchases.find(p => p.item === shopMarket.id);
      if (relevantPurchases) {
        symArr = relevantPurchases.symbols === 'all' ? managedSymbols.map(s => s.key) : relevantPurchases.symbols.split(",");
      };
    };
    if (!symArr.includes(symbolKey)) {
      const symbol = managedSymbols.find(sym => sym.key === symbolKey);
      setShoppingList([...shoppingList, symbol]);
    };
  };

  function removeFromShoppingListItem(symbolKey) {
    const currentlyInList = shoppingList.find(s => s.key === symbolKey);
    if (currentlyInList) {
      setShoppingList(shoppingList.filter(s => s.key !== symbolKey));
    };
  };

  function toggleShoppingListItem(symbolKey) {
    if (symbolKey == null) return;
    if (symbolKey === true) {
      setShoppingList(managedSymbols);
      return;
    } else if (symbolKey === false) {
      setShoppingList([]);
      return;
    };
    const currentlyInList = shoppingList.map(sym => sym.key).includes(symbolKey);
    if (currentlyInList) {
      setShoppingList(shoppingList.filter(sym => sym.key !== symbolKey));
    } else {
      const symbol = managedSymbols.find(sym => sym.key === symbolKey);
      setShoppingList([...shoppingList, symbol]);
    };
  };

  const DisplayShopTotal = () => {
    return (<div>
      {(shopMarket) ? (
        <p>Total = $ {Math.min(shopMarket.symbol_price * shoppingList.length, shopMarket.price)}</p>
      ) : (
        <p>Start by selecting a Market.</p>
      )}
    </div>);
  };

  const BuyShoppingList = () => {
    if (!shopMarket) {return (<div>
      <p>Initializing Market...</p>
    </div>)};

    const price = Math.min(shopMarket.symbol_price * shoppingList.length, shopMarket.price);
    return (<div>
      <button onClick={ () => {
        setLoading(true);
        if (selectedCard && squareCustomer) {
          const details_key = shopMarket.id + ":" + (price === shopMarket.price ? 'all' : shoppingList.map(s => s.key).join(","));
          buyData(squareCustomer.id, selectedCard.id, userInfo.email, price, details_key, (a) => {
            console.log(a);
            if (a && a.body && a.body.payment) {
              setNewPurchaseResult(a.body.payment)
            };
          });
        } else {
          //console.error("Can not buy, no Card or Customer data.", selectedCard, squareCustomer);
          setDisplayError("Can not buy, no Card selected.");
        };
      }}>Confirm Purchase $ {price}</button>
    </div>);
  };

  const DisplayOffering = () => {
    const BuyOrDownloadPurchased = () => {
      if (purchases) {
        const relevantPurchase = purchases.find(p => p.item === shopMarket.id);
        if (relevantPurchase) {// downloading state or start
          const symArr = relevantPurchase.symbols === 'all' ? managedSymbols.map(s => s.key) : relevantPurchase.symbols.split(",");
          let dlBtn = <button onClick={ () => {
            setDownloading(shopMarket.id);
            download(shopMarket.id, symArr, () => {
              setDownloading(false);
            });
          }}>Download</button>;
          if (downloading === shopMarket.id) {
            dlBtn = <span>... Downloading ...</span>;
          };
          if (relevantPurchase.symbols === 'all') {
            return (<p>All symbols purchased. {dlBtn}</p>);
          } else {
            return (<p>Symbols purchased: {relevantPurchase.symbols} {dlBtn}</p>);
          };
        };
      };
      // still able to buy
      return (<div>
        <ListSearch managedItems={managedSymbols} onSelection={(symbolKey) => addToShoppingListItem(symbolKey)}/>
      </div>);
    };

    return (<div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap', alignContent:'center'}}>
      <div style={{width:'280px', flex:1}}>
        <div style={{fontWeight:'bold'}}>{shopMarket.name}</div>
        <div style={{fontSize:14}}>{shopMarket.description}</div>
        <div style={{fontSize:14}}><a href={THF_SERVER_URL + shopMarket.sampleLink + ".csv?access_token=" + accessToken} target='_blank'>sample CSV</a></div>
      </div>
      <div style={{width:'280px', padding:'12px', flex:1}}>
        <BuyOrDownloadPurchased />
        {(shoppingList.length === managedSymbols.length) ? (
          <button onClick={ () => {
            toggleShoppingListItem(false);
          }}>Clear Cart</button>
        ) : (
          <button onClick={ () => {
            toggleShoppingListItem(true);
          }}>Add All</button>
        )}
      </div>
    </div>);
  };

  const MarketSelector = () => {
    const OfferingsDisplaySelector = () => {
      return (<div className='marketSelectorContainer'>
        {dataMarketPlace && dataMarketPlace.map( (offering) => {
          const active = (shopMarket && shopMarket.id === offering.id);
          const className = 'marketSelectorButton' + (active ? ' selected' : '');
          return (<button className={className} key={offering.id} onClick={ ()=> {
            changeMarketType(offering);
          }}><div className='marketSelector-buttonFill'>{offering.name}</div>
          </button>);
        })}
      </div>);
    };

    const CurrentDataMarket = () => {
      if (shopMarket) {
        return <DisplayOffering />;
      } else {
        return (<div>
          <p>Loading market data...</p>
        </div>);
      };
    };

    return (<div>
      <OfferingsDisplaySelector />
      <CurrentDataMarket />
    </div>);
  };

  return (<div className="pageContent">
    <h2>Data Marketplace</h2>
    <MarketSelector />
    <DisplayShopTotal />
    <ListGrouped managedItems={shoppingList} onSelection={(symbolKey) => removeFromShoppingListItem(symbolKey)}/>
    <BuyShoppingList />
    <SquareCardManager userInfo={userInfo} onCardSelection={() => squareCardSelected()} accessToken={accessToken} permissions={permissions} ifNoCardShowAdd={true} />
  </div>);
};

export default DataMarketplacePage;
