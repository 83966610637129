const MSGBUS = (process.env.MSG_BUS === undefined ? 'https://TODO' : process.env.MSG_BUS);
const MOCK_FETCHING = (process.env.MOCK_FETCHING === undefined ? false : process.env.MOCK_FETCHING);

const liveProd = {
  identity: {
    CLIENT_ID: '0oafsjx1Gc3pmeXXi5d5',
    ISSUER: 'https://dev-4048885.okta.com/oauth2/default',

    redirectUri: 'https://www.thf.ai/implicit/callback'
  },
  OKTA_TESTING_DISABLEHTTPSCHECK: false,
  payments: {
    appId: 'sandbox-sq0idb-JmYcwIrqvnw98BXdFzDbRg',
    locationId: 'L3S6EQHGM0J2F',
    plans: ['7SGLLFMTP4RRNHAWFFXVEKVT'],
  }
};

const local = {
  prod: {
    identity: {
      CLIENT_ID: '0oa3sd3d0sV81gMFJ5d7',
      ISSUER: 'https://dev-4048885.okta.com/oauth2/default',

      redirectUri: 'http://localhost:3000/implicit/callback'
    },
    OKTA_TESTING_DISABLEHTTPSCHECK: false
  },

  dev: {
    identity: {
      CLIENT_ID: '0oabi4snYEmw3m6t75d5',
      ISSUER: 'https://dev-4048885.okta.com/oauth2/default',

      redirectUri: 'http://localhost:8080/implicit/callback'
    },
    OKTA_TESTING_DISABLEHTTPSCHECK: false,
    payments: {
      appId: 'sandbox-sq0idb-JmYcwIrqvnw98BXdFzDbRg',
      locationId: 'L3S6EQHGM0J2F',
      plans: ['7SGLLFMTP4RRNHAWFFXVEKVT'],
    }
  }
};

let config = liveProd;
if (process.env.NODE_ENV === 'local_prod') {
  config = local.prod;
} else if (process.env.NODE_ENV === 'development') {
  config = local.dev;
}

export default {
  analytics: {
    googleID: 'G-GGF18D1D89',
    postHog: 'phc_WdKC5NwNDCMQJKRN4pQ1Jp8ZsoNxa0BqQGa9zltYYKB',
  },
  mockFetching: MOCK_FETCHING,
  payments: config.payments,
  oidc: {
    clientId: config.identity.CLIENT_ID,
    issuer: config.identity.ISSUER,
    redirectUri: config.identity.redirectUri,
    scopes: ['openid', 'profile', 'email', "groups"],
    pkce: true,
    disableHttpsCheck: config.OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
    digestUrl: MSGBUS,
  },
  dataMarketplace: [
    {
      id: 'historical-vol',
      name: 'Historical Volatility',
      description: 'Daily volatility metrics for supported tickers. Supports 12 metrics, including Realized Volatility, Continuous Quadratic Variation, Realized Skewness and Cretosis, and more.',
      price: 250,
      symbol_price: 50,
      sampleLink: 'data-marketplace-sample/vol',
    },
    {
      id: 'historical-hurst',
      name: 'Historical Hurst Exponent',
      description: 'Periodic historical calculated Hurst Exponent for supported tickers. Is recalculated daily on an ongoing basis, but historical data is limited',
      price: 250,
      symbol_price: 50,
      sampleLink: 'data-marketplace-sample/hurst',
    },
    {
      id: 'historical-option-pricings',
      name: 'Historical Option Pricings',
      description: 'Access to a record of historical market and THF pricings for various option contracts. We have contracts going back to 2022-02-16, but not all contracts are available on all tickers.',
      price: 250,
      symbol_price: 50,
      sampleLink: 'data-marketplace-sample/option-pricings',
    },
    {
      id: 'historical-calibration-forecasts',
      name: 'Historical Forecasts for Rho and SpotV',
      description: 'Access to a record of historical forecasts for Rho and SpotV from the THF models for supported symbols.',
      price: 250,
      symbol_price: 50,
      sampleLink: 'data-marketplace-sample/calibration-forecasts',
    },
  ],
};
