import React from 'react';
import { THF_SERVER_URL } from '../util';

import LoadingProgressShown from '../Components/LoadingProgressShown';

const PortfolioPage = ({accessToken, permissions}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);
  const [totals, setTotals] = React.useState([]);
  const [positions, setPositions] = React.useState(null);

  const [isAdmin, setIsAdmin] = React.useState(false);
  React.useEffect(() => {
    if (permissions) {
      setIsAdmin(permissions.admin)
    } else {
      setIsAdmin(false);
    };
  }, [permissions]);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken && isAdmin) {
      setLoading(true);
      fetch(
        THF_SERVER_URL+"account-totals?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => {
        res.json().then( (list) => {
          console.log(list);
          setTotals(list.totals);
          setPositions(list.accounts);
        });
      }).catch( (err) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (PortfolioPage) fetching 'account-totals'", err);
        setDisplayError("Unable to communicate with server. Please try again later.");
      }).finally( () => {
        setLoading(false);
      });
    };
    return () => controller.abort();
  }, [accessToken, isAdmin]);

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  return (<div className="pageContent">
    <h1>Portfolio</h1>

    {isAdmin ? (<ul>
      Account positions:
      {totals.map( (t) => {
        let acc_pos = positions[t.account];
        if (acc_pos) {
          acc_pos = <ul>
            {acc_pos.map(pos => {
              return <li key={pos.type+pos.symbol+pos.expiration}>{pos.size} of {pos.symbol} {pos.type} @ {pos.avgCost}</li>;
            })}
          </ul>;
        }
        return <li key={t.account}>
          {t.account}: {t.value}
          {acc_pos}
        </li>;
      })}
    </ul>) : (
      <p>Coming soon.</p>
    )}
  </div>);
};
export default PortfolioPage;
