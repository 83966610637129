import React from 'react';

function useCopyToClipboard(CopiedValue=null) {
  const [copiedText, setCopiedText] = React.useState(CopiedValue);

  const clipboardCopy = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    };

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    };
  };
  return {copiedText, clipboardCopy};
};
export default useCopyToClipboard;
