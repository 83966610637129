import React from 'react';
import { Popup, Card, Button } from 'semantic-ui-react';

const AiGamePage = () => {

  return (<div className="pageContent">
    <div>
      <h3>How this works?</h3>
      <p>Think your actions are random? Play against AI to find out. Every time THF guesses your next move right, it will take $1 from your virtual account. Every time THF guesses wrong, you'll get $1.05. THF keeps track of the patterns you produce and uses them to predict your next move. After just 100 iterations THF should become trained enough to frequently beat you.</p>
      <br/>
      <h3>How to play?</h3>
      <p>If you press ← and → as randomly as you can, THF will try to guess your next input. After pressing the buttons below you can use your keyboard arrow keys. Once you've become tired of watching your virtual money evaporate, press the "randomize" button below, and we will add 10 pseudo-random inputs on your behalf. On average (i.e. not all the time), this is going to earn you some money. Isn't it ironic that a pseudo-random number generator is more "random" than you are?</p>
      <br/>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <iframe title="aigame-box" className="aigame-box" seamless scrolling="no" sandbox="allow-scripts" src="not-so-random.html" frameBorder="0" style={{width:"100%", height:"520px"}}>
          <p>Your browser does not support this game.</p>
        </iframe>
      </div>
    </div>

    <Popup wide='very' position='top right' hoverable={true} trigger={
      <Button compact inverted color='blue'>How does this work?</Button>
    }>
      <Popup.Content>
        <Card>
          <Card.Content>
            <Card.Header>How does this work?</Card.Header>
            <Card.Description>
              So how does it work exactly? Your fingers tend to repeat certain patterns even if you don't notice it. The program keeps a database of each possible combination of 5 presses, and two counters are stored under each entry — one is for every zero that follows the combination, and the other one is for all the ones that follow this combination. So every time you press a key, an entry in the database gets updated. To make a prediction, the program needs only to look up the entry corresponding to the last 5 presses and decide by looking at the counters which key press is more likely to follow. The rest is up to Fortuna (velut luna). I've run this script with 200 pseudo-random inputs 100,000 times, and found that the distribution of correct guesses is normal with µ=49.99% and σ=3.52%.  The probability of the program guessing your inputs >57% (µ+2σ) of the time purely by chance is very slim, which suggests that you really aren't good at making random choices.
            </Card.Description>
          </Card.Content>
        </Card>
      </Popup.Content>
    </Popup>
  </div>);
};

export default AiGamePage;
