import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_SYMBOL } from '../util';
import useManagedSymbols from '../Hooks/ManagedSymbols';

import LoadingProgressShown from '../Components/LoadingProgressShown';
import Heatmap from '../Components/Heatmap';
import { SymbolFavoriteList } from '../Hooks/ManagedSymbols';
import SymbolComments from '../Components/SymbolComments';

const HeatmapPage = ({accessToken, userInfo, permissions, updateUserPermissions, trackActivity}) => {
  const [loading, setLoading] = React.useState(true);
  const [displayError, setDisplayError] = React.useState(null);
  
  const { managedSymbols } = useManagedSymbols({accessToken, setLoading, setDisplayError}, true);
  
  const [search, setSearch] = useSearchParams();
  function grabUrlSymbol() {
    let sym = search.get('symbol');
    if (!sym && managedSymbols[0]) {
      sym = managedSymbols[0].key;
    };
    if (!sym) sym = DEFAULT_SYMBOL;
    return sym;
  };
  
  const [displaySymbol, setDisplaySymbol] = React.useState(grabUrlSymbol());
  const setHeatSymbol = (value) => {
		setSearch({symbol: value});
		setDisplaySymbol(value);
	};
 
  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  return (<div className="pageContent">
    <h1>Symbol Heatmap</h1>

    <div alight="right" style={{width:'fit-content'}}>
      <SymbolFavoriteList accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} 
        selectedItem={displaySymbol} onSelection={(value) => setHeatSymbol(value)} 
      />
    </div>

    {(displaySymbol != null) && (<div>
      <SymbolComments accessToken={accessToken} userInfo={userInfo} symbol={displaySymbol} />
      <div style={{paddingTop:'26px'}}>
        <Heatmap accessToken={accessToken} symbol={displaySymbol} permissions={permissions} updateUserPermissions={updateUserPermissions} trackActivity={trackActivity}/>
      </div>
    </div>)}
  </div>);
};

export default HeatmapPage;
