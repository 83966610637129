import React from 'react';
import { Button, Checkbox, Search, Label, Icon} from 'semantic-ui-react';

import '../css/components.css';

export const ListSearch = ({managedItems, currentItemKey, onSelection}) => {
  const initialState = {
    loading: false,
    results: {},
    value: '',
  };
  const [state, dispatch] = React.useReducer(shownMatchReducer, initialState);
  const { loading, results, value } = state;

  const timeoutRef = React.useRef();
  React.useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  function shownMatchReducer(state, action) {
    switch (action.type) {
      case 'CLEAN_QUERY':
        return initialState;
      case 'START_SEARCH':
        return { ...state, loading: true, value: action.query };
      case 'FINISH_SEARCH':
        return { ...state, loading: false, results: action.results };
      case 'UPDATE_SELECTION':
        return { ...state, value: action.selection };
  
      default:
        throw new Error();
    };
  };
  
  const resultRenderer = ({ text }) => <Label content={text} />;

  const handleSearchChange = React.useCallback((e, data) => {
    window.clearTimeout(timeoutRef.current);
    dispatch({ type: 'START_SEARCH', query: data.value });
    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        dispatch({ 
          type: 'CLEAN_QUERY',
          value: data.value,
        });
        return;
      };
      const foundItems = managedItems.filter( (item) => {
        return item.value.toLowerCase().indexOf(data.value.toLowerCase()) !== -1
      });
      dispatch({
        type: 'FINISH_SEARCH',
        results: foundItems,
      });
    }, 300);
  }, [managedItems]);

  return (
    <Search
      aligned="right"
      loading={loading}
      placeholder={currentItemKey == null ? 'Search symbols...'+managedItems.length : currentItemKey+', search again...'}
      onResultSelect={(e, data) => {
        dispatch({ type: 'UPDATE_SELECTION', selection: data.result.value });
				onSelection(data.result.key);
      }}
      onSearchChange={handleSearchChange}
			resultRenderer={resultRenderer}
      value={value}
      results={results}
    />
  );
};


export const ListGrouped = ({managedItems, onSelection, icon='remove circle'}) => {
  React.useEffect( () => {

    return () => {};
  }, [managedItems]);

  return (<div className='c_ListGrouped'>
    {managedItems && managedItems.map( (item) => {
      return (item && <div key={("group"+item.key)} className='c_ListGrouped-item'>
        <Button animated='fade' onClick={() => onSelection(item.key)}>
          <Button.Content visible>{item.key}</Button.Content>
          <Button.Content hidden><Icon name={icon} /></Button.Content>
        </Button>
      </div>);
    })}
  </div>);
};


export const ListSelector = ({managedItems, selectedItems, onToggleItem}) => {
  var alternateColor = false;
  var listIndex = 0;
  return (<div align='left' className='c_ListSelector'>
    <ul className="c_ListSelector list">
      <li key={'all'+listIndex}>
        <div className='c_ListSelector listElement bg-head'>
          <Checkbox checked={selectedItems.length === managedItems.length} onChange={() => {
            if (selectedItems.length === managedItems.length) {
              onToggleItem(false);
            } else {
              onToggleItem(true);
            };
          }}/>
          <label className='c_ListSelector listLabel'>"Toggle All"</label>
        </div>
      </li>
      {managedItems.map( (selection) => {
        alternateColor = !alternateColor;
        const currentlyInList = selectedItems.map(s => s.key).includes(selection.key);
        let className = 'c_ListSelector listElement '+(alternateColor ? 'bg-1' : 'bg-2');
        listIndex++;
        if (listIndex >= managedItems.length) className = className+' last';
        return (<li key={'sym'+listIndex}>
          <div className={className}>
            <Checkbox checked={currentlyInList} onChange={() => {
              onToggleItem(selection.key);
            }}/>
            <label className='c_ListSelector listLabel'>{`${selection.text}`}</label>
          </div>
        </li>);
      })}
    </ul>
  </div>);
};
