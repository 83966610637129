import React from 'react';
import { THF_SERVER_URL } from '../util';
import LoadingProgressShown from './LoadingProgressShown';
import ConfigurePayments from '../Components/ConfigurePayments';

export const CardsList = ({squareCards, selectedCard, onSetCard, userInfo, accessToken, permissions}) => {
  if (!squareCards) return (<div></div>);
  let cardsListElem = null;
  if (squareCards.length > 0) {
    cardsListElem = (<ul>
      <p>Select a card:</p>
      {squareCards.map(card => {
        return (<li key={card.id} onClick={()=> onSetCard(card)} 
          style={{border: selectedCard && selectedCard.id === card.id ? '1px solid blue' : 'none', cursor: 'pointer'}
        }>{card.card_brand} {card.exp_month}/{card.exp_year} ****{card.last_4}</li>)
      })}
    </ul>);
  } else {
    if (userInfo && accessToken && permissions) {
      cardsListElem = (<ConfigurePayments userInfo={userInfo} accessToken={accessToken} permissions={permissions}/>);
    } else {
      cardsListElem = (<div style={{fontSize: 18, fontWeight: 'bold', color: 'red'}}>WARNING: You have no Square cards for this profile.</div>);
    };
  };
  return cardsListElem;
};

const SquareCardManager = ({userInfo, onCardSelection, accessToken, permissions, ifNoCardShowAdd}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);

  const [squareCards, setSquareCards] = React.useState([]);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [squareCustomer, setSquareCustomer] = React.useState(null);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    if (userInfo) {
      fetch(
        THF_SERVER_URL+"get_or_create_customer_for_user_with_cards/"+userInfo.email,
        {signal: signal}
      ).then( (res) => res.json().then( (customerData) => {
        if(customerData.customer) setSquareCustomer(customerData.customer);
        if(customerData.cards) {
          setSquareCards(customerData.cards);
          if (customerData.cards.length > 0) {
            setSelectedCard(customerData.cards[0]);
          };
        };
        setLoading(false);
      })).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (SquareCardManager)", error);
        setDisplayError("Unable to communicate with server. Please try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [userInfo]); // Update when userInfo (from okta authState in App) changes

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  const setCard = (card) => {
    if (squareCustomer && selectedCard) {
      setSelectedCard(card);
      onCardSelection(squareCustomer, selectedCard);
    } else {
      onCardSelection(null, null);
    }
  };
  
  return (
    <CardsList squareCards={squareCards} selectedCard={selectedCard} onSetCard={() => setCard()} 
      accessToken={accessToken} userInfo={ifNoCardShowAdd ? userInfo : null} permissions={permissions}
    />
  );
};
export default SquareCardManager;
