import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';

import config from '../config';

const { issuer, clientId, redirectUri, scopes } = config.oidc;
export const OktaConfig = {
  issuer,     // issuer.split('/oauth2')[0],
  clientId,   // process.env.CLIENT_ID
  redirectUri,
  logo: 'images/svg/thf-text-black.jpg',
  i18n: {
    en: {
      'primaryauth.title': 'Sign in to THF Portal',
    },
  },
  features: {
    registration: true, // Enable self-service registration flow
    rememberMe: true,   // Setting to false will remove the checkbox to save username
  },
  pkce: true,
  authParams: {
    // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
    issuer,
    scopes,
  },
};


class CustomOktaLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionToken: null,
      email: '',
      password: ''
    };
    this.signIn = this.signIn.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  async signIn(event) {
    event.preventDefault();
    const transaction = await this.props.oktaAuth.signIn({
      username: this.state.email,
      password: this.state.password
    });

    if (transaction.status === 'SUCCESS') {
      this.props.oktaAuth.signInWithRedirect({sessionToken: transaction.sessionToken})
    } else {
      throw new Error('Could not sign in: ' + transaction.status);
    }
  }

  render() {
    return (
      <form onSubmit={this.signIn} className="login-form">
        <h2>Log In</h2>
        <p>Please login to continue</p>
        <label className="full-width-input">
          Email
          <input type="text" placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} required />
        </label>
        <label className="full-width-input">
          Password
          <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} required autoComplete="off" />
        </label>
        <button className="button">Login</button>
      </form>
    );
  }
};
export default withOktaAuth(CustomOktaLogin);


export class DefaultOktaLogin extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    /**
     * Note: when using the Sign-In Widget for an OIDC flow, it still
     * needs to be configured with the base URL for your Okta Org. Here
     * we derive it from the given issuer for convenience.
     */
    this.widget = new OktaSignIn(OktaConfig);
    this.widget.renderEl(
      {el: this.wrapper.current},
      this.props.onSuccess, 
      this.props.onError
    );
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    return <div ref={this.wrapper} />;
  }
};


export const signOutHandler = async (oktaAuth) => {
  await oktaAuth.signOut();
  //oktaAuth.tokenManager.clear();
};
