import posthog from 'posthog-js';

import { trackingAnalytics } from './Analytics';

// blacklist DOM from tracking by setting it's class to 'ph-no-capture'
export default function postHogAnalyticsPlugin(userConfig) {
  var isReady;

  return {
    name: 'postHog-plugin',

    initialize: ({ config }) => {
      if (!trackingAnalytics.enabled) return;
      posthog.init(userConfig.measurementID, {
        api_host: userConfig.api_host,
        autocapture: {
          // DOM events from this list ['click', 'change', 'submit']
          event_allowlist: ['click'], 
          // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
          element_allowlist: ['button'], 
          // List of CSS selectors,
          css_selector_allowlist: ['[ph-autocapture]'],
          // strings or RegExps
          url_allowlist: ['posthog.com\.\/docs\/.*'], 
        },
        loaded: function() { isReady = true; },
      });
      if (userConfig.debug) console.log("posthog analytics plugin, initializing", {config, userConfig});
    },

    page: ({ payload }) => {
      // postHog JS library automatically captures user events for 'pageviews', including the URL
      //posthog.capture('Pageview', payload.properties);
      if (userConfig.debug) console.log("posthog analytics plugin, page:", {payload});
    },

    track: ({ payload }) => {
      // most things are auto-captured events, such as any click, change of input, or submission associated
      // with a, button, form, input, select, textarea, and label tags
      posthog.capture(payload.event, payload.properties);
      if (userConfig.debug) console.log("posthog analytics plugin, track:", {payload});
    },

    identify: ({ payload }) => {
      posthog.identify(payload.userId, payload.traits);
      if (userConfig.debug) console.log("posthog analytics plugin, identify:", {payload});
    },

    loaded: () => {
      if (userConfig.debug) console.log("posthog analytics ready.", {isReady, userConfig});
      return !!isReady
    },
  };
};