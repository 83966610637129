// TODO: get this from env var
export const THF_SERVER_URL = "https://thf.ai/";
//export const THF_SERVER_URL = "http://localhost:5000/";

export const DEFAULT_SYMBOL = "SPY";

export const RIGHTS = ['calls', 'puts'];

export function floatPadded(floatValue, decimalPlaces=2, subAbsoluteZero=null) {
  if (!floatValue) floatValue = 0.0;
  if (subAbsoluteZero && floatValue === 0.0) return subAbsoluteZero;
  let returnString = floatValue.toFixed(decimalPlaces);
  const wholeAndPartial = returnString.split(".");
  if (!wholeAndPartial[1]) return wholeAndPartial[0];
  const addZeros = decimalPlaces - wholeAndPartial[1].length;
  if (addZeros > 0) {
    loop(addZeros, i => wholeAndPartial[1]+='0');
  };
  return wholeAndPartial[0]+'.'+wholeAndPartial[1];
};

export function zero_pad(x){
  if(x < 10) return "0" + x;
  return x;
};

export const downloadCsv = (title, csv) => {
  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  };
  let data = encodeURI(csv);

  let link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', title+'.csv');
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
};

export function tooltipCollector() {
  const _collection = [];
  let _min = { y: 0, value: 0 };
  let _max = { y: 1, value: 1 };
  function _setMaxAndMin() {
    const ys = _collection.map(obj => obj.y);
    const maxY = Math.max(...ys);
    const maxYIndex = ys.indexOf(maxY);
    _max = _collection[maxYIndex];
    const minY = Math.min(...ys);
    const minYIndex = ys.indexOf(minY);
    _min = _collection[minYIndex];
  }
  return {
    collect: (value, y) => {
      _collection.push({ value, y });
      _setMaxAndMin(_collection);
    },
    maxAndMin: () => {
      return {
        max: { ..._max },
        min: { ..._min },
      };
    },
  };
}

export const MONTHS = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];
export const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

export function getTimeSince(dateObj) {
  if (dateObj == null) return "";
  const currentTime = new Date();
  function ageInMonths(dateObj) {
    var months;
    months = (currentTime.getFullYear() - dateObj.getFullYear()) * 12;
    months -= currentTime.getMonth();
    months += dateObj.getMonth();
    return months <= 0 ? 0 : months;
  };
  const difference = (currentTime - dateObj);
  const yrs = Math.floor(difference / (1000*360*24*365.25));
  const months = ageInMonths(dateObj);
  const days = difference / (1000*360*24);
  const hours = difference / (1000*60*60);
  const minutes = difference / (1000*60);
  const seconds = difference / (1000);
  const ageHash = {
    totalYears: yrs,
    totalMonths: months,
    totalDays: Math.floor(days),
    hours: Math.floor(hours) % 24,
    minutes: Math.floor(minutes) % 60,
    seconds: Math.floor(seconds) % 60
  };
  //console.log("Calculation age since", {ageHash});
  return ageHash;
};

export const TimeSince = ({fromDate}) => {
  const timeAges = getTimeSince(fromDate);
  var ageDisplayString = timeAges.minutes + "m ";
  if (timeAges.hours >= 1) {
    ageDisplayString = ageDisplayString + timeAges.hours + "h";
  };
  return (<div>
    <p>{ageDisplayString} old</p>
  </div>);
};

export const TimeTill = ({toDate}) => {
  const timeAges = getTimeSince(toDate);
  var ageDisplayString = -timeAges.minutes+"m";
  if (-timeAges.hours >= 1) {
    ageDisplayString = -timeAges.hours+"h "+ageDisplayString;
  };
  return (<div>
    <p>{ageDisplayString}</p>
  </div>);
};

export function dateObjFromStr(stringDate) {
  if (stringDate == null) return undefined;
  const yearDayMonth = [stringDate.slice(0, 4), stringDate.slice(4,6), stringDate.slice(6,8)];
  if (yearDayMonth[0] && yearDayMonth[1] && yearDayMonth[2]) {
    return new Date(yearDayMonth[0]+"/"+yearDayMonth[1]+"/"+yearDayMonth[2]);
  } else {
    console.error("utils having issues with parsing string date.", {stringDate, yearDayMonth});
    return undefined;
  };
};

export function zeroPaddedDateParts(date) {
  if (!date) {
    console.error("utils having issues with a padded date.", {date});
    return undefined;
  };
  var year = date.getFullYear();
  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;
  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  const weekday = DAYS[date.getDay()];
  const monthName = MONTHS[date.getMonth()];
  return {year,month,day,weekday,monthName};
};

export function getTimeStringDateFormat({stringDate, shorthand=false, withDay=false}) {
  if (stringDate == null) return undefined;
  const date = dateObjFromStr(stringDate);
  if (!date) {
    console.error("utils having issues converting string to date.", {stringDate});
    return undefined;
  };
  const {year, month, day, weekday} = zeroPaddedDateParts(date);
  if (withDay && shorthand) {
    return month + '_' + day + " " + weekday;
  } else if (withDay) {
    return month + '/' + day + '/' + year + " " + weekday;
  } else if (shorthand) {
    return month + '/' + day;
  } else {
    return month + '/' + day + '/' + year;
  };
};

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export function randomColor() {
  const randomVal = () => ("0123456789abcef".split("")[Math.floor(15 * Math.random())]);
  const randomColor = () => ("#"+randomVal()+randomVal()+randomVal()+randomVal()+randomVal()+randomVal());
  return randomColor();
};

export const HISTORICAL_DOWNLOAD_URL = 'yahoo-daily-history/$SYMBOL/$PERIOD1/$PERIOD2';

export function dateToYahooFormat(date){
  return Math.floor((date - 1)/1000);
}

export function getDailyHistory(symbol, accessToken, cb) {
  const today = new Date();
  const thePast = new Date(today - 1000*60*60*24*180);
  const url = (HISTORICAL_DOWNLOAD_URL+"?access_token="+accessToken)
    .replace(/\$SYMBOL/, symbol)
    .replace(/\$PERIOD1/, dateToYahooFormat(thePast))
    .replace(/\$PERIOD2/, dateToYahooFormat(today));
  fetch(THF_SERVER_URL+url).then(r => r.text()).then((r) => {
    const lines = r.split("\n");
    const headers = lines[0].split(",");
    let parsed = [];
    for (let i = 1; i< lines.length; i+=1) {
      let row = {};
      const line = lines[i].split(",");
      for (let j =0; j<headers.length; j+=1) {
        const header = headers[j];
        row[header] = header === 'Date' ? line[j] : parseFloat(line[j]);
      }
      parsed.push(row);
    }
    if (cb) cb(parsed);
  })
}

export const PAGES_MAP = {
  "symbol_tickers": {use:true, name:"Tickers", icon:"images/svg/heatmap.svg"},
  "todd": {use:true, name:"Todd", icon:"images/svg/light-bulb.svg"},
  "probability_zones": {use:false, name:"Probability Zones", icon: 'images/svg/temp.svg'},

  "data-marketplace": {use:true, name:"Data Marketplace"},
  "heatmap": {use:true, name:"Heatmap"},
  "history": {use:true, name:"History"},
  "logs": {use:true, name:"Logs"},
  "managed_symbols": {use:true, name:"Managed Symbols"},
  "messages": {use:false, name:"Messages"},
  "profile": {use:true, name:"Profile"},
  "top10": {use:true, name:"Top10"},
};

export const LIST_TYPES = [
  {key: "buy", value: "buy", text: "buy"},
  {key: "sell", value: "sell", text: "sell"},
];

export const DEFAULT_PREFERRED_VIEWS = {
  "heatmap": {
    "old_contracts": false,
    "calculation_age": false,
    "heat_top5": true,
    "showContractCalculationDate": false,
    "showTipHeatmapWhatIs": true
  }, 
  "symbolOverview": {
    "probabilityZones": true,
    "heatmap": true,
    "history": true,
    "logs": true
  },
};
