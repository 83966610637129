import React from 'react';
import { THF_SERVER_URL } from '../util';

const BackendStatus = ({accessToken}) => {
  const [statuses, setStatuses] = React.useState({});
  const [triggerCount, setTriggerCount] = React.useState(1);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken && triggerCount) {
      fetch(THF_SERVER_URL+"boolean-statuses?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => {
        res.json().then( (stats) => {
          setStatuses(stats);
        });
      }).catch( (error) => { 
        if (error.name === 'AbortError') return;
        console.error("Error: fetch (BackendStatus)", {index, error});
      }).finally( () => {
        window.setTimeout(()=>{
          setTriggerCount(triggerCount + 1);
        },60*1000);
      });
    };
    return () => controller.abort();
  }, [accessToken, triggerCount]);

  const doweek = (new Date()).getUTCDay();
  const hrs = (new Date()).getUTCHours();
  const mins = (new Date()).getUTCMinutes();
  let marketIsOpen = (// mon-fri 1:30pm-8:00pm UTC
    doweek !== 6 && doweek !== 0 && hrs <= 20 && hrs >= 13 &&
    (hrs !== 13 || (hrs === 13 && mins >= 30)) && 
    (hrs !== 20 || (hrs === 20 && mins <= 1))
  );
  let timeUntilMarketOpen = Math.abs(((13*60)+30) - ((hrs * 60) + mins));
  if (doweek == 0) {
    timeUntilMarketOpen += (60*24);
  } else if (doweek == 6) {
    timeUntilMarketOpen += (2*60*24);
  };

  if (marketIsOpen) {
    return (<ul>
      {Object.keys(statuses).map(stat => {
        return <li key={stat}>{stat}: <span style={{padding:'5px',color:'white',backgroundColor:(statuses[stat]?'green':'red')}}>
          {statuses[stat] ? 'LIVE' : 'DOWN'}
        </span></li>;
      })}
    </ul>);
  } else {
    return <p>Market opens in {timeUntilMarketOpen} minutes</p>;
  };
};
export default BackendStatus;
