import React from 'react';
import { LineChart, Line, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, ReferenceLine } from 'recharts';

import { THF_SERVER_URL } from '../util';
import LoadingProgressShown from '../Components/LoadingProgressShown';

const DealerGammaChart = ({symbol, accessToken}) => {
  const [loading, setLoading] = React.useState(true);
  const [displayError, setDisplayError] = React.useState(null);

  const [dealerGamma, setDealerGamma] = React.useState(null);
  const [lastContract, setLastContract] = React.useState(0);

  function setLast(data) {
    var lastStrike = Number.MAX_SAFE_INTEGER;
    for (let entry of data.gammas) { if (lastStrike > entry.strike) lastStrike = entry.strike; };
    setLastContract(lastStrike);
  };

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    if (accessToken && symbol !== "") {
      fetch(THF_SERVER_URL+"dealergamma/"+symbol+"?access_token="+accessToken, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal: signal,
    }).then( (res) => res.json().then( (data) => {
        setLast(data)
        setDealerGamma(data);
        setLoading(false);
      })).catch( (error) => {
        if (error.name === 'AbortError') return;
        setDisplayError("Fetching of symbol's dealer gamma data has failed, please try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [accessToken, symbol]);

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  return (<div style={{width:'100%', height:'400px', paddingBottom:'24px'}}>
    {dealerGamma && <ResponsiveContainer width="100%" height="100%">
      <LineChart width={730} height={250} data={dealerGamma.gammas}>
        <CartesianGrid strokeDasharray="1 1"/>
        <YAxis dataKey="gamma" label={
          {value:"Dealer Gamma in $mm", angle:-90, position:'middle', fontSize:18, fontWeight:'bold'}
        }/>
        <XAxis padding={{left:20, right:40}} label="" dataKey="strike"/>
        <Tooltip/>
        <Legend/>
        <Line name={symbol} type="monotone" dataKey="gamma" dot={false} strokeWidth={6} stroke="#994545"/>
        {lastContract && <ReferenceLine x={lastContract} strokeDasharray="4 4" strokeWidth={4} stroke="#8884d8" label={
          {value:"Last", angle:-90, position:'right', offset:12, fontSize:18, fontWeight:'bold'}
        }/>}
        <ReferenceLine x={dealerGamma.zerogex} strokeDasharray="8 4" strokeWidth={4} stroke="#333333" label={
          {value:"Zero GEX: "+dealerGamma.zerogex, angle:-90, position:'right', offset:12, fontSize:18, fontWeight:'bold'}
        }/>
      </LineChart>
    </ResponsiveContainer>}
  </div>);
};
export default DealerGammaChart;
