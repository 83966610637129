import React from 'react';
import {useParams} from 'react-router-dom';
import Login from '../Components/Login';
import { THF_SERVER_URL } from '../util';

const Referral = ({accessToken}) => {
  const [referrer, setReferrer] = React.useState({});
  const [invalid, setInvalid] = React.useState(false);
  const [signedUp, setSignedUp] = React.useState(false);
  let {code} = useParams();

  const handleSuccessfulSignup = (response) => {
    console.log(response);
    setSignedUp(true);
  }

  React.useEffect( () => {
    if (!accessToken && code !== null && code.length != 0) {
      fetch(THF_SERVER_URL+"referrer/"+code)
      .then( (res) => {
        return res.json();
      }).then((j) => {
        setReferrer(j);
      }).catch( (error) => {
        setInvalid(true);
      }).finally( () => {
      });
    };
  }, [accessToken, code]);
  
  if (invalid) {
    return <p>invalid referral code</p>;
  }
  
  console.log(referrer);
  if (accessToken) {
    return <p>you already have an account</p>;
  }

  if (Object.keys(referrer).length === 0) {
    return <p>Loading...</p>;
  }
  
  let body = null;
  if (signedUp) {
    body = <p>check your email for signup confirmation</p>;
  } else {
    body = <Login register={true} code={code} registrationCallback={handleSuccessfulSignup} />;
  }

  return (<div className="pageContent">
    <p>Welcome to the Referral Page for {referrer.channel_name || (referrer.firstName + referrer.lastName)}</p>
    {body}
  </div>);
};

export default Referral;
