import React from 'react';
import { Checkbox, Tab } from 'semantic-ui-react';

import { THF_SERVER_URL, getTimeStringDateFormat, TimeSince } from '../util';
import HoverToolTip from './HoverToolTip';
import { HelperBelowIntrinsicError } from './Heatmap';
import FloatClickToCopy from './FloatClickToCopy';

const enterMarkedTrade = (direction, accessToken, info) => {
  const trade = {
    symbol: info.symbol,
    contract: info.contract,
    contract_type: info.contract_type,
    action: direction,
    entry_price: info.market_price,
    exit_price: null,
    thf_price_at_entry: info.thf_price,
  };
  fetch(
    THF_SERVER_URL +"marked-trades?access_token="+accessToken,
    {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(trade)
    }
  ).then(r=> r.json()).then(j=> {
    console.log(j);
    alert('trade marked');
  })
};

const ContractInfo = ({info, invertDiff, showRealCost, permissions, updateUserPermissions, accessToken}) => {
  const [showCalculationDate, setShowCalculationDate] = React.useState(permissions.preferredViews.heatmap.showContractCalculationDate);
  const [showDeltas, setShowDeltas] = React.useState(permissions.preferredViews.heatmap.showDeltas);

  if (Object.keys(info).length < 1) {
    return (<div className="empty-contract-info">
      <h3>No Contract Selected</h3>
      <p>Please select an element from either heat map or from a top5 list.</p>
    </div>);
  };

  const TableLabelWithData = ({label, value, tipDetails, align="right", valueAlign="center"}) => {
    return (<tr>
      {tipDetails ? (
        <HoverToolTip label={<td className={"align-"+align} style={{maxWidth:'132px'}}>{label}</td>} contents={tipDetails} />
      ) : (
        <td className={"align-"+align} style={{maxWidth:'132px', width:'40%'}}>{label}</td>
      )}
      <td className={"align-"+valueAlign} style={{width:'60%'}}>{value}</td>
    </tr>);
  };

  let diff = (invertDiff ? info.diff_market: info.diff_THF);

  return (<div className="contract-info" align="center">
    <HelperBelowIntrinsicError />
    <div style={{display:'block',width:'fit-content',marginLeft:'32px'}} align='left'>
      <ul className="plain-left-list" style={{width:'300px',marginTop:'24px'}}>
        <li><Checkbox label="Show full calculation date" toggle checked={showCalculationDate} onChange={() => {
          var updatedPreferredViews = permissions.preferredViews;
          updatedPreferredViews.heatmap.showContractCalculationDate = !showCalculationDate;
          updateUserPermissions('preferredViews', updatedPreferredViews);
          setShowCalculationDate(!showCalculationDate);
        }}/></li>
        <li><Checkbox label="Show THF and Market Deltas" toggle checked={showDeltas} onChange={() => {
          var updatedPreferredViews = permissions.preferredViews;
          updatedPreferredViews.heatmap.showDeltas = !showDeltas;
          updateUserPermissions('preferredViews', updatedPreferredViews);
          setShowDeltas(!showDeltas);
        }}/></li>
      </ul>
    </div>

    <table className="contract-info">
      <tbody>
        <TableLabelWithData label="Symbol" value={info.symbol} />
        <TableLabelWithData label="Right" value={info.right} />
        <TableLabelWithData label="Strike" value={info.strike} />
      </tbody>

      <tbody>
        <TableLabelWithData label="Expiration" value={getTimeStringDateFormat({stringDate:info.expiration, withDay:true})} />
        <TableLabelWithData label="Market Price" value={
          info.market_price && (typeof info.market_price === "string" ? info.market_price : 
            <FloatClickToCopy value={(showRealCost ? info.market_price*100 : info.market_price)} message="$%value%" decimalPlaces={(showRealCost ? 2 : 4)} />
          )
        }/>
      </tbody>

      <tbody>
        <TableLabelWithData label="THF Price" value={info.thf_price ? <FloatClickToCopy value={(showRealCost ? info.thf_price*100 : info.thf_price)} message="$%value%" decimalPlaces={(showRealCost ? 2 : 4)}/> : ""}/>
      </tbody>

      <tbody>
        <TableLabelWithData label="Market Delta" value={
          info.market_delta && (typeof info.market_delta === "string" ? info.market_delta : <FloatClickToCopy value={info.market_delta}/>)
        }/>
        <TableLabelWithData label="THF Delta" value={info.thf_delta && <FloatClickToCopy value={info.thf_delta} message="%value%"/>} />
      </tbody>

      <tbody>
        <TableLabelWithData label="Difference" tipDetails={"volatility risk premium "+(invertDiff ? "market vs THF" : "THF vs market")} value={diff && <FloatClickToCopy value={diff} message="%value%%" decimalPlaces={0}/>} />
        {info.accuracy && <TableLabelWithData label="Accuracy" tipDetails="Forecasting Accuracy Score"
          value={(info.accuracy ? <FloatClickToCopy value={(1 - info.accuracy) * 100}  message="%value%%" decimalPlaces={0}/> : '--')}
        />}
        {showCalculationDate ? (
          <TableLabelWithData label="Calculated At" value={info.date && info.date.toUTCString()} />
        ) : (
          <TableLabelWithData label="Calculation Age" value={<TimeSince fromDate={new Date(info.date)} />} />
        )}
      </tbody>
    </table>

    <p>For theoretical paper-trading purposes, you can "Mark" this trade:</p>
    <div>
      <button onClick={()=> enterMarkedTrade('buy', accessToken, info)}>"Buy" this Contract</button>
      <button onClick={()=> enterMarkedTrade('sell', accessToken, info)}>"Sell" this Contract</button>
    </div>
  </div>);
};
export default ContractInfo;

export const ContractBooklet = ({permissions, updateUserPermissions, accessToken, modelError, contracts, hoveringOver, selectedContracts=[]}) => {
  const [tabPanes, setTabPanes] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState(0);

  React.useEffect(() => {
    if (selectedContracts.length > 0) buildTabPanes();
    else setTabPanes([]);
  }, [contracts, selectedContracts]);

  /*
  var lastHover = null;
  React.useEffect(() => {
    if (lastHover === hoveringOver) return;
    lastHover = hoveringOver;
    const existing = tabPanes.find(tab => tab.key === hoveringOver);
    if (existing) {
      let alsoSelected = selectedContracts.find(c => c === hoveringOver);
      if (!alsoSelected) {
        tabPanes.splice(existing.index, 1);
        setTabPanes(tabPanes);
      };
    } else {
      tabPanes.push(newPane(hoveringOver, tabPanes.length));
      setTabPanes(tabPanes);
    };
  }, [hoveringOver]);
  */

  function getContractInfo(contractUIid) {
    let contractInfo = {};
    contractInfo.symbol = contractUIid.split("_")[0];
    let dateKey = contractUIid.split("_")[1];
    let strikeKey = contractUIid.split("_")[2];
    if (strikeKey) strikeKey = strikeKey.split(".0-")[0];
    contractInfo.right = contractUIid.split("-")[1];
    contractInfo.strike = strikeKey;
    contractInfo.expiration = dateKey;
    const heatmapData = contractInfo.right === 'calls' ? contracts.calls : contracts.puts;
    let contractBlob = null;
    if (heatmapData[dateKey]) contractBlob = heatmapData[dateKey][strikeKey];
    if(contractBlob) {
      contractInfo.market_price = contractBlob.market_price;
      contractInfo.thf_price = contractBlob.thf_price;
      contractInfo.market_delta = contractBlob.market_delta;
      contractInfo.thf_delta = contractBlob.thf_delta;
      contractInfo.diff_THF = contractBlob.diff_THF * 100;
      contractInfo.diff_market = contractBlob.diff_market * 100;
      contractInfo.accuracy = modelError;
      contractInfo.date = contractBlob.date;
    } else {
      return null;
      //console.warn("Heatmap Component can't locate contractBlob:", {contractRef:contractUIid, expireDate, strikeKey, heatmapData});
    };
    return contractInfo;
  };

  function rendererContract(uiId) {
    const data = getContractInfo(uiId);
    console.log("contract rendering", {uiId, data});
    if (data)
      return <ContractInfo info={data} permissions={permissions} updateUserPermissions={updateUserPermissions} accessToken={accessToken}/>;
    else
      return <React.Fragment/>
  };

  function newPane(uiId, i) {
    return { key: uiId, index: i, menuItem: ''+i, render: () => rendererContract(uiId) };
  };

  function buildTabPanes() {
    const panes = selectedContracts.map( (uiId, i) => {
      return newPane(uiId, i);
    });
    setTabPanes(panes);
  };

  return (<div className="contractBooklet">
    {selectedContracts.length > 0  ? (
      <Tab panes={tabPanes} activeIndex={currentTab} onTabChange={(e,data) => setCurrentTab(data.activeIndex)}
        menu={{secondary:false,pointing:false,fluid:true,vertical:false,tabular:true,attached:'bottom'}}
      />
    ) : (
      <p>Please select a contract from the heatmap.</p>
    )}
  </div>);
};
