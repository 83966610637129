import React from 'react';
import { Checkbox, Input } from 'semantic-ui-react';
import { LineChart, Line, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { THF_SERVER_URL, tooltipCollector, randomColor, getTimeStringDateFormat } from '../util';

import LoadingProgressShown from '../Components/LoadingProgressShown';
import HoverToolTip from '../Components/HoverToolTip';
import { CustomizedTick, CustomTooltip } from '../Components/CustomizedTick';
import Hurst from '../Components/Hurst';
import ModelErrorsChart from '../Components/ModelErrorsChart';
import FloatClickToCopy from '../Components/FloatClickToCopy';

const LogsPage = ({accessToken, permissions, symbol}) => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);

  const [fullLogs, setFullLogs] = React.useState(false);
  const [showErrorsTable, setShowErrorsTable] = React.useState(false);
  const [showMeanAbsoluteTable, setShowMeanAbsoluteTable] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [errorColors, setErrorColors] = React.useState({});
  const [lines, setLines] = React.useState([]);
  const [arbitraryThreshold, setArbitraryThreshold] = React.useState("");
  const THE_COLLECTOR = tooltipCollector();

  const handleNetError = (error, forFetch) => {
    if (error.name === 'AbortError') return;
    //console.error(`Error: (LogsPage) fetching '${forFetch}'`, error, error.name);
    setDisplayError("Unable to communicate with server. Please try again later.");
  }

  React.useEffect(() => {
    if (permissions) {
      setIsAdmin(permissions.admin)
    } else {
      setIsAdmin(false);
    };
  }, [permissions]);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken) {
      setLoading(true);
      const loc = THF_SERVER_URL+"errors?access_token="+accessToken;
      fetch(loc, {signal: signal}).then( (res) => {
        res.json().then( (list) => {
          setErrors(list);
          setLoading(false);
        });
      }).catch( (err) => {
        handleNetError(err, 'errors');
      }).finally( () => {
        //console.log("Success: (LogsPage) fetch 'errors'.");
      });
    };
    return () => controller.abort();
  }, [accessToken])

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken && isAdmin) {
      fetch(THF_SERVER_URL+"logs?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => {
        res.json().then( (list) => {
          setResults(list);
        });
      }).catch( (err) => {
        handleNetError(err, 'logs');
      }).finally( () => {
      });
    };
    return () => {
      controller.abort();
    }
  }, [accessToken, isAdmin])

  React.useEffect( () => {
    const errorKeys = Object.keys(errors);
    if (errors && errorKeys.length > 0) {
      let temp = {};
      let colorMapping = {};
      for (let i = 0; i < errorKeys.length; i+=1) {
        let errSymbol = errorKeys[i];
        errors[errSymbol].forEach(errorObj => {
          temp[errorObj.calculatedAt] = temp[errorObj.calculatedAt] || {};
          temp[errorObj.calculatedAt][errSymbol] = errorObj.mean_absolute_percentage_error;
        });
        colorMapping[errSymbol] = randomColor();
      };
      setErrorColors(colorMapping);
      let formattedData = Object.keys(temp).map( dateStr => {
        return {
          date: dateStr,
          renderer: getTimeStringDateFormat({stringDate:dateStr, shorthand:true}),
          "model stable": 0.18,
          ...temp[dateStr]
        };
      }).sort((a,b) => parseInt(a.date) - parseInt(b.date));
      setLines(formattedData);
    };
  }, [errors]);

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  const MappedSortedErrorsTable = () => {
    let recentSymbolErrors = Object.keys(errors).map((symbolKey) => {
      return errors[symbolKey].sort((a,b) => b._ts - a._ts)[0];
    });
    recentSymbolErrors.sort((a,b) => b.mean_absolute_percentage_error - a.mean_absolute_percentage_error);

    return (<table className="even-cells logs-errorsTable no-scroll">
      <thead><tr>
        <th style={{width:"120px"}}>symbol</th>
        <th>calculated at</th>
        <th>most recent error</th>
      </tr></thead>
      <tbody>
        {recentSymbolErrors.map((errData) => {
          if (!errData) return <React.Fragment />;
          return (<tr key={errData.id}>
            <td style={{width:"120px"}}>{errData.symbol}</td>
            <td>{new Date(errData._ts * 1000).toLocaleString()}</td>
            <td>{<FloatClickToCopy value={errData.mean_absolute_percentage_error} decimalPlaces={10}/>}</td>
          </tr>);
        })}
      </tbody>
    </table>);
  };

  const MeanAbsoluteTable = () => {
    const mostRecentOnly = Object.keys(errors).map( (symbol) => {
      return errors[symbol].sort((a,b) => b.date - a.date)[0];
    });
    mostRecentOnly.sort((a,b) => a.mean_absolute_percentage_error - b.mean_absolute_percentage_error);

    return (<table className="even-cells logs-hurstTable no-scroll">
      <thead><tr>
        <th style={{width:"120px"}}>symbol</th>
        <th>calculated at</th>
        <th>most recent stability value</th>
      </tr></thead>
      <tbody>
        {mostRecentOnly.map( (symbolData) => {
          return (<tr key={symbolData.id}>
            <td style={{width:"120px"}}>{symbolData.symbol}</td>
            <td>{new Date(symbolData._ts * 1000).toLocaleString()}</td>
            <td>{<FloatClickToCopy value={symbolData.mean_absolute_percentage_error} decimalPlaces={10}/>}</td>
          </tr>);
        })}
      </tbody>
    </table>);
  };

  return (<div className="pageContent">
    <ModelErrorsChart accessToken={accessToken} symbol={symbol}/>
    <hr className="section"/>

    <h5>mean absolute percentage error</h5>
    {lines.length > 0 && (<div style={{height:'450px'}}>
      <div style={{width:'100%', height:'400px'}}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={800} height={400} data={lines}>
            <Legend />
            <Tooltip content={<CustomTooltip THE_COLLECTOR={THE_COLLECTOR} />} />
            <YAxis tick={<CustomizedTick THE_COLLECTOR={THE_COLLECTOR} />} />
            <XAxis dataKey="renderer" />
            {Object.keys(errors).map(errSymbol => <Line key={errSymbol} type="monotone" dataKey={errSymbol} stroke={errorColors[errSymbol]} connectNulls />)}
            {arbitraryThreshold !== "" && 
              <ReferenceLine y={arbitraryThreshold} label="AT" stroke="red" strokeDasharray="3 3" />
            }
          </LineChart>
        </ResponsiveContainer>
        <Input placeholder='Arbitrary Threshold' onChange={ (event, data) => {
          let newFloat = parseFloat(data.value);
          if (isNaN(newFloat)) setArbitraryThreshold("");
          else setArbitraryThreshold(newFloat);
        }}/>
      </div>
    </div>)}
    
    <div align='center'>
      <div style={{display:'block',width:'fit-content',width:'fit-content',margin:'24px'}} align='left'>
        <Checkbox label="Toggle mean squared error table view" toggle onChange={() => {
  				setShowMeanAbsoluteTable(!showMeanAbsoluteTable);
  			}} checked={showMeanAbsoluteTable}/>
      </div>
    </div>
    {showMeanAbsoluteTable && <div align="center"> <MeanAbsoluteTable/> </div>}
    <hr className="section"/>

    <Hurst isAdmin={isAdmin} accessToken={accessToken} />

    {isAdmin && <React.Fragment>
      <hr className="section"/>
      <div align='center'>
        <div style={{display:'block',width:'fit-content',width:'300px',margin:'24px'}} align='left'>
          <Checkbox label="Toggle errors table display" toggle onChange={(e, data) => {
  					setShowErrorsTable(data.checked);
  				}} checked={showErrorsTable}/>
        </div>
      </div>
      {showErrorsTable && errors && <div align="center"> <MappedSortedErrorsTable/> </div>}

      <div align='center'>
        <div style={{display:'block',width:'fit-content',width:'300px',margin:'24px'}} align='left'>
          <Checkbox label="Toggle full logs display" toggle onChange={() => {
  					setFullLogs(!fullLogs);
  				}} checked={fullLogs}/>
        </div>
      </div>
      {fullLogs && <div align='center'>
        <table className="even-cells thf-logs">
          <thead>
            <tr>
              <th>time</th>
              <th>table</th>
              <th>action</th>
              <th>data</th>
            </tr>
          </thead>
          <tbody>
            {results && results.map((log) => {
              let data = log.data;
              if (typeof data !== 'string') {
                console.warn("(LogsPage) Issue with log entry:", {log});
                data = '** Check web console for data.';
              };
              return (<tr key={log.id}>
                <td>{new Date(log._ts * 1000).toLocaleString()}</td>
                <td>{log.table}</td>
                <td>{log.action}</td>
                <HoverToolTip label={<td>"View Data"</td>} contents={data} />
              </tr>);
            })}
          </tbody>
        </table>
      </div>}
    </React.Fragment>}
  </div>);
};

export default LogsPage;
