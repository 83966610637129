// https://stackoverflow.com/questions/64501313/recharts-how-is-it-possible-to-show-only-the-value-of-one-data-point-inside-a-t
import React from 'react';

export function CustomizedTick(props) {
  const { x, y, payload, THE_COLLECTOR } = props;
  THE_COLLECTOR.collect(payload.value, y);
  return (
    <g>
      <text x={x} y={y} fill='#5d6571' textAnchor='end' dy={16}>{payload.value}</text>
    </g>
  );
}

export function CustomTooltip({
  active,
  payload,
  label,
  coordinate,
  THE_COLLECTOR
}) {

  if (payload === null) return null;

  if (active) {
    const { min, max } = THE_COLLECTOR.maxAndMin();
    const threshold = min.value / 30;
    const deltaY = max.y - min.y;
    const deltaValue = max.value - min.value;
    const cursorValue = min.value - deltaValue * ((min.y - coordinate.y) / deltaY);
    const points = payload.map(p => {
      const { color, stroke, dataKey, fill, name, payload } = p;
      return {
        color,
        stroke,
        dataKey,
        fill,
        name,
        value: payload[dataKey],
      };
    });
    const nearestPointIndexes = points.reduce((acc, curr, index) => {
      const deltaValue = Math.abs(curr.value - cursorValue);
      if (acc.length === 0) return (deltaValue < threshold) ? [{ index, deltaValue }] : [];
      if (Math.abs(deltaValue - acc[0].deltaValue) < threshold) return acc.concat([{ index, deltaValue }]);
      return acc;
    }, []);

    if (nearestPointIndexes.length === 0) return null;
    const nearestPoints = nearestPointIndexes
      .map(({ index }) => points[index]);
    return <div style={{background: 'white', border: '1px solid black'}}>
      <p>{label}</p>
      {nearestPoints.map((nearestPoint, index) =>
        <div key={`nearestPoint_${index}`} >
          <p style={{color: nearestPoint.color}}>
            {`${nearestPoint.name}: ${nearestPoint.value}`}
          </p>
        </div>
      )}
    </div>
  }
  return null;
}
