import React from 'react';
import { Popup } from 'semantic-ui-react';

const HoverToolTip = ({label, contents}) => {
  return (<Popup hideOnScroll size='small' inverted position='left center' trigger={label}>
    <Popup.Content>{contents}</Popup.Content>
  </Popup>);
};
export default HoverToolTip;

export const TimedTip = ({label, tipContent}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const timerRef = React.useRef(null);

  const handleOpen = () => {
    setIsOpen(true);
    timerRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  const handleClose = () => {
    setIsOpen(false);
    clearTimeout(timerRef.current);
  };

  return (<Popup
    trigger={label}
    on='click'
    content={tipContent}
    hideOnScroll
    open={isOpen}
    onClose={() => handleClose()}
    onOpen={() => handleOpen()}
    position='right center'
  />)
};
