import React from 'react';
import { useNavigate } from "react-router-dom";
import { Loader } from 'semantic-ui-react'

const LoadingProgressShown = ({type, text}) => {
  switch(type) {
    case 'none':
      return (<div></div>);
    case 'text':
      return (<p>{text}</p>);
    case 'spinner':
      return (<Loader active inline='centered' />);
    case 'dna':
      return (<div></div>);
    default:
      return (<p>Loading...</p>);
  };
};
export default LoadingProgressShown;


export const AuthenticatingPermissions = ({permissions, text="Please wait, validating credentials..."}) => {
  const EXCLUDE_PATH = [
    '/profile', '/login', '/error'
  ];
  if (permissions || EXCLUDE_PATH.includes(window.location.pathname)) return (<React.Fragment/>);

  const navigate = useNavigate();
  React.useEffect( () => {
    const timer = setTimeout(() => {
      if (!EXCLUDE_PATH.includes(window.location.pathname)) navigate('/error?code=502');
    }, 1000*80);
    return () => clearTimeout(timer);
  }, []);
  
  return (<div> 
    <p>{text}</p>
    <Loader active inline='centered' />
  </div>);
};