import React from 'react';
import { Dropdown, Header } from 'semantic-ui-react';
import { THF_SERVER_URL } from '../util';

import LoadingProgressShown from '../Components/LoadingProgressShown';

import '../css/trading.css';

const RISK_CHOICES = [
  { key: 0.25, text: '0.25%', value: 0.0025 },
  { key: 0.5, text: '0.5%', value: 0.005 },
  { key: 0.75, text: '0.75%', value: 0.0075 },
  { key: 1.0, text: '1.0%', value: 0.01 },
  { key: 1.25, text: '1.25%', value: 0.0125 },
  { key: 1.5, text: '1.5%', value: 0.015 },
  { key: 1.75, text: '1.75%', value: 0.0175 },
  { key: 2.0, text: '2.0%', value: 0.02 },
  { key: 2.25, text: '2.25%', value: 0.0225 },
  { key: 2.5, text: '2.5%', value: 0.025 },
  { key: 2.75, text: '2.75%', value: 0.0275 },
  { key: 3.0, text: '3.0%', value: 0.03 },
];

const TradingSystemPage = ({authState, accessToken}) => {
  const [regimes, setRegimes] = React.useState([]);
  const [accountSize, setAccountSize] = React.useState(500000);
  const [risk, setRisk] = React.useState(RISK_CHOICES[3].value);
  const [loading, setLoading] = React.useState(true);
  const [shownTrade, setShownTrade] = React.useState(false);

  const today = new Date().toDateString();
  const newRegimes = regimes.filter(regime => {
    const regimeDate = new Date(regime._ts * 1000).toDateString();
    return today === regimeDate;
  }).sort((a,b)=> {if (a.symbol < b.symbol) {return -1;} if (a.symbol > b.symbol) {return 1;}return b.expiration - a.expiration;});
  const oldRegimes = regimes.filter(regime => {
    const regimeDate = new Date(regime._ts * 1000).toDateString();
    return today !== regimeDate;
  }).sort((a,b)=> parseInt(b.expiration) - parseInt(a.expiration) );
  
  React.useEffect(() => {
    if (accessToken) {
      fetch(
        THF_SERVER_URL+"regimes?access_token="+accessToken,
      ).then( (res) => res.json().then( (list) => {
        setRegimes(list);
      })).catch( (error) => {
        console.log(error);
      }).finally( () => {
        setLoading(false);
      });
    }
  }, [accessToken]);

  /* Loading, wait here. */
  if (!authState || authState.isPending || loading) return <LoadingProgressShown type='spinner' />

  return <div className="pageContent">
    <Header as="h1">Automated Trading System</Header>
    <p>Use at own risk. THF not responsible for losses from following system. If risk per trade is higher than 2%, consider modifying the trade.</p>
    <div>
      <div>Your Account Size: $<input type="number" value={accountSize} onChange={(e)=> setAccountSize(e.target.value)}/></div>
      <div>Max Risk per trade: <Dropdown placeholder="select risk tolerance" onChange={(e, {value})=> setRisk(value)} options={RISK_CHOICES} selection value={risk}/></div>
    </div>
    <p>Buy the following strategies at the listed entry price or better. Hold to expiration/final day, and close.</p>
    <ul className="regimes">
      <strong>Today - {today}</strong>
      <li className="regime">
        <div>
          <div>Symbol</div>
          <div>Regime</div>
          <div>Expiration</div>
          <div></div>
        </div>
      </li>
      {newRegimes.map(r=> displayRegime(r, risk, accountSize, shownTrade, setShownTrade))}
    </ul>
    <ul>
      <strong>History</strong>
      <li className="regime">
        <div>
          <div>Symbol</div>
          <div>Regime</div>
          <div>Expiration</div>
          <div></div>
        </div>
      </li>
      {oldRegimes.map(r=> displayRegime(r, risk, accountSize, shownTrade, setShownTrade))}
    </ul>
  </div>;
};

const displayRegime = (r, risk, accountSize, shownTrade, setShownTrade)=>{
  let tradeDetails = null;
  if (r.id === shownTrade) {
    tradeDetails = <div className="trade-details">
      {r.trades.map(trade => {
        if (trade.type === 'credit-spread') {
          const market_credit = trade.sell_contract.market_price - trade.buy_contract.market_price;
          if (market_credit < 0) {
            return <div key={trade.sell_contract.id}>
              <div className="trade-title">{trade.sell_contract.contract_type} credit spread</div>
              <div>Invalid pricing data. Model suggests Selling the {trade.sell_contract.strike} strike for the {trade.sell_contract.contract_type}s, and buying one further out to make a credit spread that defines your risk, but market prices may be illiquid. Use your best judgement</div>
            </div>;
          }
          const tradeRisk = Math.abs(trade.sell_contract.strike - trade.buy_contract.strike) * 100;
          return <div key={trade.sell_contract.id}>
            <div className="trade-title">{trade.sell_contract.contract_type} credit spread</div>
            <div>BUY strike: {trade.buy_contract.strike}</div>
            <div>Market Delta: {trade.buy_contract.market_delta.toFixed(3)}</div>
            <div>THF Delta: {trade.buy_contract.thf_delta.toFixed(3)}</div>
            <div>SELL strike: {trade.sell_contract.strike}</div>
            <div>Market Delta: {trade.sell_contract.market_delta.toFixed(3)}</div>
            <div>THF Delta: {trade.sell_contract.thf_delta.toFixed(3)}</div>
            <div>expected credit per contract: $ {(market_credit * 100).toFixed(2)}</div>
            <div>contracts: {((risk * accountSize) / tradeRisk).toFixed(0)}</div>
            <div>Pair Short THF Delta: {(trade.sell_contract.thf_delta - trade.buy_contract.thf_delta).toFixed(3)*-1}</div>
          </div>
        } else if (trade.signal === 'buy') {
          return <div key={trade.id}>
            <div className="trade-title">Long {trade.contract_type}</div>
            <div>strike: {trade.strike}</div>
            <div>price: $ {trade.market_price.toFixed(2)}</div>
            <div>contracts: {((risk * accountSize) / (trade.market_price * 100)).toFixed(0)}</div>
          </div>
        }
      })}
    </div>;
  }
  return <li className="regime" key={r.id}>
    <div>
      <div>{r.symbol}</div>
      <div>{r.regime_key}</div>
      <div>{r.expiration}</div>
      <div>
        <a style={{cursor: 'pointer'}} onClick={()=> {
          shownTrade === r.id ? setShownTrade(false) : setShownTrade(r.id);
          //if (trackingAnalytics.tradingSystem.viewTrade) trackActivity('tradeView', {group_id: r.symbol, item_id: r.regime_key, key_id: r.id});
        }}>{shownTrade === r.id ? 'hide' : 'see'} trades</a>
      </div>
    </div>
    {tradeDetails}
  </li>;
}

export default TradingSystemPage;
