import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import { THF_SERVER_URL } from '../util';
import LoadingProgressShown from '../Components/LoadingProgressShown';
import Top5TableList from '../Components/Top5TableList';

import '../css/todd.css';

var viewCalendarDate = null;
var calendarEmails = [];

const ToddPage = ({accessToken, permissions, updateUserPermissions}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);
  const [loadingEmail, setLoadingEmail] = React.useState(false);
  const [emailError, setEmailError] = React.useState(null);

  const [showCalendar, setShowCalendar] = React.useState(permissions.preferredViews.todd ? permissions.preferredViews.todd.calendar : false);
  const [showSymbolsTop5s, setShowSymbolsTop5s] = React.useState(permissions.preferredViews.todd ? permissions.preferredViews.todd.top5s : false);

  const [newEmail, setNewEmail] = React.useState("");
  const [emailDisplayed, setEmailDisplayed] = React.useState(null);

  const pageContentRef = React.useRef(null);

  const getEmail = React.useCallback( (email_id) => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken) {
      setLoadingEmail(true);
      fetch(THF_SERVER_URL+"todd-email/"+email_id+"?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => res.json() ).then( (email) => {
        setEmailDisplayed(email);
        setLoadingEmail(false);
        pageContentRef.current.scrollIntoView({behavior:'smooth', block:'start'});
      }).catch( (error) => {
        if (error.name === 'AbortError') return;
        setEmailError("Unable to locate that email. Please try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [accessToken]);

  const addEmail = React.useCallback( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken) {
      setLoading(true);
      fetch(THF_SERVER_URL+"todd-email?access_token="+accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({eml: newEmail}),
        signal: signal,
      }).then( (res) => res.json() ).then( (email) => {
        setNewEmail("");
        setLoading(false);
      }).catch( (error) => {
        if (error.name === 'AbortError') return;
        setEmailError("Unable to locate that email. Please try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [accessToken, setNewEmail]);

  const DisplaySelectedEmail = () => {
    return (<div>
      {loadingEmail ? (<div className="todd-emailLoading">
        <div>
          <p>{emailError ? emailError : "loading email..."}</p>
          <LoadingProgressShown type='spinner' />
        </div>
      </div>) : (emailDisplayed ? (
        <div className="todd-emailContent" align="center">
          <h4>Subject: {emailDisplayed.subject}</h4>
          <table className="toddEmail">
            <thead><tr key={"head"+emailDisplayed.id}>
              <th>Risk</th>
              <th>Symbols</th>
              <th>Contracts</th>
            </tr></thead>
            <tbody><tr key={"bod-"+emailDisplayed.id}>
              <td>{emailDisplayed.risk}</td>
              <td>{emailDisplayed.symbols.map( (s, i) => (<div key={"sym-"+i}><a href={"/symbol_tickers?symbol="+s}>{s}</a></div>))}</td>
              <td>{emailDisplayed.contracts.map( (c, i) => (<div key={"con-"+i}>{c}</div>))}</td>
            </tr></tbody>
          </table>
          <iframe className="toddEmailRead" srcDoc={emailDisplayed.html} title={emailDisplayed.subject}></iframe>

          {emailDisplayed && showSymbolsTop5s && <MentionedTop5s mentionedSymbols={emailDisplayed.symbols}/>}
        </div>
        ) : (<div className="todd-emailLoading">
          <h4>Select an email for viewing contents.</h4>
        </div>)
      )}
    </div>);
  };

  function renderEventContent(eventInfo) {
    return (<React.Fragment>
        <i>{eventInfo.event.title}</i>
      </React.Fragment>
    );
  };

  const MentionedTop5s = ({mentionedSymbols}) => {
    return (<div className="toddTop5List">
      <h4>Email mentions these symbols, here are their Today's top5 charts:</h4>
      {mentionedSymbols && mentionedSymbols.map( (symbol, i) => {
        return (<div key={"top5-"+i+symbol} style={{marginBottom:"8px"}}>
          <a href={"/symbol_tickers?symbol="+symbol}>{symbol}</a>
          <Top5TableList accessToken={accessToken} symbol={symbol} fetchDelay={i*3} showLoading={true}/>
        </div>);
      })}
    </div>);
  };
  
  const AdminToolBox = () => {
    return (<div>
      <textarea value={newEmail} onChange={(e) => setNewEmail(e.target.value)}></textarea>
      <button onClick={addEmail}>Add Email</button>
    </div>);
  };

  const EmailsAvailable = () => {
    const [rawEmails, setRawEmails] = React.useState([]);
    const [filteredEmails, setFilteredEmails] = React.useState([]);
    const [searchString, setSearchString] = React.useState("");

    var todayDate = new Date().toISOString().replace(/T.*$/, '');
    var firstDate = undefined;
    function mapCalendarEmailEvents(emailList) {
      let emailEvents = emailList.map( (email) => {
        const emailDate = (email.date ? new Date(email.date * 1000) : new Date());
        return {
          id: email.id,
          title: email.subject || 'undefined',
          start: emailDate.toISOString().replace(/T.*$/, ''), // YYYY-MM-DD
        };
      });
      calendarEmails = emailEvents;
      setNewestEmailDate();
    };

    function setNewestEmailDate() {
      if (calendarEmails.length > 0) {
        let newest = calendarEmails[0];
        firstDate = calendarEmails[calendarEmails.length-1].start
        viewCalendarDate = newest.start;
      };
      //console.log("set newest email for view", {calendarEmails, viewCalendarDate});
    };

    function setCalendarToEMailDate(emailId) {
      let emailMatch = calendarEmails.find((email) => emailId === email.id);
      if (emailMatch) viewCalendarDate = emailMatch.start;
    };

    React.useEffect( () => {
      const controller = new AbortController();
      const signal = controller.signal;
      if (accessToken) {
        fetch(
          THF_SERVER_URL+"todd-emails?access_token="+accessToken,
          {signal: signal}
        ).then( (res) => res.json().then( (emailList) => {
          emailList.sort((a,b) => new Date(b.date) - new Date(a.date) );
          setRawEmails(emailList);
          setFilteredEmails(rawEmails);
          mapCalendarEmailEvents(rawEmails);
        })).catch( (error) => {
          if (error.name === 'AbortError') return;
          setDisplayError("Unable to communicate with server. Please try again later.");
        }).finally( () => {
        });
      };
      return () => controller.abort();
    }, [accessToken]);

    React.useEffect( () => {
      if (searchString.length === 0) {
        setFilteredEmails(rawEmails);
        mapCalendarEmailEvents(rawEmails);
      } else {
        let filtered = rawEmails.filter(email => email.symbols.includes(searchString));
        setFilteredEmails(filtered);
        mapCalendarEmailEvents(filtered);
      };
      //console.log("searching...", {searchString, viewCalendarDate, rawEmails});
    }, [searchString, rawEmails]);

    React.useEffect( () => {
      setNewestEmailDate();
    }, [calendarEmails]);

    const EmailList = () => {
      return (<div>
        {filteredEmails && (<ul>
          {filteredEmails.map(email => {
            return (<li key={email.id}>
              <button className='link-lookalike-button' onClick={() => getEmail(email.id)}>{email.subject}</button>
            </li>);
          })}
        </ul>)}
      </div>);
    };

    return (<div>
      <div style={{display:'block', width:'fit-content'}} align='left'>
        <ul className="plain-left-list" style={{width:'300px',marginTop:'0px'}}>
          <li><Checkbox label="Display Calendar" toggle onChange={() => {
            var updatedPreferredViews = permissions.preferredViews;
            if (!updatedPreferredViews.todd) updatedPreferredViews.todd = {};
            updatedPreferredViews.todd.calendar = !showCalendar;
            updateUserPermissions('preferredViews', updatedPreferredViews);
            setShowCalendar(!showCalendar);
          }} checked={showCalendar}/></li>
        </ul>
      </div>

      <div>
        <label>Email filter: </label>
        <input placeholder="by symbol" type="text" onChange={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setSearchString(event.target.value.toUpperCase());
        }}/>
        <label> found: {filteredEmails.length}</label>
      </div>
      
      <div style={{height:"1000px"}}>
        {calendarEmails.length > 0 && (showCalendar && viewCalendarDate) ? (
          <FullCalendar
            plugins={[dayGridPlugin]}
            headerToolbar={{
              left: 'title',
              center: '',
              right: 'prev,next today',
            }}
            initialView='dayGridMonth'
            events={calendarEmails}
            weekends={true}
            dayMaxEvents={true}
            eventClick={(target) => getEmail(target.event.id)}
            eventContent={renderEventContent}
            initialDate={viewCalendarDate}
            validRange={{
              start: firstDate,
              end: todayDate
            }}
          />
        ):( <EmailList /> )}
      </div>
    </div>);
  };

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  return (<div className="pageContent" ref={pageContentRef}>
    <h2>Daily Overview Due Diligence</h2>
    <div style={{display:'block', width:'fit-content'}} align='left'>
      <ul className="plain-left-list" style={{width:'300px',marginTop:'0px'}}>
        <li><Checkbox label="Symbol Top5 charts" toggle onChange={() => {
          var updatedPreferredViews = permissions.preferredViews;
          if (!updatedPreferredViews.todd) updatedPreferredViews.todd = {};
          updatedPreferredViews.todd.top5s = !showSymbolsTop5s;
          updateUserPermissions('preferredViews', updatedPreferredViews);
          setShowSymbolsTop5s(!showSymbolsTop5s);
        }} checked={showSymbolsTop5s}/></li>
      </ul>
    </div>

    <DisplaySelectedEmail />

    <EmailsAvailable />

    {permissions && permissions.admin && <AdminToolBox />}
  </div>);
};
export default ToddPage;
