import React from 'react';
import ReactDOM from "react-dom/client";
import 'semantic-ui-css/semantic.min.css';
import './polyfills';
import App from './App';
import './css/index.css';
import registerServiceWorker from './registerServiceWorker';

/* eslint-disable react/jsx-filename-extension */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render( <App /> );
/*  <React.StrictMode>
      <App />
    </React.StrictMode> 
  );
*/

registerServiceWorker();
