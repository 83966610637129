import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { THF_SERVER_URL } from '../util';
import { LocalStoreContext } from '../App';
import isMockingData, { mockResPermissions } from '../MockService/inPlace';

// if still able to receive user information from authentication service, but not able to reach THF services.
export const ERROR_WHEN_NO_PERMISSIONS = false;

export default function usePermissions({setDisplayError}) {
  const { authState } = useOktaAuth();
  const [permissions, setPermissions] = React.useState(null);
  const [accessToken, setAccessToken] = React.useState(null);

  const localStoreContext = React.useContext(LocalStoreContext);
  
  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (authState && authState.accessToken) {
      setAccessToken(authState.accessToken.accessToken);
      if (isMockingData) {
        setPermissions(mockResPermissions);
        return;
      };
      fetch(THF_SERVER_URL+"user_permissions?access_token="+authState.accessToken.accessToken,
        {signal: signal}
      ).then( (res) => {
        if (res.ok) {
          return res.json();
        } else {
          console.error("Unable to fetch user 'permissions'", res, authState.accessToken);
          throw new Error("Unable to fetch user permissions.");
        };
      }).then( (details) => {
        setPermissions(details);
        localStoreContext.changeTheme(details.visual_mode);
        //console.debug("(App 'root') setting permissions.", details);
      }).catch( (error) => {
        if (error.name === 'AbortError') return;
        if (error.name === 'SyntaxError') {
          console.error("Error: (PermissionsHook) response was not 'ok'")
          return;
        };
        console.error("Error: (PermissionsHook) fetch", error);
        if (ERROR_WHEN_NO_PERMISSIONS) setDisplayError("Unable to communicate with server. Please try again later.");
      }).finally( () => {
      });
    } else {
      setPermissions(null);
    };
    return () => controller.abort();
  }, [authState, setAccessToken]);

  const updateUserPermissions = React.useCallback((permissionName, permissionValue, overrideUID, cb) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const data = {
      okta_uid: overrideUID || permissions.okta_uid,
      permissionName,
      permissionValue,
      signal: signal,
    };
    fetch( THF_SERVER_URL + "user_permissions?access_token="+accessToken, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then(response => {
      if (response.ok) {
        response.json().then(details => {
          if (cb) {
            cb(details);
          } else {
            setPermissions(details);
          };
        });
      };
    }).catch( (error) => {
      if (error.name === 'AbortError') return;
      if (error.name === 'SyntaxError') {
        console.error("Error: (PermissionsHook) response was not 'ok' :", loc)
        return;
      };
    }).finally( () => {
    });
    return () => controller.abort();
  }, [permissions, accessToken]);

  return { accessToken, permissions, updateUserPermissions };
};
