import React from 'react';


export const UserName = ({userInfo, message}) => {
  if (userInfo) {
    let impregnateMessage = message.split("%user%");
    let names = userInfo.name.split(' ');
    for (let i = 0; i < names.length; i++) {
      names[i] = names[i][0].toUpperCase() + names[i].substring(1);
    };
    let displayName = names.join(" ");
    let displayText = impregnateMessage[0]+displayName+impregnateMessage[1];
    
    return (<React.Fragment>
      <p>{displayText}</p>
    </React.Fragment>);
  };

  return (<React.Fragment/>);
};
