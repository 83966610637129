import React from 'react';
import { Checkbox, Input } from 'semantic-ui-react';
import { LineChart, Line, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { THF_SERVER_URL, tooltipCollector, randomColor, getTimeStringDateFormat } from '../util';

import LoadingProgressShown from '../Components/LoadingProgressShown';
import { CustomizedTick, CustomTooltip } from '../Components/CustomizedTick';
import FloatClickToCopy from '../Components/FloatClickToCopy';

const Hurst = ({accessToken, isAdmin}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);

  const [showHurstTable, setShowHurstTable] = React.useState(false);
  const [hursts, setHursts] = React.useState({});
  const [colors, setColors] = React.useState({});
  const [hurstLines, setHurstLines] = React.useState([]);
  const [arbitraryThreshold, setArbitraryThreshold] = React.useState("");
  const otherCollector = tooltipCollector();

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken) {
      setLoading(true);
      fetch(THF_SERVER_URL+"historical_hurst_exponents?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => res.json().then( (hurstsBySymbol) => {
        setHursts(hurstsBySymbol);
        const keys = Object.keys(hurstsBySymbol);
        let temp = {};
        let colorMapping = {};
        for (let i = 0; i < keys.length; i+=1) {
          let hurstSymbol = keys[i];
          hurstsBySymbol[hurstSymbol].forEach(obj => {
            temp[obj.calculatedAt] = temp[obj.calculatedAt] || {};
            temp[obj.calculatedAt][hurstSymbol] = obj.hurst_exponent;
          });
          colorMapping[hurstSymbol] = randomColor();
        };
        setColors(colorMapping);
        let formattedData = Object.keys(temp).map( dateStr => {
          return {
            date: dateStr,
            renderer: getTimeStringDateFormat({stringDate:dateStr, shorthand:true}),
            "model stable": 0.10,
            ...temp[dateStr]
          };
        }).sort((a,b) => parseInt(a.date) - parseInt(b.date));
        setHurstLines(formattedData);
        setLoading(false);
      })).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (Hurst) component", error);
        setDisplayError("Error while loading hurst. Try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [accessToken])

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  const HurstTableView = () => {
    const mostRecentOnly = Object.keys(hursts).map( (symbol) => {
      return hursts[symbol].sort((a,b) => b.date - a.date)[0];
    });
    mostRecentOnly.sort((a,b) => a.hurst_exponent - b.hurst_exponent);

    return (<table className="even-cells logs-hurstTable no-scroll">
      <thead><tr>
        <th style={{width:"120px"}}>symbol</th>
        <th>calculated at</th>
        <th>most recent hurst value</th>
      </tr></thead>
      <tbody>
        {mostRecentOnly.map( (symbolData) => {
          return (<tr key={symbolData.id}>
            <td style={{width:"120px"}}>{symbolData.symbol}</td>
            <td>{new Date(symbolData._ts * 1000).toLocaleString()}</td>
            <td>{<FloatClickToCopy value={symbolData.hurst_exponent} decimalPlaces={16}/>}</td>
          </tr>);
        })}
      </tbody>
    </table>);
  };

  return (<div>
    <h4 style={{marginTop:"24px"}}>hurst exponent</h4>
    {hurstLines.length > 0 && (<div style={{height:'450px'}}>
      <div style={{width:'100%', height:'400px'}}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={800} height={400} data={hurstLines}>
            <Legend />
            <Tooltip content={<CustomTooltip THE_COLLECTOR={otherCollector} />} />
            <YAxis tick={<CustomizedTick THE_COLLECTOR={otherCollector} />} />
            <XAxis dataKey="renderer" />
            {Object.keys(hursts).map(symbol => <Line key={symbol} type="monotone" dataKey={symbol} stroke={colors[symbol]} connectNulls />)}
            {arbitraryThreshold !== "" && 
              <ReferenceLine y={arbitraryThreshold} label="AT" stroke="red" strokeDasharray="3 3" />
            }
          </LineChart>
        </ResponsiveContainer>
        <Input placeholder='Arbitrary Threshold' onChange={ (event, data) => {
          let newFloat = parseFloat(data.value);
          if (isNaN(newFloat)) setArbitraryThreshold("");
          else setArbitraryThreshold(newFloat);
        }}/>
      </div>
    </div>)}

    {isAdmin && <div align='center'>
      <div style={{display:'block',width:'fit-content',width:'fit-content',margin:'24px'}} align='left'>
        <Checkbox label="Toggle hurst table display" toggle onChange={(e, data) => {
					setShowHurstTable(data.checked);
				}} checked={showHurstTable}/>
      </div>
    </div>}
    {isAdmin && showHurstTable && <div align='center'> <HurstTableView/> </div>}
  </div>);
};

export default Hurst;
