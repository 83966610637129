import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select } from 'semantic-ui-react'
import { LineChart, Line, Legend, XAxis, YAxis, Tooltip } from 'recharts';

import { THF_SERVER_URL, DEFAULT_SYMBOL } from '../util';
import useManagedSymbols from '../Hooks/ManagedSymbols';

import LoadingProgressShown from '../Components/LoadingProgressShown';
import HistoricalNormalizedProfitChart from '../Components/HistoricalNormalizedProfitChart';
import { SymbolFavoriteList } from '../Hooks/ManagedSymbols';

const HistoryPage = ({accessToken, permissions, updateUserPermissions}) => {
  const [loading, setLoading] = React.useState(true);
  const [displayError, setDisplayError] = React.useState(null);
  const [results, setResults] = React.useState(null);

  const [right, setRight] = React.useState("put");
  const [strike, setStrike] = React.useState("400");
  const [date, setDate] = React.useState("20220819");

  const { managedSymbols } = useManagedSymbols({accessToken, setLoading, setDisplayError}, true);

  const [search, setSearch] = useSearchParams();
  function grabUrlSymbol() {
    let sym = search.get('symbol');
    if (!sym && managedSymbols[0]) {
      sym = managedSymbols[0].key;
    };
    if (!sym) sym = DEFAULT_SYMBOL;
    return sym;
  };
  
  const [displaySymbol, setDisplaySymbol] = React.useState(grabUrlSymbol());
  const setHistorySymbol = (value) => {
		setSearch({symbol: value});
		setDisplaySymbol(value);
	};

  const getResults = React.useCallback( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken && displaySymbol !== "" && strike !== "" && date !== "" && right !== "") {
      setLoading(true);
      fetch(THF_SERVER_URL+"option-pricings-history/"+displaySymbol+"/"+strike+"/"+date+"/"+right+"?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => res.json().then( (allData) => {
        setResults(allData.map( (obj) => {
          obj.time = new Date(obj.calculatedAt * 1000);
          return obj;
        }));
        setLoading(false);
      })).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (HistoryPage)", error);
        setDisplayError("Unable to communicate with server. Please try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [accessToken, displaySymbol, strike, date, right]);

  React.useEffect( () => {
    if (!results) getResults();
  }, [getResults, results]);

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  return (<div className="pageContent">
    <div>
      <h3>Symbol Historical Performance Summary</h3>
      <div>
        <SymbolFavoriteList accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} 
          selectedItem={displaySymbol} onSelection={(value) => {
            setResults(null);
            setHistorySymbol(value);
          }
        }/>
      </div>
      <HistoricalNormalizedProfitChart accessToken={accessToken} symbol={displaySymbol}/>
    </div>

    <div>
      <h3>Specific Contract History Explorer</h3>
      <div style={{marginBottom: 20}}>
        <div>
          <SymbolFavoriteList accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} 
            selectedItem={displaySymbol} onSelection={(value) => {
              setResults(null);
              setHistorySymbol(value);
            }
          }/>
          <Select
            placeholder='Select your right'
            options={[{key: 'call', value: 'call', text: 'Call'},{key: 'put', value: 'put', text: 'Put'}]}
            value={right}
            onChange={(e, { value }) => {
              setSearch({right: value});
              setRight(value);
              setResults(null);
            }}
          />
        </div>
        <div>strike: <input type="string" placeholder="strike" value={strike} onChange={(e) => setStrike(e.target.value)} /></div>
        <div>date (YYYYMMDD): <input type="string" placeholder="YYYYMMDD" value={date} onChange={(e) => setDate(e.target.value)} /></div>
        <button onClick={getResults}>go</button>
      </div>

      {!results && <p>Hit "go" or put in a different contract (not all contracts have valid historical data)</p>}
      {results && <h2>Comparison of market prices and THF calculated prices for selected option</h2>}
      {results && results.length > 0 && <LineChart width={800} height={400} data={results}>
        <Legend />
        <Tooltip />
        <YAxis />
        <XAxis dataKey="time" />
        <Line type="monotone" dataKey="thf_price" stroke="#00ff00" dot={null} />
        <Line type="monotone" dataKey="market_price" stroke="#ff0000" dot={null}/>
      </LineChart>}
    </div>
  </div>);
};

export default HistoryPage;
