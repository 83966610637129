import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';

import { DEFAULT_SYMBOL } from '../util';
import useManagedSymbols from '../Hooks/ManagedSymbols';
import { SymbolFavoriteList } from '../Hooks/ManagedSymbols';

import LoadingProgressShown from '../Components/LoadingProgressShown';
import Heatmap from '../Components/Heatmap';
import BugReport from '../Components/BugReport';
import HistoricalNormalizedProfitChart from '../Components/HistoricalNormalizedProfitChart';
import ProbabilityZonesChart from '../Components/ProbabilityZonesChart'
import SymbolComments from '../Components/SymbolComments';
import DealerGammaChart from '../Components/DealerGammaChart';

import LogsPage from './LogsPage'

const SymbolTickers = ({userInfo, accessToken, permissions, updateUserPermissions, trackActivity}) => {
  const [loading, setLoading] = React.useState(true);
  const [displayError, setDisplayError] = React.useState(null);

  const [showSymbolComments, setShowSymbolComments] = React.useState(permissions.preferredViews.symbolOverview.comments);
  const [showProbabilityZones, setShowProbabilityZones] = React.useState(permissions.preferredViews.symbolOverview.probabilityZones);
  const [showHeatmap, setShowHeatmap] = React.useState(permissions.preferredViews.symbolOverview.heatmap);
  const [showHistory, setShowHistory] = React.useState(permissions.preferredViews.symbolOverview.history);
  const [showLogs, setShowLogs] = React.useState(permissions.preferredViews.symbolOverview.logs);
  const [showDealerGamma, setShowDealerGamma] = React.useState(permissions.preferredViews.symbolOverview.dealerGamma);

  const { managedSymbols, getSymbolData } = useManagedSymbols({accessToken, setLoading, setDisplayError}, true);
  const setSummarySymbol = (key, data) => {
		setSearch({symbol: key});
		setDisplaySymbolKey(key);
    setSelectedSymbolObj(data);
	};

  const [search, setSearch] = useSearchParams();
  function grabUrlSymbol() {
    let sym = search.get('symbol');
    if (!sym && managedSymbols[0]) {
      sym = managedSymbols[0].key;
    };
    if (!sym) sym = DEFAULT_SYMBOL;
    return sym;
  };
  const [displaySymbolKey, setDisplaySymbolKey] = React.useState(grabUrlSymbol());

  function initUrlSymbolData() {
    return getSymbolData(grabUrlSymbol());
  };
  const [selectedSymbolObj, setSelectedSymbolObj] = React.useState(initUrlSymbolData());
 
  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' /> }
  </div>);

  const ViewToggles = () => {
    return (<div className="overviewToggles" align='left'>
      <ul className="plain-left-list" style={{width:'300px'}}>
        <li><Checkbox label="Symbol Comments" toggle onChange={() => {
            var updatedPreferredViews = permissions.preferredViews;
            updatedPreferredViews.symbolOverview.comments = !showSymbolComments;
            updateUserPermissions('preferredViews', updatedPreferredViews);
            setShowSymbolComments(!showSymbolComments);
          }} checked={showSymbolComments}/>
        </li>
        <li><Checkbox label="Probability Zones" toggle onChange={() => {
            var updatedPreferredViews = permissions.preferredViews;
            updatedPreferredViews.symbolOverview.probabilityZones = !showProbabilityZones;
            updateUserPermissions('preferredViews', updatedPreferredViews);
            setShowProbabilityZones(!showProbabilityZones);
          }} checked={showProbabilityZones}/>
        </li>
        <li><Checkbox label="Heatmap" toggle onChange={() => {
            var updatedPreferredViews = permissions.preferredViews;
            updatedPreferredViews.symbolOverview.heatmap = !showHeatmap;
            updateUserPermissions('preferredViews', updatedPreferredViews);
            setShowHeatmap(!showHeatmap);
          }} checked={showHeatmap}/>
        </li>
        <li><Checkbox label="Dealer Gamma" toggle onChange={() => {
            var updatedPreferredViews = permissions.preferredViews;
            updatedPreferredViews.symbolOverview.dealerGamma = !showDealerGamma;
            updateUserPermissions('preferredViews', updatedPreferredViews);
            setShowDealerGamma(!showDealerGamma);
          }} checked={showDealerGamma}/>
        </li>
        <li><Checkbox label="History" toggle onChange={() => {
            var updatedPreferredViews = permissions.preferredViews;
            updatedPreferredViews.symbolOverview.history = !showHistory;
            updateUserPermissions('preferredViews', updatedPreferredViews);
            setShowHistory(!showHistory);
          }} checked={showHistory}/>
        </li>
        <li><Checkbox label="Managed Symbol Health" toggle onChange={() => {
            var updatedPreferredViews = permissions.preferredViews;
            updatedPreferredViews.symbolOverview.logs = !showLogs;
            updateUserPermissions('preferredViews', updatedPreferredViews);
            setShowLogs(!showLogs);
          }} checked={showLogs}/>
        </li>
      </ul>
    </div>);
  };

  const MovingAvgExpectation = () => {
    React.useEffect(() => {
      if (displaySymbolKey && !selectedSymbolObj) setSelectedSymbolObj(initUrlSymbolData());
    }, [displaySymbolKey, selectedSymbolObj]);

    return (<React.Fragment>
      {(selectedSymbolObj && selectedSymbolObj.one_day_expected_move) ? (
        <div>1-day expected move: $ {selectedSymbolObj.one_day_expected_move.toFixed(2)}</div>
      ) : (
        <React.Fragment/>
      )}
    </React.Fragment>);
  };

  return (<div className="pageContent">
    <div className="tickerOverviewSection">
      <div align='center'>
        <h1>Welcome back {userInfo ? userInfo.name : "Null"}</h1>
        <BugReport />
        <div className="tickerOverviewHeaderInteracts">
          <ViewToggles/>
          <div style={{width:'max-content', marginLeft:'auto'}} align='right'>
            <label style={{paddingRight:'110px'}}>Aggregate Data for: </label>
            <SymbolFavoriteList accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} 
              selectedItem={displaySymbolKey} onSelection={(key, data) => setSummarySymbol(key, data)} 
            />
            <MovingAvgExpectation />
          </div>
        </div>
      </div>
      <hr className="title"/>
    </div>

    {showSymbolComments && <div className="tickerOverviewSection">
      <h2 style={{marginBottom:'0px'}}>Symbol Comments</h2>
      <SymbolComments accessToken={accessToken} userInfo={userInfo} symbol={displaySymbolKey}/>
      <hr className="chapter"/>
    </div>}

    {showProbabilityZones && <div className="tickerOverviewSection">
      <h2 style={{marginBottom:'0px'}}>Probability Zones</h2>
      <ProbabilityZonesChart accessToken={accessToken} symbol={displaySymbolKey} permissions={permissions} updateUserPermissions={updateUserPermissions}/>
      <hr className="chapter"/>
    </div>}

    {showHeatmap && <div className="tickerOverviewSection">
      <h2>Contract Heatmap</h2>
      <Heatmap accessToken={accessToken} symbol={displaySymbolKey} permissions={permissions} updateUserPermissions={updateUserPermissions} trackActivity={trackActivity}/>
      <hr className="chapter"/>
    </div>}

    {showDealerGamma && <div className="tickerOverviewSection">
      <h2>Dealer Gamma</h2>
      <DealerGammaChart accessToken={accessToken} symbol={displaySymbolKey} />
      <hr className="chapter"/>
    </div>}

    {showHistory && <div className="tickerOverviewSection">
      <h2>Historical Normalized Profit</h2>
      <HistoricalNormalizedProfitChart accessToken={accessToken} symbol={displaySymbolKey} displayChartTitle={false} permissions={permissions} updateUserPermissions={updateUserPermissions}/>
      <hr className="chapter"/>
    </div>}
    
    {showLogs && <div className="tickerOverviewSection">
      <h2>Managed Symbol Health</h2>
      <LogsPage accessToken={accessToken} permissions={permissions} symbol={displaySymbolKey} />
      <hr className="chapter"/>
    </div>}

  </div>);
};
export default SymbolTickers;
