import config from '../config';

const isMockingData = (process.env.NODE_ENV === "development" && config.mockFetching === true);
export default isMockingData;

export const mockResUserInfo = isMockingData ? import('./MockData/userinfo').userinfoAvg : {};
export const mockResPermissions = isMockingData ? import('./MockData/permissions').permissionsAdmin : {};

export const mockManagedSymbols = isMockingData ? import('./MockData/managedSymbols').managedSymbolsAvg : [];

export const mockTop10Buys = isMockingData ? import('./MockData/top10lists').top10BuysAvg : [];
export const mockTop10Sells = isMockingData ? import('./MockData/top10lists').top10SellsAvg : [];

export const mockSymbolComments = isMockingData ? import('./MockData/comments').commentsAvg("SPY") : [];

export const mockHeatmapData = isMockingData ? import('./MockData/heatmap').heatmapAvg("SPY") : {};

export const mockToddEmails = isMockingData ? import('./MockData/todd').toddEmailsAvg : [];

export const mockDealerGamma = isMockingData ? import('./MockData/chartData').dealerGammaAvg : {};
