import React from 'react';
import { Card, Image } from 'semantic-ui-react'
import { MentionsInput, Mention } from 'react-mentions'

import useSymbolComments from '../Hooks/SymbolComments';
import LoadingProgressShown from '../Components/LoadingProgressShown';
import HoverToolTip from './HoverToolTip';

const NewComment = ({text, setText, show, setShow, save}) => {
  if (show) {
    return (<form key="new-comment-form" className="newComment" 
      onSubmit={(e) => {
        save();
        e.preventDefault();
        e.target.reset();
    }}>
      <div style={{display:'flex', justifyContent:'center'}}>
        <textarea key="new-comment-textarea" type="text" rows="3" value={text}
          id="new-comment-textarea" autoFocus={true}
          style={{resize:'height', overflow:'auto', width:'60%'}}
          onChange={ e => setText(e.target.value)}
        />
        <div align='left' style={{width:'auto', paddingLeft:'32px'}}>
          <button onClick={ () => {
            setText("");
            setShow(false);
          }}>Discard comment</button>
          <button className="btn" type="submit">Save comment</button>
        </div>
      </div>
    </form>);
  } else {
    return (<div>
      <button onClick={() => setShow(true)}>Add comment</button>
    </div>);
  };
};

const SymbolComments = ({accessToken, userInfo, symbol, showAll=false, showOnlyRead=false}) => {
  const [loading, setLoading] = React.useState(0);
  const [displayError, setDisplayError] = React.useState(null);

  const [showNewComment, setShowNewComment] = React.useState(false);
  const [newCommentText, setNewCommentText] = React.useState("");
  const [displayedComments, setDisplayedComments] = React.useState([]);

  const { symbolComments, readComments, readingComment,
    filterByReadComments, handleCommentSave, handleMarkCommentAsRead, hasReadComment
  } = useSymbolComments({accessToken, userInfo, symbol, setLoading, setDisplayError});

  React.useEffect( () => {
    if (showAll) {
      setDisplayedComments(symbolComments);
    } else {
      setDisplayedComments(filterByReadComments(showOnlyRead));
    };
  }, [accessToken, symbol, symbolComments, readComments]);

  /* Loading/Error, wait here. */
  if (loading > 0 || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type="text" text="Retrieving..."/>}
  </div>);

  const MarkAsRead = ({comment}) => {
    return (<div className="commentReadButton" align="right">
      {readingComment === comment.id ? (
        <LoadingProgressShown type="spinner"/>
      ) : ( hasReadComment(comment) ? (
        <React.Fragment/>
      ) : (<HoverToolTip label={<button onClick={()=> handleMarkCommentAsRead({msgId: comment.id})}>
          <Image className="commentReadButton" src="images/msg_read.png"/>
        </button>} contents="Mark as read"/>
      ))}
    </div>);
  };

  const CommentsDisplay = () => {
    if (!symbol || symbol === '') {
      return (<h4>Select a symbol to view it's comments.</h4>);
    };

    if (symbolComments.length > 0) {
      let scrollComments = {marginBottom:'32px', display:'flex', justifyContent:'center'};
      if (displayedComments.length >= 4) {
        scrollComments = {...scrollComments, height: '500px', overflowY: 'scroll'};
      };

      return (<div>
        <h4>Comments on this symbol:</h4>
        <div style={scrollComments}>
          {displayedComments.map( c => {
            return (<div key={c.id} style={{marginBottom:'32px'}} >
              <Card>
                <Card.Content>
                  <Card.Header>{new Date(c._ts * 1000).toDateString()}</Card.Header>
                  <Card.Description>{c.text}</Card.Description>
                  <MarkAsRead comment={c} />
                </Card.Content>

                <Card.Content extra>By: {c.commenter}</Card.Content>
              </Card>
            </div>);
          })}
        </div>
      </div>);
    } else {
      return (<div style={{marginBottom:'32px'}}>
        <h4>No comments for {symbol}</h4>
      </div>);
    };
  };

  return (<div>
    <CommentsDisplay />

    {(symbol && symbol !== '') && 
      <NewComment text={newCommentText} setText={(val) => setNewCommentText(val)}
        show={showNewComment} setShow={(val) => setShowNewComment(val)} 
        save={() => {
          handleCommentSave({text:newCommentText});
          setNewCommentText("");
          setShowNewComment(false);
        }}
      />
    }
   </div>);
};

export default SymbolComments;
