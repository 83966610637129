import React from 'react';
import Analytics from 'analytics'

import config from '../config';

//import googleAnalyticsPlugin from '@analytics/google-analytics'
import postHogAnalyticsPlugin from './PostHogAnalytics';

export const trackingAnalytics = {
  enabled: (window.location.origin.includes('localhost') ? false : true),
  heatmap: {
    hover: true,
    selection: true,
  },
  tradingSystem: {
    viewTrade: true,
  },
};

const analytics = Analytics({
  app: 'thf-portal',
  plugins: [
    /*
    googleAnalyticsPlugin({
      debug: false,
      measurementIds: [config.analytics.googleID],
      anonymize_ip: false,
    }),
    */
    postHogAnalyticsPlugin({
      debug: false,
      api_host: 'https://posthog.thf.ai',
      measurementID: config.analytics.postHog,
    }),

  ],
});

function useAnalytics({permissions, userInfo}) {
  React.useEffect(() => {
    if (!trackingAnalytics.enabled) return;
    if (userInfo && permissions) {
      analytics.identify('u-'+permissions.okta_uid, {
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        email_verified: userInfo.email_verified,
        subscribed: userInfo.subscribed,
      });
    } else {
      analytics.reset();
    };
    analytics.page();
  }, [userInfo, permissions]);

  function trackActivity(eventTag='', data={}) {
    if (!trackingAnalytics.enabled) return;
    if (eventTag && eventTag !== '' && data && Object.keys(data).length > 0) {
      analytics.track(eventTag, data);
    };
  };

  function debugAnalytics() {
    if (!trackingAnalytics.enabled) {
      console.log("Analytics is currently disabled.");
      return;
    };
    const current = analytics.getState();
    const { storage } = analytics
    console.log("Analytic debugger:", {analytics, current, storage});
  };

  analytics.ready((payload) => {
    //console.log('Analytics Ready.', payload);
  });

  return { analytics, trackActivity, debugAnalytics };
};
export default useAnalytics;
