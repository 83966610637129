import React from 'react';

import { THF_SERVER_URL } from '../util';
import UsersTable from '../Components/UsersTable';
import Metrics from '../Components/Metrics';
import BugReport from '../Components/BugReport';

const ADMIN_KEYS = ['comment_ttl'];

const Admin = ({permissions, accessToken, updateUserPermissions}) => {
  const [settings, setSettings] = React.useState({});
  const [commentDays, setCommentDays] = React.useState(3);

  React.useEffect( () => {
    if (accessToken && Object.keys(settings).length === 0) {
      ADMIN_KEYS.forEach((key) => {
        fetch(THF_SERVER_URL+"admin_setting/"+key+"?access_token="+accessToken)
        .then( (res) => {
          return res.json();
        }).then((j) => {
          const temp = {...settings};
          temp[key] = j.value;
          setSettings(temp);
        }).catch( (error) => {
        }).finally( () => {
        });
      })
    };
  }, [accessToken, settings]);

  const updateComment = React.useCallback( () => {
    fetch(
      THF_SERVER_URL+"admin_setting/comment_ttl?access_token="+accessToken,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({value: commentDays * 24* 60 * 60})
      }
    ).then(r=> r.json()).then(j => {
      const temp = {...settings};
      temp[j.key] = j.value;
      setSettings(temp);
    })
  }, [commentDays, settings, accessToken])

  if (!permissions.admin) {
    return null;
  };

  const pStyle = {margin: 0};

  return (<div className="pageContent">
    <p style={pStyle}>Welcome to the Global Admin Panel</p>
    <p style={pStyle}>Sales manager: Robert Douglas</p>
    <BugReport />
    <ul style={{textAlign: 'left'}}>
      Raw admin setting values:
      {ADMIN_KEYS.map(k => {
        return <li key={k}>{k}: {settings[k]}</li>
      })}
    </ul>
    <div>
      Set comment days until hidden:
      <input type="number" value={commentDays} onChange={(e) => setCommentDays(e.target.value)}/>
      <button onClick={updateComment}>Save</button>
    </div>
    <hr className="title"/>

    <UsersTable accessToken={accessToken} updateUserPermissions={updateUserPermissions} />
    <hr className="chapter"/>
    
    <Metrics accessToken={accessToken}/>
  </div>);
};

export default Admin;
