import React from 'react';
import  { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'

import config from '../config';
import { THF_SERVER_URL } from '../util';
import LoadingProgressShown from './LoadingProgressShown';

import { CardsList } from './SquareCardManager';

function storeCard(token, customerId, verificationToken, givenName, familyName, cb) {
  const bodyParameters = {
    locationId: config.payments.locationId,
    sourceId: token,
    customerId,
    givenName,
    familyName
  };
  if (verificationToken !== undefined) {
    bodyParameters.verificationToken = verificationToken;
  };
  fetch(THF_SERVER_URL+'/create_card', 
    {// request options
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyParameters),
    }
  ).then( (res) => {
    if (res.ok) {
      res.json().then( (data) => {
        cb(data);
      });
    } else {
      res.text().then( (errorBody) => {
        throw new Error(errorBody);
      });
    };
  }).catch( (error) => {
    if (error.name === 'AbortError') return;
    //console.error("Error: (ConfigurePayments)", error);
  }).finally( () => {
  });
};

const SUBSCRIPTION_PLAN_ID = config.payments.plans[0]; // sandbox only

function buySubscription(accessToken, customerId, cardId, userId, cb) {
  const bodyParameters = {
    access_token: accessToken,
    locationId: config.payments.locationId,
    planId: SUBSCRIPTION_PLAN_ID,
    customerId,
    cardId,
    userId
  };
  fetch(THF_SERVER_URL+'/create_subscription', 
    {// request options
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyParameters),
    }
  ).then(response => {
    if (response.ok) {
      response.json().then(cb);
    };
  }).catch( (error) => {
   if (error.name === 'AbortError') return;
    //console.error("Error: (ConfigurePayments)", error);
  }).finally( () => {
  });
};

const formReducer = (state, event) => {
  return {...state,
    [event.name]: event.value
  };
};

// 1. create customer in square for user if not exists already
// 1b. show existing cards
// 2. get card info, create card in square
// 3. user picks card, subscribes to plan, `CreateSubscription` call to square
const ConfigurePayments = ({userInfo, accessToken, permissions}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);
  const [cardReady, setCardReady] = React.useState(false);

  const [addingCard, setAddingCard] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [squareCustomer, setSquareCustomer] = React.useState(null);
  const [squareCards, setSquareCards] = React.useState(null);
  const [bypassCode, setBypassCode] = React.useState('');
  const [bypassCodeError, setBypassCodeError] = React.useState(null);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(THF_SERVER_URL+"get_or_create_customer_for_user_with_cards/"+userInfo.email,
      {signal: signal}
    ).then( (res) => {
      res.json().then( (customerData) => {
        //console.log("ConfigurePayments 'customerData'", customerData);
        if (customerData.customer) {
          //console.log("ConfigurePayments 'customerData.customer'", customerData.customer);
          setSquareCustomer(customerData.customer);
          if (customerData.cards) {
            setSquareCards(customerData.cards);
            if (customerData.cards.length > 0) {
              setSelectedCard(customerData.cards[0]);
              setAddingCard(false);
              return;
            };
          };
          console.log("ConfigurePayments start request new card", customerData.customer);
          setAddingCard(true);
        } else {
          setDisplayError("You'll need to configure your Square Card client for use.");
        };
      });
    }).catch( (error) => {
      if (error.name === 'AbortError') return;
      //console.error("Error: (ConfigurePayments)", error);
      setDisplayError("Unable to communicate with server. Please try again later.");
    }).finally( () => {
    });
    return () => controller.abort();
  }, [userInfo]); // Update if passed in userInfo (from okta authState in parent) changes

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  const NeedToSubscribeMessage = () => {
    return (<div style={{marginBottom: 30}}>
      <p>You are not subscribed to THF data.</p>
      <p>A THF subscription is $4500/month, charged upon subscription, access begins immediately.</p>
    </div>);
  };
  const subscriptionSuccessFn = () => {
    alert('subscription enabled');
    window.location.reload(); //to refresh auth
  };

  const PurchaseSubscriptionButtonElem = () => {
    if (!selectedCard) {
      return (
        <button disabled="disabled">Purchase Subscription</button>
      );
    };
    return (<button onClick={ ()=> {
      setLoading(true);
      buySubscription(accessToken.accessToken, squareCustomer.id, selectedCard.id, userInfo.sub, (res) => {
        if (res.success) {
          subscriptionSuccessFn();
        };
      });
    }}>Purchase Subscription</button>);
  };

  const [formData, setFormData] = React.useReducer(formReducer, {
    addressLine1: '',
    addressLine2: '',
    city: '',
    firstName: '',
    lastName: '',
  });
  const handleCreditOwnerChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
    if (formData.addressLine1.length > 0 && formData.city.length > 0 && formData.firstName.length > 0 && formData.lastName.length > 0) {
      setCardReady(true);
    } else {
      setCardReady(false);
    };
  };

  return (<div class='ph-no-capture'>
    {permissions && !permissions.valid_subscription && <NeedToSubscribeMessage />}

    {addingCard && squareCards && squareCards.length === 0 && (
      <button onClick={()=> setAddingCard(!addingCard)}>{addingCard ? '-' : 'Add Card'}</button>
    )}
    {addingCard && <div>
      <div style={{marginBottom: 20}}>
        <div>Credit card information:</div>
        <form className="credit-form">
          <label className="full-width-input">Address Line 1:
            <input required type="text" name="addressLine1" onChange={handleCreditOwnerChange} />
          </label>
          <label className="full-width-input">Address Line 2:
            <input type="text" name="addressLine2" onChange={handleCreditOwnerChange} />
          </label>
          <label className="full-width-input">City:
            <input required type="text"name="city" onChange={handleCreditOwnerChange} />
          </label>
          <label className="full-width-input">First Name:
            <input required type="text" name="firstName" onChange={handleCreditOwnerChange} />
          </label>
          <label className="full-width-input">Last Name:
            <input required type="text" name="lastName" onChange={handleCreditOwnerChange}  />
          </label>
        </form>
      </div>

      <PaymentForm
        applicationId={config.payments.appId}
        // Invoked when payment form receives the result of a tokenize generation request.
        // The result will be a valid credit card or wallet token, or an error.
        cardTokenizeResponseReceived={(token, buyer) => {
          console.log("Square card form response", { token, buyer })
          if (token && buyer) {
            setLoading(true);
            storeCard(token.token, squareCustomer.id, buyer.token, formData.firstName, formData.lastName, (cardResponse) => {
              console.log(cardResponse);
              setSquareCards([...squareCards, cardResponse.body.card])
              setLoading(false);
              setAddingCard(false);
            });
          } else {
            alert('Please verify form is filled out correctly');
          };
        }}
         //  This function enable the Strong Customer Authentication (SCA) flow
         //  We strongly recommend use this function to verify the buyer and
         //  reduce the chance of fraudulent transactions.
        createVerificationDetails={() => {
          const credentials = {
            intent: 'STORE',
            currencyCode: 'USD',
            // collected from the buyer
            billingContact: {
              addressLines: [formData.addressLine1, formData.addressLine2],
              familyName: formData.lastName,
              givenName: formData.firstName,
              countryCode: 'US',
              city: formData.city,
            },
          };
          console.log("Adding card to account", {credentials});
          return credentials;
        }}
         //  Identifies the location of the merchant that is taking the payment.
         // Obtained from the Square Application Dashboard - Locations tab.
        locationId={config.payments.locationId}
      >
        {cardReady && <CreditCard>Save Card</CreditCard>}
      </PaymentForm>
    </div>}

    <CardsList squareCards={squareCards} selectedCard={selectedCard} onSetCard={() => setSelectedCard()} />
    <p style={{marginTop: 10, marginBottom: 10, fontSize: 10, color: 'gray'}}>Information provided by THF is for research only. No warranty express or implied is made about the fitness of the information for any purpose. Trading is done at own risk.</p>
    <div style={{marginTop: 30}} />
    <PurchaseSubscriptionButtonElem />
    <div>
      <p>Have a code?</p>
      <input type='text' value={bypassCode} onChange={(e) => setBypassCode(e.target.value)} />
      {bypassCodeError && <p style={{color:'red'}}>{bypassCodeError}</p>}
      <button onClick={()=>{
        fetch(
          THF_SERVER_URL+"bypass_subscription?access_token="+accessToken.accessToken, 
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({code: bypassCode}),
          }
        ).then(r => {
          r.json().then((response)=>{
            if (response.success) {
              subscriptionSuccessFn();
            } else {
              setBypassCode('');
              setBypassCodeError('Wrong code.');
            }
          })
        })
      }}>Bypass</button>
    </div>
  </div>);
};

export default ConfigurePayments;
