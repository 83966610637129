import React from 'react';
import { LineChart, Line, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Input } from 'semantic-ui-react'

import { THF_SERVER_URL, getTimeStringDateFormat } from '../util';

const ModelErrorsChart = ({symbol, accessToken}) => {
  const [modelErrors, setModelErrors] = React.useState([]);
  const [arbitraryThreshold, setArbitraryThreshold] = React.useState("");

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken && symbol !== "") {
      fetch(
        THF_SERVER_URL+"errors?access_token="+accessToken+"&symbol="+symbol,
        {signal: signal}
      ).then( (res) => res.json().then( (list) => {
        setModelErrors(list[symbol]);
      })).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (Heatmap) component.", error);
        setDisplayError("Getting model errors failed.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [accessToken, symbol]);

  let modelErrorsFormatted = [];
  if (modelErrors) {
    modelErrorsFormatted = modelErrors.map( errorObj => {
      return {
        date: errorObj.calculatedAt,
        renderer: getTimeStringDateFormat({stringDate:errorObj.calculatedAt, shorthand:true}),
        "model stable": 0.18,
        [symbol] : errorObj.mean_absolute_percentage_error.toFixed(3),
      };
    }).filter((e,i,a) => i === a.findIndex(inner => inner.date === e.date)).sort((a,b) => parseInt(a.date) - parseInt(b.date));
  };

  if (modelErrorsFormatted.length > 0) {
    return (<div align="center">
      <h5>How stable is this model?</h5>
      <div style={{width:'100%', height:'400px'}}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={800} height={400} data={modelErrorsFormatted}>
            <Legend />
            <Tooltip />
            <YAxis />
            <XAxis dataKey="renderer" />
            <Line key={symbol} type="monotone" dataKey={symbol} stroke="#00ff00" connectNulls />
            {arbitraryThreshold !== "" && 
              <ReferenceLine y={arbitraryThreshold} label="AT" stroke="red" strokeDasharray="3 3" />
            }
          </LineChart>
        </ResponsiveContainer>
      </div>
      <Input placeholder='Arbitrary Threshold' onChange={ (event, data) => {
        let newFloat = parseFloat(data.value);
        if (isNaN(newFloat)) setArbitraryThreshold("");
        else setArbitraryThreshold(newFloat);
      }}/>
    </div>);
  } else {
    return (<React.Fragment />);
  };
};
export default ModelErrorsChart;
