import React from 'react';
import { Container, Image, Menu } from 'semantic-ui-react';
import { useNavigate } from "react-router-dom";

import { THF_SERVER_URL, PAGES_MAP } from './util';
import { signOutHandler } from './Components/OktaWidgets';
import useWindowDimensions from './Hooks/WindowDimensionsHook';

import './css/navbar.css';
const BURGER_AT_WIDTH = 1300;

const Navbar = ({ oktaAuth, authState, userInfo, permissions}) => {
  const [navExpanded, setNavExpanded] = React.useState(false);
  const navigate = useNavigate();
  //console.log(userInfo, authState);

  const login = () => { navigate('/login') }
  const logout = async () => {
    if (userInfo && userInfo.email === 'testBoarding@thf.ai') {
      console.log('is special test account, DELETING')
      fetch(
        THF_SERVER_URL + 'user?access_token='+authState.accessToken.accessToken,
        {
          method: 'DELETE'
        }
      ).then(r=> {
        console.log(r)
        window.location.reload();
      })
    } else {
      await signOutHandler(oktaAuth)
    }
  }

  const authenticated = (authState && authState.isAuthenticated);
  const isLoggedIn = (authState && (!authState.isPending && authState.isAuthenticated));
  const isAdmin = (authenticated && permissions && permissions.admin);

  const { windowWidth } = useWindowDimensions();

  const LogoHomeButton = () => {
    const env_label_styling = { 
      color: 'red',
      textAlign: 'center',
      position: 'absolute',
    };
    return (<div style={{maxWidth:'98px'}}>
      { process.env.NODE_ENV === "development" && <p style={env_label_styling}>DEV</p> }
      <Menu.Item as='a' header href="/"><Image size="small" src="images/svg/thf-text-white.svg" /></Menu.Item>
    </div>);
  };

  const MenuItem = ({displayText, href, cb, img}) => {
    const reactId = displayText.toLowerCase().replace(/ /g,"-")+"-navBut";
    return (<Menu.Item id={reactId} as='a' href={href} onClick={cb} fitted={false}>
      {img && <Image size="mini" src={img} />}
      {((img && windowWidth >= BURGER_AT_WIDTH) || !img) && displayText}
    </Menu.Item>);
  };

  if (!isLoggedIn) {
    let hideLogin = (window.location.pathname === '/' || window.location.pathname === '/login');
    return (<div>
      <Menu className="navMenuBar" fixed="top" inverted size='small'><Container fluid>
        <LogoHomeButton />

        {<span style={{flexGrow: 2, display: 'flex', justifyContent: 'flex-end'}}>
          {!hideLogin && !authState.isPending && !authState.isAuthenticated && <MenuItem displayText="Login" cb={login} />}
        </span>}
      </Container></Menu>
    </div>);
  };
  
  const dontGoIfHere = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const href = e.target.closest('a').href;
    const current = window.location.origin + window.location.pathname;
    if (href !== current) {
      setNavExpanded(false);
      // only actually navigate if we aren't already there
      navigate(href.replace(window.location.origin, ""));
    };
  };

  const DynamicLinks = ({asList=false}) => {
    const navMenuItem = (key, noIcon) => {
      return (<MenuItem key={key} displayText={PAGES_MAP[key].name} href={key} img={noIcon ? null : PAGES_MAP[key].icon} cb={dontGoIfHere} />);
    };
    var configurableLinks = [];
    if (authenticated && userInfo && permissions && permissions.valid_subscription) {
      Object.keys(PAGES_MAP).forEach(key => {
        if (isAdmin || permissions.pages.includes(key)) {
          if (PAGES_MAP[key].use) configurableLinks.push(key);
        };
      });
    } else {
      return (<div></div>);
    };
    if (asList) {
      return configurableLinks.map(key => {
        return (<li key={key+"-li"}>{navMenuItem(key, true)}</li>);
      });
    };
    const menuItems = configurableLinks.map(key => {
      if (!PAGES_MAP[key].icon) return;
      return navMenuItem(key);
    });
    return (<div className="site-nav">{menuItems}</div>);
  };

  // DynamicLinks can also join the burger menu, commented for convenience
  return (<div>
    <Menu className="navMenuBar" fixed="top" size='small' stackable inverted>
      <LogoHomeButton />
      <DynamicLinks /> {/* {!navExpanded && <DynamicLinks />} */}

      <span className="navCenter">
        <MenuItem displayText="Data Marketplace" href="/data_marketplace" img="images/svg/shopping-cart.svg" cb={dontGoIfHere}/>
      </span>

      <button className={navExpanded ? "navBurger expanded" : "navBurger"} onClick={() => {setNavExpanded(!navExpanded)}}>
        {navExpanded ? (
          <Image size="mini" src="images/svg/X-logo-white.svg" />
        ) : (
          <Image size="mini" src="images/svg/burger-menu-white.svg" />
        )}
      </button>

      <div className={navExpanded ? "personalized-nav expanded" : "personalized-nav"}>
        <ul>
          {(navExpanded && windowWidth < BURGER_AT_WIDTH) && DynamicLinks({asList:true}) /* burger shows more */}
          <li><MenuItem displayText="Portfolio" href="/portfolio" /></li>
          {isAdmin && <li><MenuItem displayText="Trading System" href="/trading" /></li>}
          {isAdmin && <li><MenuItem displayText="Partner Admin" href="/partner_admin" /></li>}
          {isAdmin && <li><MenuItem displayText="Admin" href="/admin" /></li>}
  	      {!navExpanded && <li><MenuItem displayText="Profile" href="/profile" /></li>}
  	      <li><MenuItem displayText="Logout" cb={() => logout()} /></li>
        </ul>
      </div>
    </Menu>
  </div>);
};
export default Navbar;
