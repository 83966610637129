import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Checkbox, Popup, Card, Image, Button } from 'semantic-ui-react';

import { trackingAnalytics } from '../Hooks/Analytics';
import useOptionPricings from '../Hooks/OptionPricings';

import LoadingProgressShown from './LoadingProgressShown';
import { downloadCsv, getTimeStringDateFormat, floatPadded, RIGHTS } from '../util';
import ContractInfo from './ContractInfo';
import Top5TableList from './Top5TableList';

const OptionPricingsTable = ({calls, puts, invertDiff})=> {
  return (<div style={{overflowX:'auto'}}>
    <table className="even-cells option-pricing">
      <thead><tr>
        <th>Right</th>
        <th>Strike</th>
        <th>Expiration</th>
        <th>Market Price</th>
        <th>THF Price</th>
        <th>Market Delta</th>
        <th>THF Delta</th>
        <th>% Difference</th>
        <th>Calculated At</th>
      </tr></thead>
      <tbody>
        {[puts,calls].map((chunk,i) => {
          return Object.keys(chunk).map(expiration => {
            if (chunk[expiration] === true) return null;
            const strikes = Object.keys(chunk[expiration]);
            return strikes.map( strike => {
              const contract = chunk[expiration][strike];
              let diff = (invertDiff ? contract.diff_market*100 : contract.diff_THF*100);
              return <tr key={""+i+strike+expiration}>
                <td>{i === 0 ? 'Put' : 'Call'}</td>
                <td>{strike}</td>
                <td>{getTimeStringDateFormat({stringDate:expiration})}</td>
                <td className="align-center">${floatPadded(contract.market_price, 4, '--')}</td>
                <td className="align-center">${floatPadded(contract.thf_price, 4, '--')}</td>
                <td className="align-center">{floatPadded(contract.market_delta, 4, '--')}</td>
                <td className="align-center">{floatPadded(contract.thf_delta, 2, '--')}</td>
                <td>{(diff).toFixed(4)}%</td>
                <td>{contract.date.toLocaleTimeString()}</td>
              </tr>
            });
          });
        })}
      </tbody>
    </table>
  </div>);
};

const NaiveHeatmap = ({
  yLabels, xLabels, heatmapData, onHover, hoveringOver, maxPositive, maxNegative, right, 
  inverted, showCalculationAge, selectedHeatmapContract, currentContract, invertDiff
})=> {
  const size = 20;
  let styleObj = {display:(right==='puts'?'block':'block'), marginLeft:'0px'};
  if (inverted) {
    let temp = JSON.parse(JSON.stringify(yLabels));
    yLabels = xLabels;
    xLabels = temp;
    styleObj = {};
  } else {
    yLabels = yLabels.map(l => parseFloat(l)).sort().reverse();
  };
  return (<div style={{display:'flex'}}>
    <div className="c_Heat-yLabels" style={styleObj}>
      {yLabels.map((y) => {
        return <div key={y} style={{height: size+"px"}}>{y}</div>
      })}
    </div>
    <div style={{width:'fit-content'}}>
      {yLabels.map((y) => {
        return (<div key={y} style={{display:'flex'}}>
          {xLabels.map((x) => {
            let hoverKey, val, contractInfo;
            if (inverted) contractInfo = heatmapData[y][x];
            else contractInfo = heatmapData[x][y];
            if (contractInfo) {
              hoverKey = contractInfo.contract+'-'+right;
              val = (invertDiff ? contractInfo.diff_market : contractInfo.diff_THF);
            };
            if (!val) val = 0;
            let color = "rgba(255,0,0,"+val / maxPositive+")";
            if (hoverKey == currentContract) { 
              // currently selected contract for details
              color = "rgba(230,250,0,60)"
            } else if (val < 0) {
              color = "rgba(0,255,0,"+val / maxNegative+")";
            };
            let age = '';
            if (showCalculationAge && contractInfo) {
              age = Math.trunc((new Date() - contractInfo.date) / (1000*60));
              if (age > 999) age = 999;
            };
            return (<div
              key={x}
              onMouseEnter={() => {if (contractInfo) onHover(hoverKey)}}
              onClick={() => {if (contractInfo) selectedHeatmapContract(hoverKey)}}
              style={{
                cursor:'pointer',
                backgroundColor: color,
                width: size+"px",
                height: size+"px",
                border: (hoveringOver === hoverKey ? "1px solid black" : "none"),
                color: (hoverKey === currentContract ? "black" : "inherit"),
                fontSize: '11px',
                textAlign: 'center',
              }}
              className="heatmap-item">{age}
            </div>);
          })}
        </div>);
      })}
      <div style={{display:'flex'}}>
        {xLabels.map((x) => {
          let axisVal = x.substr(6);
          if (inverted) {
            axisVal = x;
          };
          return <div
            key={x}
            style={{
              width: size+"px",
              height: size+"px",
              fontSize: 11,
            }}
            className="heatmap-item">{axisVal}</div>;
        })}
      </div>
    </div>
  </div>);
};

var lastSymbol = null;
const Heatmap = ({accessToken, symbol, permissions, updateUserPermissions, trackActivity=null, onHover=null}) => {
  const [loading, setLoading] = React.useState(true);

  const [hoveringOver, setHoveringOver] = React.useState("");

  const [invertDiff, setInvertDiff] = React.useState(false);
  const [invertedYAxis, setInvertedYAxis] = React.useState(false);
  const [tableView, setTableView] = React.useState(false);
  const [drawListTop5s, setDrawListTop5s] = React.useState(false);
  
  const [showTop5s, setShowTop5s] = React.useState(permissions.preferredViews.heatmap.heat_top5);
  const [showOldContracts, setShowOldContracts] = React.useState(permissions.preferredViews.heatmap.old_contracts);
  const [showCalculationAge, setShowCalculationAge] = React.useState(permissions.preferredViews.heatmap.calculation_age);
  const [showRealCost, setShowRealCost] = React.useState(permissions.preferredViews.heatmap.showRealCost);

  const { 
    displayPuts, displayCalls, heatmapError, modelError, clearOptionPricingRefetch, optionPricingsCsvString 
  } = useOptionPricings({accessToken, symbol, showOldContracts, setLoading});

  const [search, setSearch] = useSearchParams();
  function grabUrlContract() {
    if (!lastSymbol) lastSymbol = search.get('symbol');
    let contract = search.get('contract');
    if (!contract) contract = "";
    return contract;
  };
  
  const [selectedContract, setSelectedContract] = React.useState(grabUrlContract());
  let displayContract = {};
  function selectedHeatmapContract(contractRef) {
    setSearch({symbol: symbol, contract: contractRef});
    setSelectedContract(contractRef);
    //if (trackingAnalytics.heatmap.selection) trackActivity('heatmapSelect', {group_id: symbol, item_id: contractRef});
  };

  var lastHover = null;
  React.useEffect(() => {
    if (onHover) onHover(hoveringOver);
    if (lastHover != hoveringOver) {
      lastHover = hoveringOver;
      //if (trackingAnalytics.heatmap.hover) trackActivity('heatmapHover', {group_id: symbol, item_id: hoveringOver});
    };
  }, [hoveringOver, selectedContract]);

  const location = useLocation();
  React.useEffect(() => {
    clearOptionPricingRefetch(false);
  }, [location]);

  const queryReset = () => {
    clearOptionPricingRefetch(true);
    setHoveringOver("");
    setLoading(true);
  };

  React.useEffect(() => {
    if (lastSymbol != symbol) {
      setDisplayContract(null);
      setSelectedContract("");
      queryReset();
    };
  }, [symbol]);

  const IsOldWarningDisplay = ({contractDate}) => {
    return (<div className="c_Heat-warning">
      {(!contractDate || Date.now() - contractDate <= 3600000) ? (
        <React.Fragment/>
      ) : (<div>
        WARNING: CONTRACT YOU HAVE SELECTED IS MORE THAN 1 HOUR OUT OF DATE
      </div>)}
    </div>);
  };

  /* Loading/fetching, wait here. */
  if (loading) return (<LoadingProgressShown type='spinner' />);
  
  const MapCallsPuts = () => {
    return (<div className="c_Heatmap-calls-puts">
      {RIGHTS.map( right => {
        const heatmapData = right === "calls" ? displayCalls : displayPuts;
        let xLabels = Object.keys(heatmapData);
        let yLabels = [];
        let maxPositive = 0;
        let maxNegative = 0;
        Object.keys(heatmapData).forEach((dateKey) => {
          if (heatmapData[dateKey] === true) {
            xLabels = xLabels.filter(n => dateKey !== n);
          } else {
            Object.keys(heatmapData[dateKey]).forEach((strike) => {
              yLabels.push(strike);
              const diff = (invertDiff ? heatmapData[dateKey][strike].diff_market : heatmapData[dateKey][strike].diff_THF);
              if (diff > maxPositive) maxPositive = diff;
              if (diff < maxNegative) maxNegative = diff;
            });
          };
        });
        yLabels = [...new Set(yLabels)].sort();
        if (yLabels.length < 1 || xLabels.length < 1) {
          //console.log("Heatmap Component, fetched data:", {symbol, heatmapData, yLabels, xLabels});
          return (<div key={right}></div>);
        };

        return (<div className="c_Heat-map" key={right} onMouseLeave={() => setHoveringOver("")}>
          <h4 style={{textAlign:"left"}}>{right}</h4>
          <NaiveHeatmap
            invertDiff={invertDiff}
            yLabels={yLabels}
            xLabels={xLabels}
            heatmapData={heatmapData}
            onHover={(hoverKey) => setHoveringOver(hoverKey)}
            maxPositive={maxPositive}
            maxNegative={maxNegative}
            right={right}
            hoveringOver={hoveringOver}
            inverted={invertedYAxis}
            showCalculationAge={showCalculationAge}
            currentContract={selectedContract}
            selectedHeatmapContract={(selection) => selectedHeatmapContract(selection)}
          />
        </div>);
      })}
    </div>);
  };

  const HeatMapTable = () => {
    //console.log("Heatmap data", {displayCalls, displayPuts});
    let notTrueCount = 0;
    if (Object.keys(displayPuts).length > 0) {
      Object.keys(displayPuts).forEach((dateKey) => {
        if (displayPuts[dateKey] !== true) notTrueCount += 1;
      });
    };
    if (Object.keys(displayCalls).length > 0) {
      Object.keys(displayCalls).forEach((dateKey) => {
        if (displayCalls[dateKey] !== true) notTrueCount += 1;
      });
    };
    if (notTrueCount < 1) return (<div>
      <h4>Calls and Puts are not available at this time, please check back later.</h4>
    </div>);

    return (<React.Fragment>
      <div className="c_Heatmap-container">
  			{!tableView && <MapCallsPuts />}
      </div>
    </React.Fragment>);
  };

  function setDisplayContract(uiID) {
    if (!uiID) {
      displayContract = {};
      return;
    };
    let dateKey = uiID.split("_")[1];
    let strikeKey = uiID.split("_")[2];
    if (strikeKey) strikeKey = strikeKey.split(".0-")[0];
    let rightType = uiID.split("-")[1];
    displayContract.symbol = symbol;
    displayContract.right = rightType;
    displayContract.strike = strikeKey;
    displayContract.expiration = dateKey;
    const heatmapData = rightType === 'calls' ? displayCalls : displayPuts;
    let contractBlob = null;
    if (heatmapData[dateKey]) contractBlob = heatmapData[dateKey][strikeKey];
    if(contractBlob) {
      displayContract.market_price = contractBlob.market_price;
      displayContract.thf_price = contractBlob.thf_price;
      displayContract.market_delta = contractBlob.market_delta;
      displayContract.thf_delta = contractBlob.thf_delta;
      displayContract.diff_THF = contractBlob.diff_THF * 100;
      displayContract.diff_market = contractBlob.diff_market * 100;
      displayContract.accuracy = (modelError ? modelError : "--");
      displayContract.date = contractBlob.date;
    } else {
      displayContract.market_price = "not valid contract";
      //console.warn("Heatmap Component can't locate contractBlob:", {contractRef:uiID, expireDate, strikeKey, heatmapData});
    };
  };
  if (hoveringOver !== "") {
    setDisplayContract(hoveringOver);
  } else if (selectedContract !== "") {
    setDisplayContract(selectedContract);
  };

  return (<div className="pageContent">
    <div align="center">
      <HelperHeatMapGeneral permissions={permissions} updateUserPermissions={updateUserPermissions}/>
      <IsOldWarningDisplay contractDate={displayContract.date}/>
    </div>

    {tableView && (<React.Fragment>
      <div>
        <button onClick={() => downloadCsv(symbol, optionPricingsCsvString())}>Export to CSV</button>
        <HelperBelowIntrinsicError />
      </div>
      <OptionPricingsTable calls={displayCalls} puts={displayPuts} invertDiff={invertDiff}/>
    </React.Fragment>)}

    {heatmapError && <p>{heatmapError}</p>}

    <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap',marginBottom:'12px'}} onMouseEnter={() => setHoveringOver("")}>
      <HeatMapTable />
      {/*!tableView && <ContractBooklet contracts={{displayCalls, displayPuts}} selectedContracts={[selectedContract]} hoveringOver={hoveringOver}
        modelError={modelError} permissions={permissions} updateUserPermissions={updateUserPermissions} accessToken={accessToken}
      />*/}
      {!tableView && <ContractInfo invertDiff={invertDiff} showRealCost={showRealCost} info={displayContract} permissions={permissions} updateUserPermissions={updateUserPermissions} accessToken={accessToken}/>}
    </div>

    <div align="center">{showTop5s && !tableView && <Top5TableList invertDiff={invertDiff}
      calls={displayCalls} puts={displayPuts} drawListTop5s={drawListTop5s} showRealCost={showRealCost}
      onSelect={(objRef) => selectedHeatmapContract(objRef)} selectedContract={selectedContract}
      onHover={(objRef) => setHoveringOver(objRef)}
      onExit={() => setHoveringOver("")}
    />}</div>
		<IsOldWarningDisplay contractDate={displayContract.date}/>

    <div align='center'>
      <div style={{display:'block', width:'fit-content', marginLeft:'128px'}} align='left'>
        <ul className="plain-left-list" style={{width:'300px',marginTop:'0px'}}>
  			  <li><Checkbox label="Show old contracts" toggle onChange={(e, data) => {
            var updatedPreferredViews = permissions.preferredViews;
            updatedPreferredViews.heatmap.old_contracts = data.checked;
            updateUserPermissions('preferredViews', updatedPreferredViews);
  					setShowOldContracts(data.checked)
  					queryReset();
  				}} checked={showOldContracts}/></li>
          <li><Checkbox label="Invert THF Market Difference" toggle onChange={(e, data) => {
    				setInvertDiff(data.checked);
    			}} checked={invertDiff}/></li>
          <li><Checkbox label="Toggle Table View" toggle onChange={(e, data) => {
    				setTableView(data.checked);
    			}} checked={tableView}/></li>
          {!tableView && <React.Fragment>
            <li><Checkbox label="Show calculation age" toggle onChange={() => {
              var updatedPreferredViews = permissions.preferredViews;
              updatedPreferredViews.heatmap.calculation_age = !showCalculationAge;
              updateUserPermissions('preferredViews', updatedPreferredViews);
    					setShowCalculationAge(!showCalculationAge);
    				}} checked={showCalculationAge}/></li>
            <li><Checkbox label="Show full cost" toggle checked={showRealCost} onChange={() => {
              var updatedPreferredViews = permissions.preferredViews;
              updatedPreferredViews.heatmap.showRealCost = !showRealCost;
              updateUserPermissions('preferredViews', updatedPreferredViews);
              setShowRealCost(!showRealCost);
            }}/></li>
            <li><Checkbox label="Invert Axis" toggle onChange={(e, data) => {
              setInvertedYAxis(data.checked);
            }} checked={invertedYAxis}/></li>
            <li><Checkbox label="Show Top5" toggle onChange={(e, data) => {
              var updatedPreferredViews = permissions.preferredViews;
              updatedPreferredViews.heatmap.heat_top5 = data.checked;
              updateUserPermissions('preferredViews', updatedPreferredViews);
              setShowTop5s(data.checked)
            }} checked={showTop5s}/></li>
          </React.Fragment>}
          {showTop5s && !tableView && <li>
          <Checkbox label="Top5 as List" toggle onChange={(e, data) => {
              setDrawListTop5s(data.checked)
            }} checked={drawListTop5s}/>
          </li>}
        </ul>
      </div>
    </div>
  </div>);
};
export default Heatmap;

export const HelperHeatMapGeneral = ({ permissions, updateUserPermissions, position="bottom left", align="center" }) => {
  const [open, setOpen] = React.useState(false)

  return (<div align={align} >
    {(permissions && permissions.preferredViews.heatmap.showTipHeatmapWhatIs) ? (
      <Popup className="helperPopup" open={open} position={position} //hideOnScroll
        trigger={<Button circular compact size='tiny' onClick={() => setOpen(!open)}>
          What am I looking at?
        </Button>}
      >
        <Popup.Header> </Popup.Header>
        <Popup.Content>
          <Card style={{width:'380px'}}>
            <Image wrapped ui={false} src='images/heatmap_howto.png' />
            <Checkbox label={<label ref={(el) => {
              if (el) el.style.setProperty('color', 'black', 'important');
            }}>close and hide button, it can be re-enabled in profile.</label>} onChange={() => {
              var updatedPreferredViews = permissions.preferredViews;
              updatedPreferredViews.heatmap.showTipHeatmapWhatIs = false;
              updateUserPermissions('preferredViews', updatedPreferredViews);
              setOpen(false);
            }} />
          </Card>
        </Popup.Content>
      </Popup>
    ) : (
      <React.Fragment/>
    )}
  </div>);
};

export const HelperBelowIntrinsicError = ({}) => {
  return (<React.Fragment>
    <Popup className="helperPopup" hideOnScroll
      trigger={<Button circular compact size='tiny'>
        Why are some values zero?
      </Button>}
    >
      <Popup.Header> </Popup.Header>
      <Popup.Content>
        <Card style={{width:'380px'}}>
          <p>Not all delta calculations work/make sense. The further you go out of the money the more likely this is to occur. Often our server tries to calculate this with the assistance of libraries. One of which we are using can throw a 'BelowIntrinsicError'. This happens when the resulting value implies an option value below the "intrinsic" price of the option, which is impossible in theory. Our predictions can produce this result when we think an option is overvalued by the market. So for those calculation cases the UI will display '--' for those deltas.</p>
        </Card>
      </Popup.Content>
    </Popup>
  </React.Fragment>);
};
