import React from 'react';
import { Button, Select } from 'semantic-ui-react'

import LoadingProgressShown from '../Components/LoadingProgressShown';
import useManagedSymbols from '../Hooks/ManagedSymbols';

const ManagedSymbolsPage = ({accessToken}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);

  const [showNew, setShowNew] = React.useState(false);
  const [newSymbol, setNewSymbol] = React.useState({
    symbol: "",
    type: "index",
    exchange: "SMART",
    chainExchange: "SMART",
    tradingClass: "",
    description: "",
  });

  const { managedSymbols, saveNewManagedSymbol, removeManagedSymbol } = useManagedSymbols({accessToken, setLoading, setDisplayError});

  const handleSave = () => {
    if (newSymbol.symbol === "") return alert("fill out symbol");
    if (newSymbol.description === "") return alert("fill out description");
    saveNewManagedSymbol(newSymbol, setShowNew);
  };

  const handleRemove = (e, symbol) => {
    e.stopPropagation();
    e.preventDefault();
    removeManagedSymbol(symbol);
  };

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  return (<div className="pageContent">
    <Button onClick={() => setShowNew(true)}>Add New</Button>
    <div className={showNew ? "" : "hide"}>
      <div>symbol: <input type="text" value={newSymbol.symbol} onChange={(e) => setNewSymbol({...newSymbol, symbol: e.target.value})}/></div>
      type: <Select
        placeholder='Select your type'
        options={[{key: "index", value: "index", text: "index"},{key: "stock", value: "stock", text: "stock"}]}
        value={newSymbol.type}
        onChange={(e, { value }) => {
          setNewSymbol({...newSymbol, type: value})
        }}
      />
      <div>exchange: <input type="text" value={newSymbol.exchange} onChange={(e) => setNewSymbol({...newSymbol, exchange: e.target.value})}/></div>
      <div>chain exchange: <input type="text" value={newSymbol.chainExchange} onChange={(e) => setNewSymbol({...newSymbol, chainExchange: e.target.value})}/></div>
      <div>trading class: <input type="text" value={newSymbol.tradingClass} onChange={(e) => setNewSymbol({...newSymbol, tradingClass: e.target.value})}/></div>
      <div>description: <input type="text" value={newSymbol.description} onChange={(e) => setNewSymbol({...newSymbol, description: e.target.value})}/></div>
      <Button onClick={handleSave}>Save</Button>
    </div>
    <table>
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Type</th>
          <th>Exchange</th>
          <th>Chain Exchange</th>
          <th>Trading Class</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {managedSymbols.map((sym) => {
          return <tr key={sym.id}>
            <td>{sym.symbol}</td>
            <td>{sym.type}</td>
            <td>{sym.exchange}</td>
            <td>{sym.chainExchange}</td>
            <td>{sym.tradingClass}</td>
            <td>{sym.description}</td>
            <td><button onClick={(e) => handleRemove(e, sym)}>x</button></td>
          </tr>
        })}
      </tbody>
    </table>
  </div>);
};

export default ManagedSymbolsPage;
