import React from 'react';
import { Header, Icon, Table, Checkbox, Button } from 'semantic-ui-react';

import { LocalStoreContext } from '../App';
import { DEFAULT_PREFERRED_VIEWS } from '../util';
import { SymbolFavoriteList } from '../Hooks/ManagedSymbols';
import useCopyToClipboard from '../Hooks/CopyToClipboardHook';

import { TimedTip } from '../Components/HoverToolTip';
import ConfigurePayments from '../Components/ConfigurePayments';
import MarkedTrades from '../Components/MarkedTrades';
import SymbolComments from '../Components/SymbolComments';

const ProfilePage = ({userInfo, permissions, updateUserPermissions, accessToken}) => {
  const [localReset, setLocalReset] = React.useState(false);
  const [permissionsPVReset, setPermissionsPVReset] = React.useState(false);
  const [displaySymbol, setDisplaySymbol] = React.useState("");

  const { clipboardCopy } = useCopyToClipboard();

  if (!userInfo) {
    return (<div>
      <p>Fetching user profile...</p>
    </div>);
  };

  return (<div className="pageContent">
    <div>
      <Header as="h1">
        <Icon name="drivers license" />
        {' '}
        My User Profile
      </Header>
      <a href="/apidocs">
        Read the API Docs
      </a>
      <Table color="blue">
        <thead><tr>
          <Table.HeaderCell width={4}>Attribute</Table.HeaderCell>
          <Table.HeaderCell width={8}>Value</Table.HeaderCell>
        </tr></thead>
        <tbody>
          {Object.entries(userInfo).map((claimEntry) => {
            const claimName = claimEntry[0];
            const claimValue = claimEntry[1];
            const claimId = `claim-${claimName}`;
            if (!['name', 'email'].includes(claimName)) return null;
            return (
              <tr key={claimName}>
                <td>{claimName}</td>
                <td id={claimId}>{claimValue}</td>
              </tr>
            );
          })}
          <tr>
            <TimedTip tipContent="Copied!" label={<td>
              API session key&nbsp;
              <button onClick={() => clipboardCopy(accessToken)}>Copy to Clipboard</button>
            </td>} />
            <td style={{maxWidth: '400px'}}>
              <div style={{overflow:'scroll', overflowY:'hidden', width:'100%'}}>{accessToken}</div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
    <br/>

		<LocalStoreContext.Consumer>{context => {
			return (<div style={{display:'flex', justifyContent:'space-between'}}>
				{permissions && <Checkbox label={"Theme: "+context.theme.charAt(0).toUpperCase()+context.theme.slice(1)+" Mode" }
					toggle onChange={(e, data) => context.toggleTheme((newTheme) => {
            updateUserPermissions('visual_mode', newTheme);
          })}
					checked={context.theme === 'dark'}
				/>}
        <div>
          {!localReset && <Button label='Reset Local Cache' onClick={() => {
            context.clearLocalUserStore();
            setLocalReset(true);
          }} />}
          {!permissionsPVReset && <Button label='Reset Preferred Views' onClick={() => {
            updateUserPermissions('preferredViews', DEFAULT_PREFERRED_VIEWS);
            setPermissionsPVReset(true);
          }} />}
        </div>
      </div>);
		}}</LocalStoreContext.Consumer>
    <hr className="chapter"/>

    <MarkedTrades accessToken={accessToken}/>
    <hr className="chapter"/>

    <ConfigurePayments userInfo={userInfo} accessToken={accessToken} permissions={permissions}/>
    <hr className="chapter"/>

    {permissions && (<React.Fragment>
      <div align='right'>
        <label style={{paddingRight:'110px'}}>Review comment archives for: </label>
        <SymbolFavoriteList accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} 
          selectedItem={displaySymbol} onSelection={(value) => setDisplaySymbol(value)} 
        />
      </div>
      <SymbolComments accessToken={accessToken} userInfo={userInfo} symbol={displaySymbol} showAll={true} />
    </React.Fragment>)}
  </div>);
};
export default ProfilePage;
