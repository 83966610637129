import React from 'react';

import { RIGHTS } from '../util';
import LoadingProgressShown from '../Components/LoadingProgressShown';
import useOptionPricings from '../Hooks/OptionPricings';

import FloatClickToCopy from './FloatClickToCopy';

const Top5TableList = ({calls, puts, accessToken, symbol, selectedContract=null, fetchDelay=0, showLoading=false,
   onSelect, onHover, onExit, invertDiff=false, showRealCost=false, drawListTop5s=false
}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);

  if (!calls && !puts && symbol && accessToken) {
    const { displayCalls, displayPuts } = useOptionPricings({accessToken, symbol, live:false, fetchDelay, setLoading, setDisplayError});
    calls = displayCalls;
    puts = displayPuts;
  };

  const [tops, setTops] = React.useState(updateTop5s(false));

  function updateTop5s(updateState=true) {
    let newTops = {}
    RIGHTS.forEach( (right) => {
      let temp = [];
      const heatmapData = right === "calls" ? calls : puts;
      if (!heatmapData) return;
      Object.keys(heatmapData).forEach( (dateKey) => {
        if (heatmapData[dateKey] === true) return;
        Object.keys(heatmapData[dateKey]).forEach( (strike) => {
          const diff = (invertDiff ? Math.abs(heatmapData[dateKey][strike].diff_market) : Math.abs(heatmapData[dateKey][strike].diff_THF) );
          const contract = heatmapData[dateKey][strike].contract;
          const contractRef = contract+"-"+right;
          const yearDayMonth = [dateKey.slice(0, 4), dateKey.slice(4,6), dateKey.slice(6,8)];
          const shortDate = yearDayMonth[2]+"_"+yearDayMonth[1];
          temp.push({diff, dateKey, strike, contractRef, shortDate})
        });
      });
      newTops[right] = temp.sort((a,b) => b.diff - a.diff).slice(0,5);
    });
    if (updateState) setTops(newTops);
    return newTops;
  };

  React.useEffect(() => {
    updateTop5s();
  }, [invertDiff, calls, puts]);

  /* Loading/Error, wait here. */
  if ((showLoading && loading) || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  return (<div className="c_Heat-top5">
    {RIGHTS.map( (right) => {
      const heatmapData = right === "calls" ? calls : puts;
      if (!heatmapData) return <p>Unavailable</p>;
      if (drawListTop5s) {
        return (<ol key={right}>
          <h4>Top 5 {right}</h4>
          {tops[right] && tops[right].map( (obj) => {
            return (
              <li key={right + obj.dateKey + obj.strike}
                style={{cursor:'pointer',marginBottom:"26px"}}
                onClick={() => (onSelect ? onSelect(obj.contractRef) : {})}
                onMouseEnter={() => (onHover ? onHover(obj.contractRef) : {})}
                onMouseLeave={() => (onExit ? onExit() : {})}
              >
                <div>{obj.shortDate} {right} at {obj.strike} strike</div>
                <div>
                  market: {heatmapData[obj.dateKey][obj.strike].market_price.toFixed(2)} 
                  &nbsp;vs thf: {heatmapData[obj.dateKey][obj.strike].thf_price.toFixed(2)}
                </div>
              </li>
            );
          })}
        </ol>);
      } else {
        return (<div style={{maxWidth:'400px',textAlign:'center'}} key={right}>
          <h4>Top 5 {right}</h4>
          <table className="even-cells heatmap-top5 no-scroll">
            <thead><tr>
              <th>DATE</th>
              <th>STRIKE</th>
              <th>Market</th>
              <th>vs. THF</th>
              <th>AGE</th>
            </tr></thead>
            <tbody>{tops[right] && tops[right].map( (obj) => {
              if (!heatmapData[obj.dateKey] || !heatmapData[obj.dateKey][obj.strike]) {
                console.warn("Cant' find contract in current heatmap", {obj});
                return (<React.Fragment/>);
              };
              let date = heatmapData[obj.dateKey][obj.strike].date;
              let age = Math.trunc((new Date() - date) / (1000*60));
              if (age > 999) age = 999;
              let cost_thf = (showRealCost ? heatmapData[obj.dateKey][obj.strike].thf_price*100 : heatmapData[obj.dateKey][obj.strike].thf_price);
              let cost_market = (showRealCost ? heatmapData[obj.dateKey][obj.strike].market_price*100 : heatmapData[obj.dateKey][obj.strike].market_price);
              return (<tr key={"tb-"+right+obj.dateKey+obj.strike}
                style={{cursor:'pointer', backgroundColor:(selectedContract === obj.contractRef ? 'LightGray' : 'inherit')}}
                onClick={() => (onSelect ? onSelect(obj.contractRef) : {})}
                onMouseEnter={() => (onHover ? onHover(obj.contractRef) : {})}
                onMouseLeave={() => (onExit ? onExit() : {})}
              >
                <td>{obj.shortDate}</td>
                <td>{obj.strike}</td>
                <td>
                  <FloatClickToCopy value={cost_market} message="$%value%" decimalPlaces={(showRealCost ? 2 : 4)} substituteZero={'--'}/>
                </td>
                <td>
                  <FloatClickToCopy value={cost_thf} message="$%value%" decimalPlaces={(showRealCost ? 2 : 4)} substituteZero={'--'}/>
                </td>
                <td>{age}</td>
              </tr>)
            })}</tbody>
          </table>
        </div>);
      };
    })}
  </div>);
};
export default Top5TableList;
