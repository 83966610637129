import React from 'react';
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, useNavigate, Routes } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import config from './config';
import usePermissions from './Hooks/Permissions';
import Navbar from './Navbar';
import LoadingProgressShown, { AuthenticatingPermissions } from './Components/LoadingProgressShown';

import Admin from './Pages/Admin';
import AiGamePage from './Pages/AiGamePage';
import ChannelPartnerPanel from './Pages/ChannelPartnerPanel';
import DataMarketplacePage from './Pages/DataMarketplacePage';
import ErrorPage from './Pages/ErrorPage';
import HeatmapPage from './Pages/HeatmapPage';
import HistoryPage from './Pages/HistoryPage';
import HomePage from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';
import LogsPage from './Pages/LogsPage';
import ManagedSymbolsPage from './Pages/ManagedSymbolsPage';
//import MessagesPage from './Pages/MessagesPage';
import ProfilePage from './Pages/ProfilePage';
import PortfolioPage from './Pages/PortfolioPage';
import ProbabilityZones from './Pages/ProbabilityZones';
import ToddPage from './Pages/ToddPage';
import Top10Page from './Pages/Top10Page';
import TradingSystemPage from './Pages/TradingSystem';
import Referral from './Pages/Referral';
import SymbolTickers from './Pages/SymbolTickers';
import Docs from './Pages/Docs';

//import useAnalytics from './Hooks/Analytics';

import { Security, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { OktaConfig } from './Components/OktaWidgets';
const oktaAuth = new OktaAuth(OktaConfig);

import isMockingData, { mockResUserInfo } from './MockService/inPlace';
if (isMockingData) console.warn("Currently mocking 'fetch' requests.");

export const LocalStoreContext = React.createContext(null);

const ContainedRoutes = () => {
  const { authState, authService } = useOktaAuth();
  const [displayError, setDisplayError] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(null);

  const { accessToken, permissions, updateUserPermissions } = usePermissions({setDisplayError});

  const canViewPage = (pageName) => {
    if (!(permissions && permissions.valid_subscription)) return false;
    return (permissions.admin || permissions.pages.includes(pageName));
  };

  React.useEffect(() => {
    if (oktaAuth && authState && authState.accessToken) {
      oktaAuth.token.getUserInfo().then(info => {
        if (isMockingData) {
          setUserInfo(mockResUserInfo);
          return;
        };
        setUserInfo(info);
        //console.debug("(App 'root') setting 'userInfo'", info);
      });
    } else {
      setUserInfo(null);
      //console.log("(App 'root') clearing 'userInfo', authentication state:", authState);
    };
  }, [authState, oktaAuth]);

  
  /* Loading/Error, there is a small period when Okta initializes it's authState which will be null. */
  let loading = (!oktaAuth || !authState);
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(displayError == null) && <LoadingProgressShown type='text' text="Configuring AuthState..." />}
  </div>);

  return <div className="appView">
    <Navbar oktaAuth={oktaAuth} authState={authState} userInfo={userInfo} permissions={permissions}/>
    <Container className="pageView">
      <Routes>
        <Route path="/" exact element={
          <HomePage authState={authState} userInfo={userInfo} accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} />
        } />
        <Route path="/error" element={<ErrorPage permissions={permissions} authState={authState} userInfo={userInfo}/>}/>
        <Route path="/implicit/callback" element={<LoginCallback />} />
        <Route path="/login" element={<LoginPage oktaAuth={oktaAuth} authState={authState} />} />
        <Route path="/aigame" element={<AiGamePage />} />
        <Route path="/apidocs" element={<Docs />} />
        <Route path="/r/:code" render={()=> <Referral accessToken={authState && authState.accessToken}/>}/>
        {userInfo && <Route path="/profile" element={
          <ProfilePage userInfo={userInfo} accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions}/>
        } />}
      </Routes>
      
      {(permissions) ? (<Routes>
        {(canViewPage('data_marketplace')) && <Route path="/data_marketplace" element={
          <DataMarketplacePage userInfo={userInfo} accessToken={accessToken} permissions={permissions} />
        }/>}
        {(canViewPage('heatmap')) && <Route path="/heatmap" element={
          <HeatmapPage userInfo={userInfo} accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} />
        }/>}
        {(canViewPage('history')) && <Route path="/history" element={<HistoryPage accessToken={accessToken} />} />}
        {(canViewPage('logs')) && <Route path="/logs" element={<LogsPage accessToken={accessToken} permissions={permissions} />} />}
        {(canViewPage('managed_symbols')) && <Route path="/managed_symbols" element={<ManagedSymbolsPage accessToken={accessToken} />} />}
        {/* (canViewPage('messages')) && <Route path="/messages" element={<MessagesPage accessToken={accessToken} />} /> */}
        {(canViewPage('probability_zones')) && <Route path="/probability_zones" element={
          <ProbabilityZones accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions}/>
        }/>}
        {(canViewPage('portfolio')) && <Route path="/portfolio" element={<PortfolioPage accessToken={accessToken} permissions={permissions} />} />}
        {(canViewPage('todd')) && <Route path="/todd" element={
          <ToddPage userInfo={userInfo} accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions}/>
        }/>}
        {(canViewPage('top10')) && <Route path="/top10" element={<Top10Page accessToken={accessToken} />} />}
        {(canViewPage('trading')) && <Route path="/trading" element={<TradingSystemPage authState={authState} accessToken={accessToken} />} />}

        {(permissions && permissions.valid_subscription) && <Route path="/symbol_tickers" element={
          <SymbolTickers userInfo={userInfo} accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} />
        }/>}
        {(permissions && permissions.admin) && <Route path="/admin" element={
          <Admin permissions={permissions} accessToken={accessToken} updateUserPermissions={updateUserPermissions}/>
        }/>}
        {(permissions && (permissions.channel_partner || permissions.admin)) && <Route path="/partner_admin" element={
          <ChannelPartnerPanel accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions}/>
        }/>}
      </Routes>) : (<React.Fragment>
        {accessToken && <AuthenticatingPermissions permissions={permissions} />}
      </React.Fragment>)}
    </Container>
  </div>
};

const HasAccessToRouter = () => {
  const navigate = useNavigate();

  // Redirect to the /login page that has a CustomLoginComponent
  const customAuthHandler = () => {
    navigate('/login');
  };
  
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
  };

  return (
    <Security
      {...config.oidc}
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={customAuthHandler}
    >
      <ContainedRoutes />
    </Security>
  );
};

const App = () => {
  let loadTemp = localStorage.getItem('currentTheme');
  if (loadTemp == null) loadTemp = 'light';
	const [theme, setTheme] = React.useState(loadTemp);

	const toggleTheme = (cb) => {
    let newTheme = (theme === 'light' ? 'dark' : 'light');
    changeTheme(newTheme);
    cb(newTheme);
	};

  function changeTheme(newTheme) {
    localStorage.setItem('currentTheme', newTheme);
		setTheme(newTheme);
  };

  loadTemp = localStorage.getItem('localUserCache');
  // default localStorage settings
  if (loadTemp == null) {
    loadTemp = {
    };
  } else {
    loadTemp = JSON.parse(loadTemp);
  };
  const [localUserCache, setLocalUserCache] = React.useState(loadTemp)

  const setLocalUserStore = (cacheKey, newValue) => {
    let updatedCache = localUserCache;
    updatedCache[cacheKey] = newValue;
    localStorage.setItem('localUserCache', JSON.stringify(updatedCache));
    setLocalUserCache(updatedCache);
  };

  loadTemp = null;
  const clearLocalUserStore = () => {
    localStorage.removeItem('localUserCache');
  }

 	return (
		<LocalStoreContext.Provider value={{ theme, changeTheme, toggleTheme, localUserCache, setLocalUserStore, clearLocalUserStore }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>THF</title>
        <link rel="canonical" href="http://thf.ai" />
        <base href="/" />
        {(theme === 'dark') ? 
          <link rel="stylesheet" type="text/css" href="css/theme_darkmode.css" /> : 
          <link rel="stylesheet" type="text/css" href="css/theme_lightmode.css" /> 
        }
        {/*<script type="text/javascript">{`
          !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
          posthog.init('phc_JMI0JOQVzB4d3w2ktTaUn9kZDDyqlebugyZIm4bpxL7',{api_host:'https://app.posthog.com'})
        `}</script>*/}
      </Helmet>

			<div className="App" id={theme}>
				<BrowserRouter>
					<HasAccessToRouter />
				</BrowserRouter>
			</div>

		</LocalStoreContext.Provider>
	);
};

export default App;
