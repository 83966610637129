import React from 'react';

import { THF_SERVER_URL } from '../util';
import isMockingData, { mockSymbolComments } from '../MockService/inPlace';

export default function useSymbolComments({accessToken, userInfo, symbol, setLoading=null, setDisplayError=null}) {
  const [saving, setSaving] = React.useState(false);
  const [readingComment, setReadingComment] = React.useState(null);

  const [symbolComments, setSymbolComments] = React.useState([]);
  const [readComments, setReadComments] =  React.useState([]);

  function filterByReadComments(inverted=false) {
    if (!readComments && readComments.length < 1) return symbolComments;
    return symbolComments.filter( (comment) => {
      if (hasReadComment(comment)) return inverted;
      return !inverted;
    });
  };

  function hasReadComment(comment) {
    let readComment = readComments.find(({id}) => id === comment.id);
    let diff = new Date(readComment.ts * 1000) - new Date(comment._ts * 1000);
    console.log("Have read?", {comment, readComment, diff});
    if (!readComment) return false;
    return ((readComment.ts - comment._ts) > 0);
  };

  const getSymbolComments = React.useCallback( (signal) => {
    if (accessToken && symbol && symbol !== "") {
      if (isMockingData) {
        setSymbolComments(mockSymbolComments);
        return;
      };
      fetch(
        THF_SERVER_URL+"comments?access_token="+accessToken+"&object_type=symbol&object_id="+symbol,
        {signal: signal}
      ).then( (res) => res.json() ).then( (data) => {
        setSymbolComments(data);
        getReadComments(signal);
        if (setLoading) setLoading(1);
      }).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (SymbolCommentsHook)", {error});
        if (setDisplayError) setDisplayError("Unable to fetch symbol comments. Please try again later.");
      }).finally( () => {
      });
    };
  }, [accessToken, symbol]);

  const getReadComments = React.useCallback( (signal=null) => {
    if (accessToken && symbol && symbol !== "") {
      fetch(
        THF_SERVER_URL+"comments/read?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => res.json() ).then( (data) => {
        var haveReads = {}
        data.forEach((obj) => {
          if (!Object.keys(haveReads).includes(obj.comment_id)) haveReads[obj.comment_id] = [];
          haveReads[obj.comment_id].push(obj._ts);
        });
        let justRecentlyRead = [];
        haveReads.forEach( (commentAuthorId, timestamps) => {
          timestamps.sort();
          justRecentlyRead.push({id: commentAuthorId, ts: timestamps[0]})
        });
        setReadComments(justRecentlyRead);
        if (setLoading) setLoading(0);
      }).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (SymbolCommentsHook)", {error});
        if (setDisplayError) setDisplayError("Unable to fetch symbol comments. Please try again later.");
      }).finally( () => {
      });
    };
  }, [accessToken, symbol]);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setSymbolComments([]);
    setReadComments([]);
    if (accessToken && symbol && symbol !== "") {
      if (setLoading) setLoading(2);
      getSymbolComments(signal);
    };
    return () => {
      controller.abort();
    };
  }, [accessToken, symbol, getSymbolComments]);

  const handleCommentSave = ({text}) => {
    if (saving) return;
    setSaving(true);
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(THF_SERVER_URL+'comments',
      {// request options
        method: 'POST',
        body: JSON.stringify({
          commenter: userInfo.name,
          text: text,
          object_type: 'symbol',
          object_id: symbol,
          access_token: accessToken,
          signal: signal,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    ).then( (res) => res.json().then( (data) => {
      getSymbolComments(signal);
    })).catch( (error) => {
      if (error.name === 'AbortError') return;
      console.error("Error: (SymbolCommentsHook)", {error});
      if (setDisplayError) setDisplayError("Unable to communicate with server. Please try again later.");
    }).finally( () => {
    });
  };

  const handleMarkCommentAsRead = ({msgId}) => {
    console.log("marking message as read", {msgId});
    if (accessToken && msgId && msgId !== "") {
      if (readingComment) return reading;
      setReadingComment(msgId);
      const controller = new AbortController();
      const signal = controller.signal;
      fetch(THF_SERVER_URL+"comments/read/"+msgId,{
        method: 'POST',
        body: JSON.stringify({
          access_token: accessToken,
          signal: signal,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      }).then( (res) => res.json() ).then( (data) => {
        console.log("message marked read:", {msgId, data});
        getReadComments();
        if (setLoading) setLoading(0);
      }).catch( (error) => {
        if (error.name === 'AbortError') return;
        console.error("Error: (SymbolComments) message read flag.", error);
      }).finally( () => {
        setReadingComment(null);
      });
    };
  };

  return { symbolComments, filterByReadComments, handleCommentSave, handleMarkCommentAsRead, readComments, readingComment, hasReadComment };
};
