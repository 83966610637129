import { THF_SERVER_URL, downloadCsv } from './util';

function buildCsvString(keys, data) {
  if (!keys) {
    keys = Object.keys(data[Object.keys(data)[0]][0]).sort();
  };
  let csvString = "Symbol," + keys.join(',');
  Object.keys(data).forEach( (sym) => {
    data[sym].forEach( (row) => {
      let rowArr = [sym];
      keys.forEach( (col_key) => {
        rowArr.push(row[col_key]);
      });
      csvString += ("\n" + rowArr.join(","));
    });
  });
  return csvString;
};

function processErrorReport(piece, error, setDisplayError) {
  if (error.name === 'AbortError') return;
  console.error("Error: (DataMarketPlacePage)", {error});
  if (setDisplayError) setDisplayError("Unable to download "+piece+". Please try again later.");
};

export const downloadHistoricalHurst = (accessToken, offering_id, symbols, cb, setDisplayError=null) => {
  fetch(THF_SERVER_URL+'historical_hurst_exponents?access_token='+accessToken+'&symbols='+symbols.join(','), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then( (res) => {
    if (res.ok) {
      res.json().then( (data) => {
        downloadCsv(offering_id, buildCsvString(['calculatedAt','hurst_exponent'], data));
        cb();
      });
    } else {
      throw new Error("Unable to download historical Hurst Exponents.");
    };
  }).catch( (error) => {
    processErrorReport("Historical Hurst Exponents", error, setDisplayError);
  }).finally( () => {
  });
};

export const downloadHistoricalOptionPricings = (accessToken, symbols, cb, setDisplayError=null) => {
  const getSymbolFn = (i) => {
    fetch(THF_SERVER_URL+'historical-option-pricings/'+symbols[i]+'?access_token='+accessToken, {
      method: 'GET',
    }).then( (res) => {
      if (res.ok) {
        res.text().then( (data) => {
          downloadCsv(symbols[i], data);
          i += 1;
          if (i < symbols.length) {
            getSymbolFn(i);
          } else {// is the last symbol to be fetched
            cb();
          };
        });
      } else {
        throw new Error("Unable to download historical Option Pricings.");
      };
    }).catch( (error) => {
      processErrorReport("Historical Option Pricings", error, setDisplayError);
    }).finally( () => {
    });
  };
  getSymbolFn(0); // start the fetching chain
};

export const downloadHistoricalVol = (accessToken, offering_id, symbols, cb, setDisplayError=null) => {
  let data = {};
  const getSymbolFn = (i) => {
    fetch(THF_SERVER_URL+'historical-volatility/'+symbols[i]+'/all?access_token='+accessToken, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then( (res) => {
      if (res.ok) {
        res.json().then( (history) => {
          data[symbols[i]] = history;
          i += 1;
          if ( i < symbols.length) {
            getSymbolFn(i); 
          } else {// is the last symbol to be fetched
            downloadCsv(offering_id, buildCsvString(null, data));
            cb();
          };
        });
      } else {
        throw new Error("Unable to download Historical Volatility.");
      };
    }).catch( (error) => {
      processErrorReport("Historical Volatility", error, setDisplayError);
    }).finally( () => {
    });
  };
  getSymbolFn(0); // start the fetching chain
};

export const downloadHistoricalCalibratedForecasts = (accessToken, offering_id, symbols, cb, setDisplayError=null) => {
  let data = {};
  const getSymbolFn = (i) => {
    fetch(THF_SERVER_URL+'historical-rho-and-spotv/'+symbols[i]+'?access_token='+accessToken, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then( (res) => {
      if (res.ok) {
        res.json().then( (history) => {
          data[symbols[i]] = history;
          i += 1;
          if ( i < symbols.length) {
            getSymbolFn(i);
          } else {// is the last symbol to be fetched
            downloadCsv(offering_id, buildCsvString(null, data));
            cb();
          };
        });
      } else {
        throw new Error("Unable to download historical Calibrated Forecasts.");
      };
    }).catch( (error) => {
      processErrorReport("Historical Calibrated Forecasts", error, setDisplayError);
    }).finally( () => {
    });
  };
  getSymbolFn(0); // start the fetching chain
};
