import React from 'react';
import { Button, Icon, Dropdown, Popup, Header, Label } from 'semantic-ui-react';

import { THF_SERVER_URL, DEFAULT_SYMBOL } from '../util';
import isMockingData, { mockManagedSymbols } from '../MockService/inPlace';
import { ListGrouped } from '../Components/ListTypes';

export default function useManagedSymbols({accessToken, setDisplayError, setLoading, permissions = null}, sorted = false) {
  const [managedSymbols, setManagedSymbols] = React.useState([]);
  const [favoritesList, setFavoritesList] = React.useState([]);
  const [onlyFavorites, setOnlyFavorites] = React.useState(null);

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken) {
      if (isMockingData) {
        setManagedSymbols(mockManagedSymbols);
        return;
      };
      if (setLoading) setLoading(true);
      fetch(THF_SERVER_URL+"managed-symbols?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => res.json().then( (list) => {
        if (sorted) {
          const sortedList = list.map( (symbolObj)=>{
            return {
              key: symbolObj.symbol,
              value: symbolObj.symbol,
              title: symbolObj.tradingClass, // required for '<Search />' usage
              text: symbolObj.description || symbolObj.symbol,
              data: symbolObj
            };
          }).sort((a, b) => a.key === DEFAULT_SYMBOL ? -1 : (b.key === DEFAULT_SYMBOL ? 1 : 0));
          setManagedSymbols(sortedList);
          if (permissions) setFavoritesList(sortFavoritesFirst(sortedList, permissions));
        } else {
          setManagedSymbols(list);
        };
        if (setLoading) setLoading(false);
      })).catch( (error) => { 
        if (error.name === 'AbortError') return;
        console.error("Error: (ManagedSymbolsHook)", {error});
        if (setDisplayError) setDisplayError("Unable to communicate with server. Please try again later.");
      }).finally( () => {
      });
    };
    return () => controller.abort();
  }, [accessToken]);

  const saveNewManagedSymbol = React.useCallback((newSymbol, setShowNew) => {
    if (sorted) {
      console.log("ManagedSymbols hook is set to sort its contents.");
      return;
    };
    fetch(THF_SERVER_URL+'managed-symbols',
      {// request options
        method: 'POST',
        body: JSON.stringify({...newSymbol, access_token: accessToken}),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    ).then( (res) => res.json().then( (data) => {
      const existingIndex = managedSymbols.findIndex((f) => f.id === data.id);
      if (existingIndex >= 0) {
        managedSymbols[existingIndex] = data;
        setManagedSymbols([...managedSymbols]);
      } else {
        setManagedSymbols([...managedSymbols, data]);
      };
      if (setShowNew) setShowNew(false);
    })).catch( (error) => { 
      if (error.name === 'AbortError') return;
      //console.error("Error: (ManagedSymbolsHook)", {error});
      if (setDisplayError) setDisplayError("Unable to communicate with server. Please try again later.");
    }).finally( () => {
    });
  }, [accessToken, managedSymbols]);

  const removeManagedSymbol = React.useCallback((symbol) => {
    if (sorted) {
      console.log("ManagedSymbols hook is set to sort its contents.");
      return;
    };
    fetch(THF_SERVER_URL+'managed-symbols',
      {// request options
        method: 'DELETE',
        body: JSON.stringify({
          id: symbol.id,
          symbol: symbol.symbol,
          access_token: accessToken,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    ).then( (res) => res.json().then( (data) => {
      const existingIndex = managedSymbols.findIndex((f) => f.id === symbol.id);
      if (existingIndex >= 0) {
        managedSymbols.splice(existingIndex, 1);
        setManagedSymbols([...managedSymbols]);
      };
    })).catch( (error) => { 
      if (error.name === 'AbortError') return;
      //console.error("Error: (ManagedSymbolsHook)", {error});
      if (setDisplayError) setDisplayError("Unable to communicate with server. Please try again later.");
    }).finally( () => {
    });
  }, [accessToken, managedSymbols]);

  const markAsFavoriteSymbol = (permissions, updateUserPermissions, symbolKey) => {
    let updatedFavorites = permissions.favorites || [];
    if (!updatedFavorites.includes(symbolKey)) {
      updatedFavorites.push(symbolKey);
      updateUserPermissions("favorites", updatedFavorites);
    };
  };

  const removeFavoriteSymbol = (permissions, updateUserPermissions, symbolKey) => {
    let updatedFavorites = permissions.favorites || [];
    if (updatedFavorites.includes(symbolKey)) {
      updatedFavorites = updatedFavorites.filter(function(value, index, arr){ 
        return value !== symbolKey;
      });
      updateUserPermissions("favorites", updatedFavorites);
    };
  };

  const symbolIsFavorite = (permissions, symbolKey) => {
    if (!permissions || !permissions.favorites) return false;
    return (permissions.favorites.includes(symbolKey))
  };

  function toggleSymbolFavorite(permissions, updateUserPermissions, symbolKey) {
    if (!permissions || !permissions.favorites) return false;
    if (symbolIsFavorite(permissions, symbolKey)) {
      removeFavoriteSymbol(permissions, updateUserPermissions, symbolKey);
      return false;
    } else {
      markAsFavoriteSymbol(permissions, updateUserPermissions, symbolKey);
      return true;
    };
  };

  function sortFavoritesFirst(sortedList, permissions, updateFavorites = false) {
    if (!sorted) return sortedList;
    var justFavorites = [];
    let newList = sortedList.map( function(symbolObj) {
      let isFav = symbolIsFavorite(permissions, symbolObj.key);
      if (isFav) justFavorites.push(symbolObj);
      return {...symbolObj,
        favorite: (isFav ? 1 : 0),
        content: (<Label>
          <Icon name={(isFav ? 'favorite' : '')} color={isFav ? "yellow" : "grey"}/>
          {symbolObj.text}
        </Label>),
      };
    });
    setOnlyFavorites(justFavorites);
    newList.sort( function(a,b) {
      return (b.favorite - a.favorite);
    });
    if (updateFavorites) setFavoritesList(newList);
    return newList;
  };

  function getSymbolData(symbolKey) {
    const matchingSymbol = managedSymbols.find((s)=> s.key === symbolKey);
    return (matchingSymbol ? matchingSymbol.data : null);
  };

  return { managedSymbols, saveNewManagedSymbol, removeManagedSymbol, sortFavoritesFirst, getSymbolData,
    favoritesList, markAsFavoriteSymbol, removeFavoriteSymbol, toggleSymbolFavorite, symbolIsFavorite, onlyFavorites
  };
};

export const SymbolFavoriteList = ({selectedItem, onSelection, permissions, updateUserPermissions, accessToken}) => {
  const [isSpecial, setIsSpecial] = React.useState(false);
  
  const { managedSymbols, favoritesList, toggleSymbolFavorite, sortFavoritesFirst, getSymbolData } = useManagedSymbols({accessToken, permissions}, true);
  
  React.useEffect(() => {
    if (!permissions) return;
    setIsSpecial(permissions.favorites.includes(selectedItem));
    sortFavoritesFirst(managedSymbols, permissions, true);
  }, [selectedItem, permissions]);

  const InteractIcon = () => {
    return (<Popup hideOnScroll size='small' inverted trigger={
      <Button onClick={() => {
        const isFavNow = toggleSymbolFavorite(permissions, updateUserPermissions, selectedItem);
        setIsSpecial(isFavNow);
      }}>
        <Icon color={isSpecial ? "yellow" : "grey"} fitted name='favorite'/>
      </Button>
    }>
      <Popup.Content>{(isSpecial ? "Unf" : "F")+"avorite this symbol"}</Popup.Content>
    </Popup>);
  };
  return (<React.Fragment>
    <Header.Content>
      <InteractIcon />
      <Dropdown style={{width:'320px'}} search selection placeholder="Select your symbol" options={favoritesList} 
        value={selectedItem} onChange={(e, { value }) => {
          onSelection(value, getSymbolData(value));
        }}
      />
    </Header.Content>
  </React.Fragment>);
};

export const FavoritesWatched = ({permissions, accessToken, onSelection}) => {
  const { onlyFavorites } = useManagedSymbols({accessToken, permissions}, true);
  const [hasFavorites, setHasFavorites] = React.useState(false);

  React.useEffect(() => {
    if (!onlyFavorites) setHasFavorites(false);
    else setHasFavorites(onlyFavorites.length > 0);
  }, [onlyFavorites]);

  return (<div style={{maxWidth:'1000px'}}>
    {!hasFavorites && <p>Fetching your watched symbols list...</p>}
    
    {hasFavorites && <ListGrouped icon="info" managedItems={onlyFavorites} onSelection={(symbolKey) => {
      onSelection(symbolKey);
    }}/>}

    {hasFavorites && onlyFavorites.length < 1 && <p>You have no favorite symbols being watched, star some from the tickers page.</p>}
  </div>);
};
