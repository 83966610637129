import React from 'react';

import { THF_SERVER_URL, zero_pad, getTimeStringDateFormat, RIGHTS, sleep } from '../util';

let today = new Date();
let DATES = [];
for (let i = 0; i<31; i+=1) {
  if ([1, 2, 3, 4, 5].includes(today.getDay())) { // only check weekdays
    DATES.push([today.getFullYear(), zero_pad(today.getMonth() + 1), zero_pad(today.getDate())].join(""));
  };
  today.setDate(today.getDate() + 1);
};

let refetchTimeout = null;
var lastSymbol = null;

export default function useOptionPricings({
  accessToken, symbol, live=true, showOldContracts=false, setLoading=undefined, fetchDelay=0, setDisplayError=undefined
}) {
  lastSymbol = symbol;
  const [heatmapRefetchCount, setHeatmapRefetchCount] = React.useState(0);
  const [heatmapError, setHeatmapError] = React.useState(null);

  const [modelError, setModelError] = React.useState(null);
  const [displayPuts, setDisplayPuts] = React.useState({});
  const [displayCalls, setDisplayCalls] = React.useState({});

  function clearOptionPricingRefetch(everything=false) {
    if (everything) {
      setHeatmapRefetchCount(0);
      setDisplayCalls({});
      setDisplayPuts({});
      setModelError(null);
    };
    if (refetchTimeout) {
      window.clearTimeout(refetchTimeout);
      refetchTimeout = null;
    };
  };

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      if (accessToken && symbol !== "") {
        clearOptionPricingRefetch();
        if (fetchDelay > 0) {
          if (setLoading) setLoading(true);
          await sleep(fetchDelay*1000);
        } else if (symbol !== lastSymbol) {
          lastSymbol = symbol;
          if (setLoading) setLoading(true);
        };
        fetch(
          THF_SERVER_URL+"option-pricings/"+symbol+"/"+DATES.join(",")+"?access_token="+accessToken, 
          {signal: signal}
        ).then( (res) => res.json().then( (allData) => {
          setModelError(allData.model_error);
          setHeatmapError(null);
          if (setDisplayError) setDisplayError(null);
          DATES.forEach( (date) => {
            const data = allData[date];
            RIGHTS.forEach( (right) => {
              let newData = {};
              data[right].forEach( (contract) => {
                const dateObj = new Date(contract.calculatedAt * 1000);
                if (!showOldContracts && (new Date()).toDateString() !== dateObj.toDateString()) {
                  return; // this contract pricing was not calculated today so... skip it
                };
                newData[contract.strike] = {...contract, 
                  diff_THF: (contract.market_price - contract.thf_price) / contract.market_price,
                  diff_market: (contract.thf_price - contract.market_price) / contract.thf_price,
                  date: dateObj,
                };
              });
              if (Object.keys(newData).length === 0) {
                newData = true;
                DATES = DATES.filter(d => d !== date); // remove dates from the list that don't have data attached, so we have fewer cache misses
              };
              if (right === "calls") {
                setDisplayCalls((d) => ({...d, [date] : newData}));
                //console.log("heatmap calls", {date, newData});
              } else {
                setDisplayPuts((d) => ({...d, [date] : newData}));
                //console.log("heatmap puts", {date, newData});
              };
            });
          });
          if (setLoading) setLoading(false);
        })).catch( (error) => {
          if (error.name === 'AbortError') return;
          //console.error("Error: (Heatmap) component.", error);
          setHeatmapError("Heatmap refresh failed, auto-retrying in 30 seconds.");
          if (setDisplayError) setDisplayError("Unavailable at this time, please try later.");
        }).finally( () => {
          if (live && refetchTimeout == null) {
            refetchTimeout = window.setTimeout( () => {
              setHeatmapRefetchCount(heatmapRefetchCount + 1);
            }, 30 * 1000 );
          };
        });
      };
    })();
    return () => controller.abort();
  }, [accessToken, symbol, showOldContracts, heatmapRefetchCount]);

  const optionPricingsCsvString = React.useCallback( () => {
    let csv = '';
    //add header row
    csv += "Right,Strike,Expiration,Market Price,THF Price,Market Delta,THF Delta,THF Price Diff,Market Price Diff,Calculated At\n";
    //add data
    [displayPuts,displayCalls].forEach( (chunk,i) => {
      Object.keys(chunk).forEach( expiration => {
        if (chunk[expiration] === true) return null;
        const strikes = Object.keys(chunk[expiration]);
        strikes.forEach( strike => {
          const contract = chunk[expiration][strike];
          csv += (i === 0 ? 'Put,' : 'Call,');
          csv += ""+strike+",";
          let expirationDate = getTimeStringDateFormat({stringDate:expiration, withDay:true});
          expirationDate = (expirationDate ? expirationDate : 'Err');
          csv += ""+expirationDate+",";
          csv += ""+contract.market_price.toFixed(2)+",";
          csv += ""+contract.thf_price.toFixed(2)+",";
          csv += ""+(contract.market_delta || 0).toFixed(4)+",";
          csv += ""+(contract.thf_delta || 0).toFixed(4)+",";
          csv += ""+((contract.diff_THF*100).toFixed(4))+"%,";
          csv += ""+((contract.diff_market*100).toFixed(4))+"%,";
          csv += ""+contract.date.toLocaleTimeString()+"\n";
        });
      });
    });
    return csv;
  }, [displayPuts, displayCalls]);

  return { displayPuts, displayCalls, heatmapError, modelError, clearOptionPricingRefetch, optionPricingsCsvString };
};
