import React from 'react';
import { THF_SERVER_URL } from '../util';

const MarkedTrades = ({accessToken}) => {
  const [loading, setLoading] = React.useState(true);
  const [trades, setTrades] = React.useState(null);
  React.useEffect( () => {
    if (accessToken) {
      setLoading(true);
      fetch(
        THF_SERVER_URL+"marked-trades?access_token="+accessToken,
      ).then( (res) => res.json().then( (details) => {
        setTrades(details);
      })).finally( () => {
        setLoading(false);
      });
    };
  }, [accessToken])

  if (loading || trades === null) return <p>Loading...</p>;

  return <div>
    <p>Marked Trades</p>
    <ul>
      {trades.map(t => {
        return <li key={t.id}>{t.contract} {t.action === 'buy' ? 'bought' : 'sold'} at ${t.entry_price}</li>
      })}
    </ul>
  </div>;
}

export default MarkedTrades;
