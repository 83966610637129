import React from 'react';
import { Button, Header, Checkbox, Image } from 'semantic-ui-react';
import { useNavigate } from "react-router-dom";

import LoadingProgressShown from '../Components/LoadingProgressShown';
import ConfigurePayments from '../Components/ConfigurePayments';
import BackendStatus from '../Components/BackendStatus';
import { UserName } from '../Components/UserDisplay';
import { FavoritesWatched } from '../Hooks/ManagedSymbols';

import Top10Page from './Top10Page'

const HomePage = ({userInfo, authState, permissions, updateUserPermissions, accessToken}) => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = React.useState(false);
  
  let showingTop10 = (permissions && permissions.preferredViews.home && permissions.preferredViews.home.showTop10);
  const [showTopTen, setShowTopTen] = React.useState(showingTop10);

  React.useEffect(() => {
    if (permissions) {
      setIsAdmin(permissions.admin)
      if (permissions.preferredViews.home) {
        setShowTopTen(permissions.preferredViews.home.showTop10);
      };
    } else {
      setIsAdmin(false);
    };
  }, [permissions]);

  /* Loading, wait here. */
  if (!authState || authState.isPending) return <LoadingProgressShown type='spinner' />

  if (authState && authState.isAuthenticated && userInfo && permissions && !permissions.valid_subscription) {
    return <ConfigurePayments userInfo={userInfo} accessToken={authState.accessToken} permissions={permissions}/>;
  };

  const IsNotSignedIn = () => {
    return (<div>
      <p>please sign-in to the portal.</p>
      <div>
        <Button id="login-button" primary onClick={() => navigate("/login")}>Login</Button>
      </div>
      <div style={{position:'absolute',bottom:0,right:0,height:24}}>
        <button style={{position:'absolute',right:0,height:32,width:32}} onClick={() => navigate("/aigame")}><Image src="images/rook.jpg"/></button>
      </div>
    </div>);
  };

  const IsLoggedIn = () => {
    return (<div>
      {userInfo && <div style={{height: "60px"}}>
        <UserName message="Glad to have you back, %user%!" userInfo={userInfo} />
      </div>}

      <div className="welcomeTopMenus">
        <div align="left" className="welcomeTopLeft">
          <Checkbox label="Top 10" toggle checked={showTopTen} onChange={() => {
            var updatedPreferredViews = permissions.preferredViews;
            if (!updatedPreferredViews.home) updatedPreferredViews.home = {};
            updatedPreferredViews.home.showTop10 = !showTopTen;
            updateUserPermissions('preferredViews', updatedPreferredViews);
            setShowTopTen(!showTopTen);
          }}/>
        </div>

        <div align="right" className="welcomeTopRight">
          {isAdmin && <BackendStatus accessToken={accessToken}/>}
        </div>
      </div>
      <hr className="title"/>

      {showTopTen && <div className="tickerOverviewSection">
        <h2>Top 10</h2>
        <Top10Page accessToken={accessToken}/>
        <hr className="chapter"/>
      </div>}

      <div>
        <h3>Watched Symbols</h3>
        <div align="center">
          <FavoritesWatched accessToken={accessToken} permissions={permissions} onSelection={(symbolKey) => {
            navigate("symbol_tickers?symbol="+symbolKey);
          }}/>
        </div>
        <hr className="chapter"/>
      </div>

      {/*<div>
        <Button circular size='medium' color='blue' onClick={() => navigate("/aigame")}>AI Demo</Button>
      </div>*/}
    </div>);
  };

  return (<div className="pageContent">
    <Header as="h1">Welcome!</Header>

    {authState && authState.isAuthenticated && !userInfo && (
      <div>Loading user information...</div>
    )}

    {(!authState || !authState.isAuthenticated) ? <IsNotSignedIn /> : <IsLoggedIn />}

  </div>);
};

export default HomePage;
