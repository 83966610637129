import React from 'react';

const BugReport = () => {
  return <p>please report bugs <a href="https://github.com/texashighfrequency/issue-tracker/issues/new">here</a><br/>
    your rep is: Robert Douglas<br/>
    <a href="mailto:robert@thf.ai?subject=THF%2C%20I%20found%20a%20bug%2E">robert@thf.ai</a><br/>
    <a href="tel:16179396295">+1 617 939 6295</a>
  </p>
}

export default BugReport;
