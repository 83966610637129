import React from 'react';

import { floatPadded } from '../util';
import useCopyToClipboard from '../Hooks/CopyToClipboardHook';
import HoverToolTip, { TimedTip } from '../Components/HoverToolTip';

const FloatClickToCopy = ({value, message="", decimalPlaces=2, substituteZero=undefined, fullValue=false}) => {
  const { clipboardCopy } = useCopyToClipboard();
  var valueDisplayString = null;
  if (!fullValue) valueDisplayString = floatPadded(value, decimalPlaces, substituteZero);
  else valueDisplayString = value;

  let displayText = valueDisplayString;
  if (message != "") {
    let impregnateMessage = message.split("%value%");
    displayText = impregnateMessage[0]+valueDisplayString+impregnateMessage[1];
  };

  return (<TimedTip tipContent="Copied!" label={<div>
    <HoverToolTip label={<button onClick={() => clipboardCopy(value)}>{displayText}</button>} contents={value} />
  </div>}/>);
};
export default FloatClickToCopy;
