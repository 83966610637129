import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { UserName } from '../Components/UserDisplay';

const ErrorPage = ({authState, userInfo, permissions}) => {
  const [urlParams, setUrlParams] = useSearchParams();
  function grabCode() {
    return urlParams.get('code');
  };
  const [displayCode, setDisplayCode] = React.useState(grabCode());

  React.useEffect( () => {
    const timer = setTimeout(() => {
      setDisplayCode(grabCode());
    }, 1000*80);
    return () => clearTimeout(timer);
  }, []);

  return (<div className="pageContent">
    <h4>Error: {displayCode}</h4>
    <p>Server systems are down at the moment, please try again later.</p>
    <br/>
    <UserName message="Apologies for the inconvenience, %user%" userInfo={userInfo} />
  </div>);
};
export default ErrorPage;
