import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_SYMBOL } from '../util';
import useManagedSymbols from '../Hooks/ManagedSymbols';

import { SymbolFavoriteList } from '../Hooks/ManagedSymbols';
import ProbabilityZonesChart, { HelperProbabilityZones } from '../Components/ProbabilityZonesChart';

const ProbabilityZones = ({accessToken, permissions, updateUserPermissions}) => {
  const { managedSymbols } = useManagedSymbols({accessToken, setLoading, setDisplayError}, true);

  const [search, setSearch] = useSearchParams();
  function grabUrlSymbol() {
    let sym = search.get('symbol');
    if (!sym && managedSymbols[0]) {
      sym = managedSymbols[0].key;
    };
    if (!sym) sym = DEFAULT_SYMBOL;
    return sym;
  };
  
  const [displaySymbol, setDisplaySymbol] = React.useState(grabUrlSymbol());
  const setProbabilitySymbol = (value) => {
		setSearch({symbol: value});
		setDisplaySymbol(value);
	};

  return (<div className="pageContent">
    <div alight="right" style={{width:'fit-content'}}>
      <SymbolFavoriteList accessToken={accessToken} permissions={permissions} updateUserPermissions={updateUserPermissions} 
        selectedItem={displaySymbol} onSelection={(value) => setProbabilitySymbol(value)} 
      />
    </div>
    <HelperProbabilityZones align="left" permissions={permissions} updateUserPermissions={updateUserPermissions} />
    <ProbabilityZonesChart symbol={displaySymbol} accessToken={accessToken} />
  </div>);
};
export default ProbabilityZones;
