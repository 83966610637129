import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { THF_SERVER_URL, LIST_TYPES, sleep, TimeSince, getTimeStringDateFormat } from '../util';

import LoadingProgressShown from '../Components/LoadingProgressShown';


const Top10Page = ({accessToken}) => {
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(null);

  const [showOldContracts, setShowOldContracts] = React.useState(false);
  const [showFullTime, setShowFullTime] = React.useState(false);
  const [showListAsText, setShowListAsText] = React.useState(false);

  const [topTenBuy, setTopTenBuy]   = React.useState([]);
  const [topTenSell, setTopTenSell] = React.useState([]);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken) {
      let loadJobs = 2;
      setDisplayError(null);
      setLoading(true);

      const updateResults = (typeIndex, list) => {
        const key = LIST_TYPES[typeIndex].key;
        if (key === "buy") {
          setTopTenBuy(list);
        } else if (key === "sell") {
          setTopTenSell(list);
        };
        loadJobs--;
        if (loadJobs <= 0) setLoading(false);
      };

      const fetchListType = async (index) => {
        const showOldSnippet = showOldContracts ? "" : "today=true&";
        await fetch(
          THF_SERVER_URL+"top10/"+LIST_TYPES[index].key+"?"+showOldSnippet+"access_token="+accessToken,
          {signal: signal}
        ).then( (res) => res.json().then( (list) => {
          updateResults(index, list);
        })).catch( (error) => { 
          if (error.name === 'AbortError') return;
          //console.error("Error: (Top10Page)", index, error);
          setDisplayError("Unable to communicate with server. Please try again later.");
        }).finally( () => {
        });
      };

      const populateData = async () => {
        await fetchListType(0);
        await sleep(2000);
        await fetchListType(1);
      };
      populateData();
    };
    return () => controller.abort();
  }, [accessToken, showOldContracts]);

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='text' text='Loading... (may take a few minutes)'/>}
  </div>);

  const CalculationTime = ({atDate}) => {
    if (atDate == null) return (<div />);
    return ((!showFullTime) ? (
      <TimeSince fromDate={atDate} />
    ) : (
      <div>calculated at: {atDate.toString()}</div>
    ));
  };
  
  const SymbolExternalLink = ({contract}) => {
    let contractRefSelection = contract.contract+"-"+contract.contract_type+"s";
    return (<Link to={"symbol_tickers?symbol="+contract.symbol+"&contract="+contractRefSelection}>
      {contract.symbol}&nbsp;&nbsp;&nbsp;
    </Link>);
  };

  const ContractsDisplay = ({list, label}) => {
    return (<div style={{maxWidth:(showListAsText ? '320px' : '536px'), marginInline:'8px', marginInlineEnd:'32px', marginBlockEnd:'24px'}}>
      <h3>{showOldContracts ? 'Recent' : "Today's"} Top 10 {label}</h3>
      {(list.length === 0) ? (
        <p>Unavailable at this time,<br/>please try later.</p>
      ) : (<div>
        {showListAsText ? (
          <ol>
            {list.map((contract) => {
              return <li key={contract.id}>
                <div style={{marginBottom: 24}}>
                  <div>
                    <SymbolExternalLink contract={contract} />
                    {contract.expiration_date} {contract.contract_type} at {contract.strike} strike
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <span style={{width: 200}}>market: ${contract.market_price.toFixed(2)}</span>
                    <span style={{width: 60}}>vs</span>
                    <span style={{width: 160}}>thf: ${contract.thf_price.toFixed(2)}</span>
                  </div>
                  <CalculationTime atDate={new Date(contract.calculatedAt * 1000)} />
                </div>
              </li>
            })}
          </ol>
        ) : (
          <table className="even-cells top10s no-scroll">
            <thead><tr>
              <th>{label}</th>
              <th>DATE</th>
              <th>STRIKE</th>
              <th>Market</th>
              <th>vs. THF</th>
              <th>AGE</th>
            </tr></thead>
            <tbody>{list.map((contract) => {
              return (<tr key={contract.id}>
                <td><SymbolExternalLink contract={contract} /></td>
                <td>{getTimeStringDateFormat({stringDate:contract.expiration_date, withDay:true, shorthand:true})}</td>
                <td>{contract.strike}</td>
                <td>${contract.market_price.toFixed(2)}</td>
                <td>${contract.thf_price.toFixed(2)}</td>
                <td><TimeSince fromDate={new Date(contract.calculatedAt * 1000)} /></td>
              </tr>)
            })}</tbody>
          </table>
        )}
      </div>)}
    </div>);
  };

  return (<div style={{display:'contents', justifyContent:'center'}}>
    <div style={{display:'block', width:'fit-content'}} align='left'>
      <ul className="plain-left-list">
        <li>
          <Checkbox label="Show old contracts" toggle onChange={() => setShowOldContracts(!showOldContracts)} checked={showOldContracts}/>
        </li>
        <li>
          <Checkbox label="Display as plain text" toggle onChange={() => setShowListAsText(!showListAsText)} checked={showListAsText}/>
        </li>
        {showListAsText && <li>
          <Checkbox label="Show full calculation time" toggle onChange={() => setShowFullTime(!showFullTime)} checked={showFullTime}/>
        </li>}
      </ul>
    </div>

    <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', marginTop:'38px'}}>
      <ContractsDisplay label="SELLS" list={topTenSell} />
      <ContractsDisplay label="BUYS" list={topTenBuy} />
    </div>
  </div>);
};
export default Top10Page;
