import React from 'react';
import { Navigate } from 'react-router-dom';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import { DefaultOktaLogin } from '../Components/OktaWidgets';

const LoginPage = ({ oktaAuth, authState }) => {

  const onSuccess = async function(res) {
    if (res.status === 'SUCCESS') {
      // Add tokens to storage
      if (oktaAuth.token.isLoginRedirect()) {
        const { tokens } = await oktaAuth.token.parseFromUrl();
        oktaAuth.tokenManager.setTokens(tokens);
      } else {
        //console.log("Okta login was not a redirect.", res);
        oktaAuth.tokenManager.setTokens(res.tokens);
      };
      oktaAuth.start();
      //console.log("Okta login success.", oktaAuth.authStateManager, oktaAuth.tokenManager, res);
    } else {
      //console.log("Okta login fail.", res);
    };
  };

  const onError = function(err) {
    console.log('error logging in', err);
  };

  return (<div className="pageContent">
    {(authState && authState.isAuthenticated) ?
      ( <Navigate to='/'/> ) :
      ( <DefaultOktaLogin oktaAuth={oktaAuth} onSuccess={onSuccess} onError={onError}/> )
    }
  </div>);
};

export default LoginPage;
