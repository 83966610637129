import React from 'react';

import { THF_SERVER_URL } from '../util';

const Docs = () => {
  const [docsHtml, setDocsHtml] = React.useState('');

  React.useEffect(() => {
    fetch(THF_SERVER_URL+"public/docs")
    .then( (res) => {
      return res.text();
    }).then((t) => {
      setDocsHtml(t);
    });
  }, []);

  return <div dangerouslySetInnerHTML={{__html: docsHtml}}></div>;
};

export default Docs;
