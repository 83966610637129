import React from 'react';
import { Checkbox, Popup, Card, Image, Button } from 'semantic-ui-react';

import { THF_SERVER_URL, dateObjFromStr, getDailyHistory, zeroPaddedDateParts } from '../util';
import LoadingProgressShown from '../Components/LoadingProgressShown';

function getMaxOrMin(zones, hist, max) {
  let val = max ? 0 : 10000000;
  for (let exp of Object.keys(zones)) {
    const splits = Object.keys(zones[exp]);
    for (let split of splits) {
      const strike = zones[exp][split];
      if (strike > 0) {
        if (max && strike > val) {
          val = strike;
        };
        if (!max && strike < val) {
          val = strike;
        };
      };
    };
  };
  for (let day of hist) {
    if (max) {
      if (day.High > val) val = day.High;
    } else {
      if (day.Low < val) val = day.Low;
    };
  };
  const buffer = val * 0.01;
  return max ? (val + buffer) : (val - buffer);
};

const DAY_IN_MS = 1000*60*60*24;
const COLORS = ['#9cfc79', '#70e246', '#49ff07', '#e24646', '#ef6767', '#fca9a9'];
const CANVAS_WIDTH = 1020;
const CANVAS_HEIGHT = 600;
const Y_AXIS_MARGIN = 20;
const X_AXIS_MARGIN = 20;
const CHART_WIDTH = CANVAS_WIDTH - Y_AXIS_MARGIN;
const CHART_HEIGHT = CANVAS_HEIGHT - X_AXIS_MARGIN;
const COLOR_INDEX_MAPPING = {"0.15": 0, "0.3": 1, "0.5": 2, "0.7": 3, "0.85": 4};
let hoverableRects = [];
let allThfBoxes = [];

function computeSplitZonesPx(zones, expirations, exp, expIndex, days, barWidth, max, min, end) {
  let results = [];
  const date = dateObjFromStr(exp);
  const dayOffset = (Math.ceil(days - ((end - date) / DAY_IN_MS)) - 1);
  const x = (barWidth * dayOffset) + Y_AXIS_MARGIN;
  const daysUntilNext = expIndex === expirations.length -1 ? 3 : Math.ceil((dateObjFromStr(expirations[expIndex+1]) - date) / DAY_IN_MS);
  const rectWidth = barWidth * daysUntilNext;
  const splits = Object.keys(zones[exp]).sort();

  let pos = max;
  for (let i=0; i < splits.length; i+=1) {
    const split = splits[i];
    const strike = zones[exp][split];
    let drawHeight = Math.ceil(((pos - strike) / (max - min)) * CHART_HEIGHT);
    const drawYStart = Math.round((max - pos) / (max-min) * CHART_HEIGHT);
    if (drawHeight < 0) {
      drawHeight = CHART_HEIGHT - drawYStart;
    }

    const colorIndex = COLOR_INDEX_MAPPING[split];
    results.push({
      x,
      y: drawYStart,
      width: rectWidth,
      height: drawHeight,
      color: COLORS[colorIndex],
      colorIndex,
      topPrice: pos === max ? "infinity" : pos.toFixed(2),
      strike,
      split,
      exp,
    });

    pos = strike;
  };

  // last/bottom box is handled special because it always goes to the bottom of the chart
  const drawHeight = Math.round(((pos - min) / (max - min)) * CHART_HEIGHT);
  const drawYStart = Math.round((max - pos) / (max-min) * CHART_HEIGHT);
  results.push({
    x,
    y: drawYStart,
    width: rectWidth,
    height: drawHeight,
    color: COLORS[COLORS.length - 1],
    colorIndex: COLORS.length - 1,
    topPrice: pos === max ? "infinity" : pos.toFixed(2),
    strike: 0,
    split: 0,
    exp,
  });
  return results;
};

function drawChart(ctx, priceHistory, zones, hovering, selected) {
  let market_zones = zones.market;
  let thf_zones = zones.thf;
  // clear canvas
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
  // calculate dimensions
  const max = getMaxOrMin(market_zones, priceHistory, true);
  const min = Math.max(getMaxOrMin(market_zones, priceHistory, false), 0);
  const today = new Date();
  const expirations = Object.keys(market_zones);
  const end = dateObjFromStr(expirations[expirations.length - 1]);
  const days = Math.ceil((end - today) / DAY_IN_MS) + priceHistory.length;
  const barWidth = parseInt(CHART_WIDTH / days);
  // draw market_zones
  let hoverRect = null;
  expirations.forEach((exp, expIndex) => {
    const boxes = computeSplitZonesPx(market_zones, expirations, exp, expIndex, days, barWidth, max, min, end);
    boxes.forEach((box) => {
      ctx.fillStyle = box.color;
      ctx.fillRect(box.x, box.y, box.width, box.height);
      if (!hovering)
        hoverableRects.push(box)
      else if (hovering.strike === box.strike && hovering.exp === exp)
        hoverRect = box;
    });
  });

  if (hoverRect) {
    ctx.strokeStyle = "#000000";
    ctx.setLineDash([]);
    ctx.mozDash = [];
    ctx.strokeRect(hoverRect.x, hoverRect.y, hoverRect.width - 2, hoverRect.height);
  }
  // draw currently selected zones
  if (selected) {
    ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
    selected.forEach((box)=>{
      ctx.fillRect(box.x, box.y, box.width, box.height);
    });
  };
  
  // draw thf zones
  const thf_expirations =Object.keys(thf_zones);
  allThfBoxes = [];
  thf_expirations.forEach((exp, expIndex) => {
    const boxes = computeSplitZonesPx(thf_zones, thf_expirations, exp, expIndex, days, barWidth, max, min, end);
    ctx.fillStyle = "rgba(0, 0, 255, 0.5)";
    boxes.forEach((box) => {
      const matching = hoverableRects.filter(z => z.split+z.exp == box.split + box.exp)[0];
      if (matching && !(box.y === 0 && box.topPrice === 'infinity') ) {
        //draw the divergence zone box
        if (matching.y < box.y - 5) { //market top is above thf top by more than 5 pixels
          ctx.fillRect(box.x, matching.y, box.width, box.y - matching.y);
        }
        if (matching.y - 5 > box.y) { //market top is above thf top by more than 5 pixels
          ctx.fillRect(box.x, box.y, box.width, matching.y - box.y);
          //console.log(matching, box);
        }
      }
      allThfBoxes.push(box);
    });

  });
  ctx.strokeStyle = "blue";
  ctx.setLineDash([]);
  ctx.mozDash = [];
  ctx.beginPath();
  ctx.lineWidth = "1";

  let bySplit = {};
  allThfBoxes.forEach((box) => {
    bySplit[box.split] = bySplit[box.split] || [];
    bySplit[box.split].push(box);
  })
  Object.keys(bySplit).forEach(splitKey => {
    if (splitKey == 0) return;
    const split = bySplit[splitKey];
    ctx.beginPath();
    ctx.moveTo(split[0].x, split[0].y+split[0].height);
    for (let i = 0; i < split.length; i++) {
      const box = split[i];
      ctx.lineTo(box.x, box.y + box.height);
      ctx.lineTo(box.x + box.width - 1, box.y + box.height);
    }
    ctx.stroke();
  });
  // draw vertical line marking THF prediction stop
  ctx.setLineDash([5, 10]);
  ctx.mozDash = [5, 10];
  ctx.beginPath();
  ctx.lineWidth = "1";
  if (bySplit["0.5"]) {
    const lastTHFBox = bySplit["0.5"][bySplit["0.5"].length -1];
    ctx.moveTo(lastTHFBox.x + lastTHFBox.width, 0);
    ctx.lineTo(lastTHFBox.x + lastTHFBox.width, CHART_HEIGHT);
    ctx.stroke();
  } else {
    //console.warn("bySplit[\"0.5\"] is undefined.", {bySplit});
  };
  
  // draw priceHistory
  priceHistory.forEach((price, index) => {
    let colorIndex = 2;
    if (price.Close < price.Open) {
      colorIndex = 3;
    };
    ctx.fillStyle = COLORS[colorIndex];
    const x = (barWidth * index) + Y_AXIS_MARGIN;
    // draw open-close bar
    let topPrice = Math.max(price.Close, price.Open);
    let drawYStart = (max - topPrice) / (max-min) * CHART_HEIGHT;
    let bottomPrice = Math.min(price.Close, price.Open);
    let drawHeight = ((topPrice - bottomPrice) / (max - min)) * CHART_HEIGHT;
    ctx.fillRect(x, drawYStart, barWidth, drawHeight);
    // draw high-low lines
    topPrice = Math.max(price.High, price.Low);
    drawYStart = (max - topPrice) / (max-min) * CHART_HEIGHT;
    bottomPrice = Math.min(price.High, price.Low);
    drawHeight = ((topPrice - bottomPrice) / (max - min)) * CHART_HEIGHT;
    ctx.fillRect(x+(barWidth/2)-0.5, drawYStart, 1, drawHeight);
  });
  // set axis font styles
  ctx.fillStyle = "#000000";
  ctx.strokeStyle = "#000000";
  ctx.font = "12px Courier New";
  // draw X axis
  // top
  ctx.fillText(""+(max.toFixed(2)), 0, 12);
  // bottom
  ctx.fillText(""+(min.toFixed(2)), 0, CHART_HEIGHT - 1);
  // last quote level
  const last = priceHistory[priceHistory.length - 1].Close;
  const lastY = (max - last) / (max-min) * CHART_HEIGHT;
  ctx.fillText(""+(last.toFixed(2)), 0, lastY);
  // draw dotted line to show where we are currently
  ctx.setLineDash([5, 10]);
  ctx.mozDash = [5, 10];
  ctx.beginPath();
  ctx.lineWidth = "1";
  ctx.moveTo(Y_AXIS_MARGIN, lastY);
  const todayXVal = Y_AXIS_MARGIN + (barWidth * priceHistory.length)
  ctx.lineTo(todayXVal, lastY);
  ctx.stroke();
  // draw Y axis
  // oldest bar
  ctx.fillText(""+(priceHistory[0].Date), 0, CANVAS_HEIGHT - 1);
  // furthest projection
  const {year, month, day} = zeroPaddedDateParts(end);
  ctx.fillText(""+year+"-"+month+"-"+day, CANVAS_WIDTH - 75, CANVAS_HEIGHT - 1);
  // today
  ctx.fillText(""+(priceHistory[priceHistory.length - 1].Date), todayXVal - 40, CANVAS_HEIGHT - 1);
};

const ZonesTable =  ({zones}) => {
  if (!zones || zones.length === 0) {
    return null;
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Expiration</th>
          <th>Probability</th>
          <th>Market Zone</th>
          <th>THF Zone</th>
          <th>Top Diff</th>
          <th>Bottom Diff</th>
        </tr>
      </thead>
      <tbody>
        {zones.map((z)=>{
          const zonePercent = ["15%","15%","20%","20%","15%","15%"][z.colorIndex];
          const matching = allThfBoxes.filter(b => z.split+z.exp == b.split + b.exp)[0];
          let thfZone = "--";
          let topDiff = "--";
          let botDiff = "--";
          if (matching) {
            thfZone = "$"+matching.strike.toFixed(2)+" - $"+matching.topPrice;
            if (matching.topPrice !== 'infinity')
              topDiff = "$"+(z.topPrice - matching.topPrice).toFixed(2);
            if (matching.strike !== 0)
              botDiff = "$"+(z.strike - matching.strike).toFixed(2);
          };
          return <tr>
            <td>{z.exp}</td>
            <td>{zonePercent}</td>
            <td>${z.strike.toFixed(2)} - ${z.topPrice}</td>
            <td>{thfZone}</td>
            <td>{topDiff}</td>
            <td>{botDiff}</td>
          </tr>;
        })}
      </tbody>
    </table>
  );
}

const ProbabilityZonesChart = ({accessToken, symbol, permissions, updateUserPermissions}) => {
  const [priceHistory, setPriceHistory] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [zones, setZones] = React.useState(null);
  const [zone, setZone] = React.useState(null);
  const [selectedZones, setSelectedZones] = React.useState([]);

  // get zones
  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken && symbol) {
      setLoading(true);
      setSelectedZones([]);
      fetch(
        THF_SERVER_URL+"probability-zones/"+symbol+"?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => {
        return res.json();
      }).then( (data) => {
        let cleaned = {};
        Object.keys(data).forEach(type => {
          cleaned[type] = {};
          Object.keys(data[type]).forEach(exp => {
            cleaned[type][exp] = {};
            Object.keys(data[type][exp]).forEach( split => {
              if (data[type][exp][split] > 0) {
                cleaned[type][exp][split] = data[type][exp][split];
              };
            });
          });
        });
        setZones(cleaned);
      }).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (ProbabilityZones) page.", error);
      }).finally( () => {
        setLoading(false);
      });
      getDailyHistory(symbol, accessToken, (rows) => {
        setPriceHistory(rows);
      });
    };
    return () => controller.abort();
  }, [accessToken, symbol]);

  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    //console.log("prob canvas", {canvas});
    const ctx = canvas.getContext('2d');
    if (ctx && priceHistory && zones) {
      hoverableRects = [];
      drawChart(ctx, priceHistory, zones, false, selectedZones);
    }
  }, [priceHistory, zones, selectedZones])

  const updateSelectedZones = React.useCallback((e)=>{
    if (canvasRef && canvasRef.current) {
      var rect = canvasRef.current.getBoundingClientRect(),
          x = e.clientX - rect.left,
          y = e.clientY - rect.top,
          i = 0, r;
      while( r = hoverableRects[i++]) {
        if (r.x < x && (r.x+r.width) > x) {
          if (r.y < y && (r.y+r.height) > y) {
            if (selectedZones.map(z=> z.strike+z.exp).includes(r.strike+r.exp)) {
              const newZones = selectedZones.filter(z=> z.strike+z.exp != r.strike+r.exp);
              setSelectedZones(newZones);
            } else {
              setSelectedZones([...selectedZones, r]);
            };
            i = hoverableRects.length;
          };
        };
      };
    };
  },[selectedZones]);

  let zoneLine = null;
  if (zone) {
    const zonePercent = ["15%","30%","50%","50%","30%","15%"][zone.colorIndex];
    const directionalityWord = zone.colorIndex > 2 ? "less than" : "greater than";
    zoneLine = <h5>The probability that the price on {zone.exp} is {directionalityWord} ${zone.colorIndex > 2 ? zone.topPrice : zone.strike.toFixed(2)} is ~{zonePercent}.</h5>
  };

  return (<div align="center">
    <h5>Option Implied Probability of Future Price</h5>
    <HelperProbabilityZones align="center" permissions={permissions} updateUserPermissions={updateUserPermissions} />
    <div style={{marginBottom:'24px'}}/>

    {zoneLine}
    {loading && <LoadingProgressShown type='spinner' />}
    <canvas className="probabilityChart" ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} 
      onMouseMove={(e) => {
        if (canvasRef && canvasRef.current) {
          var rect = canvasRef.current.getBoundingClientRect(),
              x = e.clientX - rect.left,
              y = e.clientY - rect.top,
              i = 0, r;
          while( r = hoverableRects[i++]) {
            if (r.x < x && (r.x+r.width) > x) {
              if (r.y < y && (r.y+r.height) > y) {
                setZone(r);
                drawChart(canvasRef.current.getContext('2d'), priceHistory, zones, r, selectedZones);
                i = hoverableRects.length;
              };
            };
          };
        };
      }}
      onClick={updateSelectedZones}
    />
    <ZonesTable zones={selectedZones} />
  </div>);
};
export default ProbabilityZonesChart;

export const HelperProbabilityZones = ({ permissions, updateUserPermissions, position="bottom left", align="center" }) => {
  const [open, setOpen] = React.useState(false)

  return (<div align={align} >
    {((permissions && !permissions.preferredViews.probabilityZones) || !permissions.preferredViews.probabilityZones.readWhatIsProbabilityChart) ? (
      <Popup open={open} position={position} wide="very" size="large" //hideOnScroll
        trigger={<Button circular compact size='tiny' onClick={() => setOpen(!open)}>
          What am I looking at?
        </Button>}
      >
        <Popup.Header> </Popup.Header>
        <Popup.Content>
          <Card fluid style={{minWidth:'380px'}}>
            <Image src='images/probabilityZones_howto.png' />

            <p>Where the candle chart meets the future probability zones is current time (1). The transition between the green and red zone represents where market delta has the probability of a price being equally possible in either direction, or 50%. For easy identification the color lightens when there is a 30% and 15% probability as too.</p>
            <p>Hover over any zone to se the precise boundary price and probability in the text above the chart (2). Clicking on any of these known market zones will select them for review on a table below the chart, clicking again will deselect them (3).</p>
            <p>THF's prices are shown in blue for the 30% and 15% probabilities. In many cases, the difference between THF and the market is minimal, but when the divergence is large, it is a potential trading opportunity. The dashed vertical blue line is where THF's predictions stop.</p>

            <Checkbox label={<label ref={(el) => {
              if (el) el.style.setProperty('color', 'black', 'important');
            }}>close and hide button, it can be re-enabled in profile.</label>} onChange={() => {
              var updatedPreferredViews = permissions.preferredViews;
              if (!updatedPreferredViews.probabilityZones) updatedPreferredViews.probabilityZones = {};
              updatedPreferredViews.probabilityZones.readWhatIsProbabilityChart = true;
              updateUserPermissions('preferredViews', updatedPreferredViews);
              setOpen(false);
            }} />
          </Card>
        </Popup.Content>
      </Popup>
    ) : (
      <React.Fragment/>
    )}
  </div>);
};
