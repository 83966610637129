import React from 'react';
import { Checkbox, Popup, Card, Button } from 'semantic-ui-react';
import { LineChart, Line, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { THF_SERVER_URL, zeroPaddedDateParts, downloadCsv } from '../util';
import LoadingProgressShown from '../Components/LoadingProgressShown';
import '../css/components.css';

export const HistoricalNormalizedProfitChart = ({accessToken, symbol, permissions, updateUserPermissions, displayChartTitle=true}) => {
  const [loading, setLoading] = React.useState(true);
  const [displayError, setDisplayError] = React.useState(null);

  const [analysis, setAnalysis] = React.useState(null);
  const [underlying, setUnderlying] = React.useState(null);
  const [byTrades, setByTrades] = React.useState(true);
  const [showTradeDetails, setShowTradeDetails] = React.useState(false);

  React.useEffect( () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (accessToken && symbol) {
      setLoading(true);
      fetch(
        THF_SERVER_URL+"historical-option-pricings-analysis/"+symbol+"?access_token="+accessToken,
        {signal: signal}
      ).then( (res) => res.json().then( (blob) => {
        setAnalysis(blob);
      })).catch( (error) => {
        if (error.name === 'AbortError') return;
        //console.error("Error: (HistoricalNormalizedProfitChart)", error);
        setDisplayError("Unable to communicate with server. Please try again later.");
      }).finally( () => {
        setLoading(false);
      });

      fetch(
        THF_SERVER_URL+"historical-underlying-daily-prices/"+symbol+"?access_token="+accessToken
      ).then( (res) => res.text().then( (blob) => {
        const arrs = blob.split("\n").map(l => l.split(','));
        let arrOfObjects = [];
        const keys = arrs[0];
        for (let i = 1; i < arrs.length; i++) {
          arrOfObjects.push({
            [keys[0]]: arrs[i][0],
            [keys[1]]: arrs[i][1],
            [keys[2]]: arrs[i][2],
            [keys[3]]: arrs[i][3],
            [keys[4]]: arrs[i][4],
            [keys[5]]: arrs[i][5],
            [keys[6]]: arrs[i][6],
          });
        };
        //console.log(arrOfObjects);
        setUnderlying(arrOfObjects);
      }));
    };
    return () => controller.abort();
  }, [accessToken, symbol]);

  const makeCsvString = React.useCallback( () => {
    let csv = '';
    //add header row
    csv += "Date Entered,Underlying Open Price at Entry,Option Contract Traded,Trade Direction,Option Entry Price,THF Option Price at entry,Date Exited,Underlying Open Price at Exit,Option Exit Price,THF Option Price at exit,Option $ Profit per contract,Option % Profit per contract\n";
    //add data
    analysis.trades.forEach( trade => {
      //console.log(trade);
      const dp = zeroPaddedDateParts(new Date(trade.earliest_time *1000));
      const dpStr = ''+dp.year+'-'+dp.month+'-'+dp.day;
      const und = underlying.find(u => u.Date === dpStr)
      csv += ""+dpStr+",";
      csv += "$"+parseFloat(und.Open).toFixed(2)+",";
      csv += ""+trade.key+",";
      csv += ""+trade.signal+",";
      csv += "$"+trade.market[0].toFixed(2)+",";
      csv += "$"+trade.thf[0].toFixed(2)+",";

      const dpEnd = zeroPaddedDateParts(new Date(trade.latest_time *1000));
      const dpEStr = ''+dpEnd.year+'-'+dpEnd.month+'-'+dpEnd.day;
      const und2 = underlying.find(u => u.Date === dpEStr)
      csv += ""+dpEStr+",";
      csv += "$"+parseFloat(und2.Open).toFixed(2)+",";
      csv += "$"+trade.market[1].toFixed(2)+",";
      csv += "$"+trade.thf[1].toFixed(2)+",";
      csv += "$"+trade.profit.toFixed(2)+",";
      csv += ""+(trade.normalized_profit*100).toFixed(2)+"%\n";
    });
    return csv;
  }, [analysis]);

  /* Loading/Error, wait here. */
  if (loading || displayError) return (<div>
    {(displayError != null) && <p>{displayError}</p>}
    {(loading) && (displayError == null) && <LoadingProgressShown type='spinner' />}
  </div>);

  if (!analysis || !symbol) return (<div></div>);

  let analysisChartData = [];
  let monthlySums = {};
  let ongoing = 0;
  analysis.trades.sort((a,b) => {
    // should sort ascending by latest_time
    if (a.latest_time > b.latest_time) {
      return 1;
    } else if (a.latest_time < b.latest_time) {
      return -1;
    } else {
      return 0;
    };
  }).forEach( t => {
    const {month, day, monthName} = zeroPaddedDateParts(new Date(t.latest_time * 1000));
    monthlySums[monthName] = monthlySums[monthName] || 0;
    monthlySums[monthName] += t.normalized_profit;
    ongoing += t.normalized_profit;
    analysisChartData.push({
      month: monthName,
      renderer: [month, day].join('/'),
      profitPerTrade: ongoing,
      key: t.key,
    });
  });
  analysisChartData.forEach(d => {
    d.monthlyProfit = monthlySums[d.month];
  });
  if (!byTrades) {
    let matchedMonths = [];
    let filteredChartData = [];
    analysisChartData.forEach(d => {
      if (!matchedMonths.includes(d.month)) {
        matchedMonths.push(d.month);
        filteredChartData.push(d);
      };
    });
    analysisChartData = filteredChartData;
  };

  const TheoreticalTradeDetailsTable = () => {
    return (<div>
      {showTradeDetails && (<React.Fragment>
        <div align="center">
          <button onClick={() => downloadCsv("THF Theoretical Trade Details ("+symbol+")", makeCsvString())}>Export to CSV</button>
        </div>
        <table className="even-cells theoreticalTradeDetails">
          <thead><tr>
            <th>Date Entered</th>
            <th>Underlying Open Price at Entry</th>
            <th style={{width:'max-content'}}>Option Contract Traded</th>
            <th>Trade Direction</th>
            <th>Option Entry Price</th>
            <th>THF Option Price at entry</th>
            <th>Date Exited</th>
            <th>Underlying Open Price at Exit</th>
            <th>Option Exit Price</th>
            <th>THF Option Price at exit</th>
            <th>Option $ Profit per contract</th>
            <th>Option % Profit per contract</th>
          </tr></thead>

          <tbody>{analysis.trades.map( (trade) => {
            //console.log(trade);
            const dp = zeroPaddedDateParts(new Date(trade.earliest_time *1000));
            const dpStr = ''+dp.year+'-'+dp.month+'-'+dp.day;
            const und = underlying.find(u => u.Date === dpStr);

            const dpEnd = zeroPaddedDateParts(new Date(trade.latest_time *1000));
            const dpEStr = ''+dpEnd.year+'-'+dpEnd.month+'-'+dpEnd.day;
            const und2 = underlying.find(u => u.Date === dpEStr);

            return (<tr key={trade.key}>
              <td>{dpStr}</td>
              <td>${parseFloat(und.Open).toFixed(2)}</td>
              <td style={{width:'max-content'}}>{trade.key}</td>
              <td>{trade.signal}</td>
              <td>${trade.market[0].toFixed(2)}</td>
              <td>${trade.thf[0].toFixed(2)}</td>

              <td>{dpEStr}</td>
              <td>${parseFloat(und2.Open).toFixed(2)}</td>
              <td>${trade.market[1].toFixed(2)}</td>
              <td>${trade.thf[1].toFixed(2)}</td>
              <td>${trade.profit.toFixed(2)}</td>
              <td>{(trade.normalized_profit*100).toFixed(2)}%</td>
            </tr>);
          })}</tbody>
        </table>
      </React.Fragment>)}
    </div>);
  };

  return (<div>
    <div align="center">
      <HelperHistoricalNormalizedProfit permissions={permissions} updateUserPermissions={updateUserPermissions}/>
      <table className="full-cells histNormProf">
        <thead>
          <tr>
            <th>Contract Type</th>
            <th>Total Profit</th>
            <th>Sell Side Profit</th>
            <th>Buy Side Profit</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Puts</td>
            <td>{analysis.summary.puts.total.toFixed(2)}</td>
            <td>{analysis.summary.puts.sell.toFixed(2)}</td>
            <td>{analysis.summary.puts.buy.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Calls</td>
            <td>{analysis.summary.calls.total.toFixed(2)}</td>
            <td>{analysis.summary.calls.sell.toFixed(2)}</td>
            <td>{analysis.summary.calls.buy.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <div style={{padding:'8px'}}>
        <button onClick={()=>setShowTradeDetails(!showTradeDetails)}>Toggle Theoretical Trade Details</button>
        <div style={{padding:'8px'}}>
          <label>By Monthly &nbsp;</label><Checkbox label="By Trades" toggle onChange={() => setByTrades(!byTrades)} checked={byTrades}/>
        </div>
      </div>
    </div>
    
    {displayChartTitle && <h4>Historical Normalized Profit</h4>}
    <div style={{width:'100%', height:'400px', paddingBottom:'24px'}}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={800} height={400} data={analysisChartData}>
          <Legend />
          <Tooltip />
          <YAxis />
          <XAxis dataKey="renderer" />
          {!byTrades && <Line type="monotone"
            dataKey="monthlyProfit"
            stroke={analysis.summary.puts.total + analysis.summary.calls.total > 0 ? "#00ff00" : "#FF0000"}
            dot={null}
          />}
          {byTrades && <Line type="monotone"
            dataKey="profitPerTrade"
            stroke={analysis.summary.puts.total + analysis.summary.calls.total > 0 ? "#00ff00" : "#FF0000"}
            dot={null}
          />}
        </LineChart>
      </ResponsiveContainer>
    </div>

    <TheoreticalTradeDetailsTable />
  </div>);
};
export default HistoricalNormalizedProfitChart;

export const HelperHistoricalNormalizedProfit = ({ permissions, updateUserPermissions, position="bottom left", align="center" }) => {
  const [open, setOpen] = React.useState(false)

  return (<div align={align} style={{paddingBottom:'6px'}}>
    {((permissions && !permissions.preferredViews.hisNormalProfit) || !permissions.preferredViews.hisNormalProfit.readWhatIsHistoricalProfitChart) ? (
      <Popup open={open} position={position} wide="very" size="large" //hideOnScroll
        trigger={<Button circular compact size='tiny' onClick={() => setOpen(!open)}>
          What am I looking at?
        </Button>}
      >
        <Popup.Header> </Popup.Header>
        <Popup.Content>
          <Card fluid style={{minWidth:'380px'}}>
            <p>By applying a simple system to our historical option pricing data, we simulate a simple profitability metric, displayed below. Our system: within each expiration, find the strike for puts and the strike for calls which has the highest percentage gap between the predicted thf price and the actual market price, at the time thf first starts predicting that expiration. Then buy and hold to expiration, or sell and hold to expiration, depending on the direction signaled by our pricing engine. Normalize the resulting profit by the initial market price of the option.</p>
            <Checkbox label={<label ref={(el) => {
              if (el) el.style.setProperty('color', 'black', 'important');
            }}>close and hide button, it can be re-enabled in profile.</label>} onChange={() => {
              var updatedPreferredViews = permissions.preferredViews;
              if (!updatedPreferredViews.hisNormalProfit) updatedPreferredViews.hisNormalProfit = {};
              updatedPreferredViews.hisNormalProfit.readWhatIsHistoricalProfitChart = true;
              updateUserPermissions('preferredViews', updatedPreferredViews);
              setOpen(false);
            }} />
          </Card>
        </Popup.Content>
      </Popup>
    ) : (
      <React.Fragment/>
    )}
  </div>);
};
